import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const RecipePrimarySpirits = ({ themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColorsPie(themeColor);
    }());

    useEffect(() => {
        metrics.getRecipePrimarySpirits({
            "total" : 10
        }).then(primarySpirits => {
            setChartData(primarySpirits);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },
                    title: {
                        text: `Top ${chartData.buckets} Spirits in the Menu`
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br />{point.y} Drinks'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            borderWidth: 0,
                            cursor: 'pointer',
                            colors: graphColors,
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                                distance: -50,
                                filter: {
                                    property: 'percentage',
                                    operator: '>',
                                    value: 4
                                }
                            }
                        }
                    },
                    series: [{
                        name: 'Share',
                        data: chartData.metrics.map((spirit, i) => {
                            return {
                                "name" : spirit.spirit,
                                "y" : spirit.count
                            }
                        })
                    }],
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default RecipePrimarySpirits;