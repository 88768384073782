import React, {useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrdersHistogram = ({ patron, daysAgo, recipeId, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        metrics.getOrdersHistogram({
            "patron" : patron,
            "days_ago" : daysAgo,
            "recipe_id" : recipeId
        }).then(ordersHistogram => {
            setChartData(ordersHistogram);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    if(!chartData) {
        return "";
    }

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'area'
                    },
                    title: {
                        text: 'Number of Orders by Date'
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return "Total Number of Drink Orders Grouped By Date";
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `Drink Orders by date ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        type: "datetime"
                    },
                    yAxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        pointFormat: '<b>{point.y}</b> Drinks Ordered On {point.date}'
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        dateFormat: "YYYY-mm-dd"
                    },
                    series: [{
                        name: 'Orders',
                        data: chartData.metrics.map(bucket => {
                            return {
                                "x" : new Date(bucket.date).getTime(),
                                "y" : bucket.count,
                                "date" : bucket.date
                            }
                        }),
                        color: themeColor
                    }],
                    "credits" : {
                        "enabled" : false
        
                    }
                }}
            />
        );
    }

    return <Chart />
}

export default OrdersHistogram;