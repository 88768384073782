import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        tokenIsValid : false
    },
    reducers: {
        setTokenIsValid : (state, input) => {
            state.tokenIsValid = input.payload;
        },
        fetch : (state) => {
            // fetch from server TBD
        }
    },
});

export const { setTokenIsValid, fetch } = appSlice.actions;
export default appSlice.reducer;