import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown.js';

import metrics from '../helper/metrics';

require("highcharts/highcharts-more")(Highcharts);

const OrderSpiritsActualByTypeBar = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [chart, setChart] = useState(null);
    const [error, setError] = useState(null);

    drilldown(Highcharts); // initialize the drilldown library
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    const genChart = refresh => {
        if(chartData && !refresh) {
            return;
        }

        metrics.getOrderSpiritsActualByType({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(spiritsActual => {
            setChartData(spiritsActual);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }

    useEffect(() => {
        if(!chartData) {
            return;
        }

        setChart({
            chart: {
                type: 'column'
            },
            title: {
                text: 'Types of Spirits Ordered'
            },
            subtitle: {
                text: (() => {
                    if(!daysAgo && !patron) {
                        return "Breakdown of primary spirit types by order (click for brands)";
                    }
                    const daysAgoLabel = () => {
                        if(daysAgo === 365) {
                            return "year";
                        } else if(daysAgo === 182) {
                            return "6 months";
                        } else if(daysAgo === 90) {
                            return "3 months";
                        } else if(daysAgo === 14) {
                            return "2 weeks";
                        } else {
                            return `${daysAgo} days`;
                        }
                    }
                    return `Most used primary spirits ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                })()
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: 'Total number of drink orders'
                }
        
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                column: {
                    colors: graphColors
                },
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        borderWidth: 0,
                        enabled: true,
                        format: '{point.y}',
                        style: {
                            textOutline: "0px"
                        }
                    }
                }
            },
        
            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Orders<br/>'
            },
        
            series : [{
                "name" : "Total Orders",
                "colorByPoint" : true,
                "data" : chartData.primary_spirits_metrics.map(primary_spirit => {
                    return {
                        "name" : primary_spirit.primary_spirit,
                        "y" : primary_spirit.count,
                        "drilldown" : primary_spirit.primary_spirit
                    }
                })
            }],
            drilldown : {
                breadcrumbs: {
                    position: {
                        align: "right"
                    }
                },
                series: chartData.primary_spirits_metrics.map(primary_spirit => {
                    return {
                        "name" : primary_spirit.primary_spirit,
                        "id" : primary_spirit.primary_spirit,
                        "data" : chartData.spirits_metrics.filter(actual_spirit => actual_spirit.spirit_type === primary_spirit.primary_spirit).map(actual_spirit => {
                            return [
                                actual_spirit.actual_spirit,
                                actual_spirit.count
                            ]
                        })
                    }
                })
            },
            credits : {
                enabled : false
            }
        });
    }, [chartData]);

    useEffect(() => {
        genChart(true);
    }, [daysAgo, patron]);

    genChart(); 


    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={chart}
            />
        );
    }

    return <Chart />
}

export default OrderSpiritsActualByTypeBar;