import React, {useState, useEffect, useRef} from 'react';

import styles from './css/RecipeSortMenu.css';

const useOutsideBlur = (ref, hideFilterMenu) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideFilterMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const RecipeSortMenu = ({ handleSort, sort, sortList}) => {
    const [filterMenuVisible, setFilterMenuVisible] = useState(false);
    const filterRef = useRef(null);

    const hideFilterMenu = () => {
        setFilterMenuVisible(false);
    }

    const SortMenu = () => {
        return (
            <div className="sort-select-container">
                {
                    Object.keys(sortList).map(key => {
                        return <span key={key} className={`${sort === key ? "selected" : ""}`} onClick={() => {
                            setFilterMenuVisible(false);
                            handleSort(key);
                        }}>{sortList[key].display}</span>
                    })
                }
            </div>
        );
    }

    // setup the off click typeahead hider
    useOutsideBlur(filterRef, hideFilterMenu);

    if(!sortList) {
        return <></>
    }

    return (
        <div className="filter-container" ref={filterRef}>
            <button id="filter-select" className={`filter-select ${filterMenuVisible ? "menu-open" : "menu-closed"}`} onClick={event => {
                setFilterMenuVisible(filterMenuVisible ? false : true);
            }}>{sortList[sort].display}&nbsp;&nbsp;<i className="fa-solid fa-sort" /></button>
            <div id="filter-menu" className={`filter-menu ${filterMenuVisible ? "show" : "hide"}`}>
                <SortMenu />
            </div>
        </div>
    );
}

export default RecipeSortMenu;