import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import wordCloud from 'highcharts/modules/wordcloud.js';

import metrics from '../helper/metrics';

wordCloud(Highcharts);

const OrderSpiritsActualWordCloud = ({ patron, daysAgo }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        metrics.getOrderSpiritsActualByType({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(spiritsActual => {
            setChartData(spiritsActual);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const AllSpiritsWordCloud = () => {
        return (
            <HighchartsReact
                key={`wc-allspirits`}
                highcharts={Highcharts}
                options={{
                    "series" : [{
                        "type" : "wordcloud",
                        "data" : chartData.spirits_metrics.filter(spirit => spirit.actual_spirit !== "").map(spirit => {
                            return {
                                "name" : spirit.actual_spirit,
                                "weight" : spirit.count
                            }
                        }),
                        "name" : "Spirits",
                        "colors" : graphColors
                    }],
                    "title" : {
                        "text" : `All Brands Used`
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return null;
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `All Brands Used ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    "chart" : {
                        "height" : "60%"
                    },
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        );
    }

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }
        return (
            <>
                <AllSpiritsWordCloud />
            </>
        )
    }

    return <Chart />
}

export default OrderSpiritsActualWordCloud;