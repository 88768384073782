import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import wordCloud from 'highcharts/modules/wordcloud.js';

import metrics from '../helper/metrics';

wordCloud(Highcharts);

const OrderSpiritTypesActualWordCloud = ({ patron, daysAgo }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        metrics.getOrderSpiritsActualByType({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(spiritsActual => {
            setChartData(spiritsActual);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const AllSpiritTypesWordCloud = () => {
        return <HighchartsReact
            key={`wc-all-spirit-types`}
            highcharts={Highcharts}
            options={{
                "series" : [{
                    "type" : "wordcloud",
                    "data" : chartData.primary_spirits_metrics.filter(spirit => spirit.spirit_type !== "").map(spirit => {
                        return {
                            "name" : spirit.primary_spirit,
                            "weight" : spirit.count
                        }
                    }),
                    "name" : "Primary Spirits",
                    "colors" : graphColors
                }],
                "title" : {
                    "text" : `Primary Spirits Used`
                },
                subtitle: {
                    text: (() => {
                        if(!daysAgo && !patron) {
                            return null;
                        }
                        const daysAgoLabel = () => {
                            if(daysAgo === 365) {
                                return "year";
                            } else if(daysAgo === 182) {
                                return "6 months";
                            } else if(daysAgo === 90) {
                                return "3 months";
                            } else if(daysAgo === 14) {
                                return "2 weeks";
                            } else {
                                return `${daysAgo} days`;
                            }
                        }
                        return `All Spirit Types ${patron ? `for ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                    })()
                },
                "credits" : {
                    "enabled" : false
                }
            }}
        />
    }

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <>
                <AllSpiritTypesWordCloud />
            </>
        )
    }

    return <Chart />
}

export default OrderSpiritTypesActualWordCloud;