import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';

import metrics from '../helper/metrics';

HighchartsSankey(Highcharts);

const RecipeTypesGlassTypesSankey = ({  }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        metrics.getRecipeTypesGlassTypes().then(typesGlassTypes => {
            setChartData(typesGlassTypes);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    "series" : [{
                        "type" : "sankey",
                        "keys" : ['from', 'to', 'weight'],
                        "data" : chartData.metrics.map(result => {
                            return [
                                result.type,
                                result.glass_type,
                                result.count
                            ];
                        }),
                        "name" : "Drink Types w/Glass Type",
                        "colors" : graphColors
                    }],
                    "title" : {
                        "text" : `Drink Types with Top Glass Types`
                    },
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default RecipeTypesGlassTypesSankey;