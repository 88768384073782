import React, {useState, useEffect} from 'react';
import {Routes, Route, Outlet, Link, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import moment from 'moment';

import idm from './helper/idm';
import cocktail from './helper/cocktail';
import app from './helper/app';
import ws from './helper/ws';
import {set as setPatrons} from './reducers/patronsSlice';

import NavIngredients from './NavIngredients';
import styles from './css/NavModal.css';
import config from './helper/config';

const NavModal = ({ type, visible, hideMenu, navTo }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [spiritsCache, setSpiritsCache] = useState([]);
    const [types, setTypes] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [tagsCache, setTagsCache] = useState([]);
    //const [patrons, setPatrons] = useState([]);
    const [locations, setLocations] = useState([]);
    const [shareTokenId, setShareTokenId] = useState(null);
    const [shareUrl, setShareUrl] = useState(null);
    const [uiStats, setUiStats] = useState({});
    const [apiRecipeStats, setApiRecipeStats] = useState({});
    const [apiOrderStats, setApiOrderStats] = useState({});
    const [apiAuthStats, setApiAuthStats] = useState({});
    const [apiShareStats, setApiShareStats] = useState({});
    const [apiMetricsStats, setApiMetricsStats] = useState({});
    const [apiGatewayStats, setApiGatewayStats] = useState({});
    const patrons = useSelector((state) => state.patrons.list);

    useEffect(() => {

    }, [shareTokenId]);
 
    useEffect(() => {
        if(type === "spirits" || type === "orders-spirits" && !spiritsCache.length) {
            cocktail.getSpirits().then(spiritList => {
                setSpiritsCache(spiritList.map(spirit => {
                    return spirit.spirit;
                }));
                setError(null);
            }).catch(e => {
                setError("Spirit list not available");
            });
        } else if(type === "types" && !types.length) {
            cocktail.getTypes().then(typesList => {
                setTypes(typesList.map(type => {
                    return type.type;
                }));
                setError(null);
            }).catch(e => {
                setError("Drink type list not available");
            });
        } else if(type === "ingredients" && !ingredients.length) {
            cocktail.getIngredients().then(ingredientsList => {
                // capitalize each word and set the list to the follow format
                // {"value" : "lime juice", "display" : "Lime Juice", "selected" : false}
                setIngredients(
                    ingredientsList.map(ingredient => {
                        return {
                            "value" : ingredient,
                            "display" : ingredient.split(" ").map(word => {
                                return word[0].toUpperCase() + word.substring(1);
                            }).join(" "),
                            "selected" : false
                        }
                    })
                );
                setError(null);
            }).catch(e => {
                setError("Ingredient list not available")
            });
        } else if(type === "tags" && !tagsCache.length) {
            cocktail.getTags().then(tagList => {
                setTagsCache(tagList);
                setError(null);
            }).catch(e => {
                setError("Tag list is not available");
            });
        } else if((type === "patrons" || type === "metrics-patrons") && !patrons.length) {
            /*
            if(!idm.tokenHasRole("order_viewer")) {
                return;
            }

            cocktail.getPatrons().then(patronsList => {
                setPatrons(patronsList);
                setError(null);
            }).catch(e => {
                setError("Patron list is not available");
            });
            */
        } else if(type === "locations" && !locations.length) {
            cocktail.getLocations().then(locationsList => {
                setLocations(locationsList);
                setError(null);
            }).catch(e => {
                setError("Location list is not available");
            });
        } else if(type === "about") {
            axios.get(`/server_stats.json?t=timestamp=${new Date().getTime()}`).then(output => {
                setUiStats(output.data);
                setError(null);
            }).catch(e => {
                setError("UI stats are not available");
            });

            ws.apiRequest({
                "path" : `/recipes/about?t=timestamp=${new Date().getTime()}`,
                "method" : "GET"
            }).then(output => {
                setApiRecipeStats(output.data.response);
                setError(null)
            }).catch(e => {
                setError("Recipe microservice are not available");
            });

            ws.apiRequest({
                "path" : `/orders/about?t=timestamp=${new Date().getTime()}`,
                "method" : "GET"
            }).then(output => {
                setApiOrderStats(output.data.response);
            }).catch(e => {
                setError("Order microservice stats are not available");
            });

            ws.apiRequest({
                "path" : `/metrics/about?t=timestamp=${new Date().getTime()}`,
                "method" : "GET"
            }).then(output => {
                setApiMetricsStats(output.data.response);
            }).catch(e => {
                setError("Metrics microservice stats are not available");
            });

            axios.get("/s/about?t=timestamp=${new Date().getTime()").then(output => {
                setApiShareStats(output.data.response);
            }).catch(e => {
                setError("Share microservice stats are not available");
            });

            ws.apiRequest({
                "path" : `/auth/about?t=timestamp=${new Date().getTime()}`,
                "method" : "GET"
            }).then(output => {
                setApiAuthStats(output.data.response);
            }).catch(e => {
                setError("Auth microservice stats are not available");
            });

            axios.get(`/ingress/server_stats.json?t=timestamp=${new Date().getTime()}`).then(output => {
                setApiGatewayStats(output.data);
                setError(null);
            }).catch(e => {
                setError("Ingress stats are not available");
            });
        }
    }, []);

    const HeaderNav = ({title, backTo=null}) => {
        const Back = () => {
            if(backTo) {
                return (
                    <div onClick={ () => {
                        hideMenu();
                        navTo(backTo);
                    }} className="nav-header-back">
                        <i className="fa-solid fa-chevron-left" />
                    </div>
                );
            }

            return <></>
        }

        /*
        <div onClick={ e => { e.stopPropagation(); } } className="nav-header">
            <div className="nav-header-text">
                <span onClick={ () => {  } }>{title}</span>
            </div>
            <Back />
            <div className="nav-header-close">
                <i onClick={hideMenu} className="fa-solid fa-xmark" />
            </div>
        </div>
        */

        return (
            <div onClick={ e => { e.stopPropagation(); } } className="nav-header">
            <div className="nav-header-text">
                <span onClick={ () => {  } }>{title}</span>
            </div>
            <Back />
            <div className="nav-header-close">
                <i onClick={hideMenu} className="fa-solid fa-xmark" />
            </div>
        </div>
        );
    }

    const changePassword = () => {
        const oldPassword = document.getElementById("old-password").value;
        const newPassword = document.getElementById("new-password").value;
        const confirmPassword = document.getElementById("confirm-password").value;

        if(!oldPassword && !newPassword && !confirmPassword) {
            app.showAlertModal({
                "type" : "error",
                "message" : "Please enter your password information to proceed"
            });
            return;
        } else if(newPassword != confirmPassword) {
            app.showAlertModal({
                "type" : "error",
                "message" : "Your new passwords do not match"
            });
            return;
        } else if(!/\d/g.test(newPassword)
            || !/[a-z]/g.test(newPassword)
            || !/[A-Z]/g.test(newPassword)
            || newPassword.length < 10) {
            app.showAlertModal({
                "type" : "error",
                "message" : "Password requirements not met.  Please ensure your password is at least 10 characters in length, has both upper and lowercase letters, and at least one number."
            });
            return;
        }

        idm.changePassword({
            "oldPassword" : oldPassword,
            "newPassword" : newPassword
        }).then(() => {
            document.getElementById("old-password").value = "";
            document.getElementById("new-password").value = "";
            document.getElementById("confirm-password").value = "";

            hideMenu();
            app.showAlertModal({
                "type" : "info",
                "message" : "Your password has been successfully changed"
            });
        }).catch(e => {
            console.log(e);
            //hideMenu();
            app.showAlertModal({
                "type" : "error",
                "message" : e.error ? `Your password could not be changed: ${e.error}` : "An error was encountered and your password was not changed -- Please verify your current password is accurate."
            });
        });
    }

    const NavError = () => {
        return (
            <div className="nav-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        );
    }

    const RecipeNav = () => {
        return (
            <>
                <HeaderNav title="Drink Menu" />
                <div onClick={ e => { e.stopPropagation(); } } className="inner">
                    <div className="items">
                        {/*<div className="nav-title">Search Drinks By</div>*/}
                        <div onClick={() => {
                            navigate("/recipes/favorites");
                            hideMenu();
                        }} className="nav-subitem">Favorites</div>
                        <div onClick={() => {
                            navigate("/recipes/seasonal");
                            hideMenu();
                        }} className="nav-subitem">Seasonal</div>
                        <div onClick={() => {
                            hideMenu();
                            navTo("types");
                        }} className="nav-subitem">Drink Types</div>
                        <div onClick={() => {
                            hideMenu();
                            navTo("spirits");
                        }} className="nav-subitem">Spirits</div>
                        <div onClick={() => {
                            hideMenu();
                            navTo("ingredients");
                        }} className="nav-subitem">Ingredients</div>
                        <div onClick={() => {
                            hideMenu();
                            navTo("tags");
                        }} className="nav-subitem">Tags</div>
                        <div onClick={() => {
                            navigate("/recipes");
                            hideMenu();
                        }} className="nav-subitem">Everything</div>
                        <div onClick={() => {
                            navigate(`/recipes/random?r=${Math.random()}`);
                            hideMenu();
                        }} className="nav-subitem">Surprise Me!</div>
                    </div>
                </div>
            </>
        );
    }

    const RecipeSpiritsNav = () => {
        if(error || spiritsCache.length < 1) {
            return (
                <>
                    <HeaderNav title="Drinks by Spirit" backTo="recipes" />
                    <NavError />
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Drinks by Spirit" backTo="recipes" />
                <div className="inner">
                    <div className="items">
                        {
                            spiritsCache.map((spirit, i) => {
                                if(i > config.app.spirits_max) {
                                    return ""
                                }
                                return (
                                    <div key={`nav-${spirit}`} onClick={() => {
                                        navigate(`/recipes/spirits/${spirit}`);
                                        hideMenu();
                                    }} className="nav-subitem">{spirit}</div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </>
        );
    }

    const RecipeTypesNav = () => {
        if(error || types.length < 1) {
            return (
                <>
                    <HeaderNav title="Drinks by Type" backTo="recipes" />
                    <NavError />
                </>
            )
        }

        return (
            <div className="nav-vflex">
                <HeaderNav title="Drinks by Type" backTo="recipes" />
                <div className="inner">
                    <div className="items">
                        {
                            types.map(type => {
                                return (
                                    <div key={`nav-${type}`} onClick={() => {
                                        navigate(`/recipes/types/${type}`);
                                        hideMenu();
                                    }} className="nav-subitem">{type}</div>
                                )
                            })
                        }
                        <div onClick={ () => {
                            hideMenu();
                            navTo("recipes");
                        }} className="back"></div>
                    </div>
                </div>
            </div>
        );
    }

    const IngredientsNav = () => {
        if(error || !ingredients.length) {
            return (
                <>
                    <HeaderNav title="Drinks by Ingredients" backTo="recipes" />
                    <NavError />
                </>
            )
        }

        return <NavIngredients ingredients={ingredients} navTo={navTo} hideMenu={hideMenu} HeaderNav={HeaderNav} />
    }

    const RecipeTagsNav = () => {
        if(!tagsCache) {
            return <>
                <HeaderNav title="Drinks by Tag" backTo="recipes" />
            </>
        } else if(error) {
            return <>
                <HeaderNav title="Drinks by Tag" backTo="recipes" />
                <NavError />
            </>
        }

        return (
            <>
                <HeaderNav title="Drinks by Tag" backTo="recipes" />
                <div className="inner">
                    <div className="items">
                        {
                            tagsCache.map(tag => {
                                return (
                                    <div key={`nav-tag-${tag.tag}`} onClick={() => {
                                        navigate(`/recipes/tags/${tag.tag}`);
                                        hideMenu();
                                    }} className="nav-subitem">{tag.tag}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    const OrderNav = () => {
        return (
            <>
                <HeaderNav title="Drinks Ordered" />
                <div className="inner">
                    <div className="items">
                        {
                            (() => {
                                if(idm.tokenHasRole("recipe_readonly")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        navigate(`/orders/patrons/${localStorage.getItem("patronName")}`);
                                        hideMenu();
                                    }} className="nav-subitem">My Orders</div>
                                )
                            })()
                        }
                        {
                            (() => {
                                if(!idm.tokenHasRole("order_admin")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        navigate(`/orders/new`);
                                        hideMenu();
                                    }} className="nav-subitem">New Orders</div>
                                );
                            })()
                        }
                        {
                            (() => {
                                if(!idm.tokenHasRole("order_viewer")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        hideMenu();
                                        navTo("patrons");
                                    }} className="nav-subitem">Patrons</div>
                                );
                            })()
                        }
                        <div onClick={() => {
                            hideMenu();
                            navTo("orders-spirits");
                        }} className="nav-subitem">Spirits</div>
                        <div onClick={() => {
                            hideMenu();
                            navTo("locations");
                        }} className="nav-subitem">Locations</div>
                        <div onClick={() => {
                            navigate("/orders");
                            hideMenu();
                        }} className="nav-subitem">Everything</div>
                    </div>
                </div>
            </>
        );
    }

    const OrderPatronsNav = () => {
        if(error || patrons.length < 1) {
            return (
                <>
                    <HeaderNav title="Orders by Patron" backTo="orders" />
                    <NavError />
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Orders by Patron" backTo="orders" />
                <div className="inner">
                    <div className="items">
                        {
                            patrons.map((patron, i) => {
                                return (
                                    <div key={`nav-${patron}`} onClick={() => {
                                        navigate(`/orders/patrons/${patron}`);
                                        hideMenu();
                                    }} className="nav-subitem">{patron}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    const OrderLocationsNav = () => {
        if(error || locations.length < 1) {
            return (
                <>
                    <HeaderNav title="Orders by Location" backTo="orders" />
                    <NavError />
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Orders by Location" backTo="orders" />
                <div className="inner">
                    <div className="items">
                        {
                            locations.map(location => {
                                return (
                                    <div key={`nav-${location}`} onClick={() => {
                                        navigate(`/orders/locations/${location}`);
                                        hideMenu();
                                    }} className="nav-subitem">{location}</div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    const OrderSpiritsNav = () => {
        if(error || spiritsCache.length < 1) {
            return (
                <>
                    <HeaderNav title="Orders by Spirit" backTo="orders" />
                    <NavError />
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Orders by Spirit" backTo="orders" />
                <div className="inner">
                    <div className="items">
                        {
                            spiritsCache.map((spirit, i) => {
                                if(i > config.app.spirits_max) {
                                    return ""
                                }
                                return (
                                    <div key={`nav-${spirit}`} onClick={() => {
                                        navigate(`/orders/spirits/${spirit}`);
                                        hideMenu();
                                    }} className="nav-subitem">{spirit}</div>
                                )
                            })
                        }
                        
                    </div>
                </div>
            </>
        );
    }

    const UserNav = () => {
        return (
            <>
                <HeaderNav title="Account Actions" />
                <div className="inner">
                    <div className="items">
                        {
                            (() => {
                                if(!idm.tokenHasRole("recipe_share")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        navTo("share");
                                        hideMenu();

                                        // retrieve a readonly (share) token identifier
                                        ws.apiRequest({
                                            "path" : "/auth/token/readonly",
                                            "method" : "PUT"
                                        }).then(output => {
                                            setShareTokenId(output.data.response.id);
                                            const shareUrl = window.location.protocol + "//" + window.location.host + "/s" + window.location.pathname + window.location.search + (window.location.search ? "&" : "?") + "t=" + output.data.response.id;
                                            document.getElementById("share-url").value = shareUrl;
                                        }).catch(e => {
                                            console.error(e);
                                        });
                                    }} className="nav-subitem">Share</div>
                                )
                            })()
                        }
                        {
                            (() => { // do not show the change password if the readonly role is assigned
                                if(idm.tokenHasRole("recipe_readonly")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        navTo("password");
                                        hideMenu();
                                    }} className="nav-subitem">Change Password</div>
                                )
                            })()
                        }
                        <div onClick={() => {
                            navTo("logout");
                            hideMenu();
                        }} className="nav-subitem">Logout</div>
                    </div>
                    <div onClick={hideMenu} className="close"></div>
                </div>
            </>
        );
    }

    const ShareNav = () => {
        return (
            <>
                <HeaderNav title="Share This Page" backTo="usermenu" />
                <div className="inner">
                    <div className="share-box">
                        <div key={shareTokenId} >
                            <input key={shareTokenId} type="text" id="share-url" defaultValue={shareUrl} />
                            <button onClick={() => {
                                navigator.clipboard.writeText(document.getElementById("share-url").value);
                                hideMenu();
                            }}><FontAwesomeIcon icon={faCopy} /></button>
                        </div>
                    </div>
                    <div className="share-note">This link will be accessible for {config.session.share_ttl / 60 / 60} hours, after which a valid user account will be required for external access.</div>
                </div>
            </>
        );
    }

    const PasswordNav = () => {
        return (
            <>
                <HeaderNav title="New Password" backTo="usermenu" />
                <div className="inner">
                    <div className="password-content">
                        <input type="password" id="old-password" placeholder="Current Password" />
                        <input type="password" id="new-password" placeholder="New Password" />
                        <input type="password" id="confirm-password" placeholder="Confirm New Password" />

                        <div className="password-actions button-group">
                            <button className="standard-button primary" onClick={() => {
                                changePassword();
                            }}><i className="fa-solid fa-key" /> Change Password</button>
                            <button onClick={() => {

                            }}><i className="fa-solid fa-xmark" /> Cancel</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const MetricsNav = () => {
        return (
            <>
                <HeaderNav title="Drink Metrics" />
                <div className="inner">
                    <div className="items">
                        <div onClick={() => {
                            navigate("/metrics/popularity");
                            hideMenu();
                        }} className="nav-subitem">Popularity</div>
                        <div onClick={() => {
                            navigate("/metrics/trends");
                            hideMenu();
                        }} className="nav-subitem">Drink Trends</div>
                        <div onClick={() => {
                            navigate("/metrics/spirits");
                            hideMenu();
                        }} className="nav-subitem">Spirit Use</div>
                        <div onClick={() => {
                            navigate("/metrics/recipes");
                            hideMenu();
                        }} className="nav-subitem">Menu Insights</div>
                        {
                            (() => {
                                if(idm.tokenHasRole("recipe_readonly")) {
                                    return <></>
                                }

                                return (
                                    <div onClick={() => {
                                        navigate(`/metrics/patrons/${localStorage.getItem("patronName")}`);
                                        hideMenu();
                                    }} className="nav-subitem">My Orders</div>
                                )
                            })()
                        }
                        {
                            (() => {
                                if(idm.tokenHasRole("order_viewer")) {
                                    return <div onClick={() => {
                                        navTo("metrics-patrons");
                                        hideMenu();
                                    }} className="nav-subitem">Leaderboard</div>
                                }

                                return "";
                            })()
                        }
                        <div onClick={() => {
                            navigate("/metrics");
                            hideMenu();
                        }} className="nav-subitem">All Orders</div>
                    </div>
                </div>
            </>
        );
    }


    const MetricsPatronsNav = () => {
        if(error || patrons.length < 1) {
            return (
                <>
                    <HeaderNav title="Metrics by Patron" backTo="metrics" />
                    <NavError />
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Metrics by Patron" backTo="metrics" />
                <div className="inner">
                    <div className="items">
                        {
                            patrons.map((patron, i) => {
                                return (
                                    <div key={`nav-${patron}`} onClick={() => {
                                        navigate(`/metrics/patrons/${patron}`);
                                        hideMenu();
                                    }} className="nav-subitem">{patron}</div>
                                )
                            })
                        }
                        <div onClick={ () => {
                            navTo("metrics");
                            hideMenu();
                        }} className="back"></div>
                    </div>
                    <div onClick={hideMenu} className="close"></div>
                </div>
            </>
        );
    }

    const AboutEnvNav = () => {
        const StatCell = ({label, value}) => {
            if(!value) {
                return <></>
            } else if(label == "Release Date" && value) {
                const originalDate = moment(value, "YYYY-MM-DD HH:mm:ss Z");
                value = originalDate.clone().format("M/D/YY H:mm");
            } else if(label == "Boot Date" && value) {
                const originalDate = moment(value, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z");
                value = originalDate.clone().format("M/D/YY H:mm");
            } else if(label == "Release SHA" && value) {
                value = value.slice(-8);
            }
            return (
                <div className="about-item">
                    <p>{value}</p>
                    <span>{label}</span>
                </div>
            )
        }

        const StatLine = ({label, value}) => {
            return (
                <div className="about-line">
                    {label}: <span>{value}</span>
                </div>
            )
        }

        const ElapsedCell = ({label, value}) => {
            const currTime = moment();
            let pastTime;

            value = value ?? "CURRENT";
            
            if(label == "Uptime") {
                if(value.length > 26) { // determine if milliseconds are included
                    pastTime = !value || value == "CURRENT" ? moment() : moment(value, "YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z");
                } else {
                    pastTime = !value || value == "CURRENT" ? moment() : moment(value, "YYYY-MM-DD HH:mm:ss Z");
                }
            } else if(label == "Release Age") {
                pastTime = !value || value == "CURRENT" ? moment() : moment(value);
            }

            const days = currTime.diff(pastTime, 'days');
            const hours = currTime.diff(pastTime.add(days, 'days'), 'hours');
            const minutes = currTime.diff(pastTime.add(hours, 'hours'), 'minutes');
            return <StatCell label={label} value={`${days}d, ${hours}h, ${minutes}m`} />
        }

        const MicroServiceStats = ({msName, stats, last}) => {
            return (
                <>
                    <div className="about-title">{stats.app_name}</div>
                    <div className="about-grid">
                        <StatCell label="Version" value={stats.microservice_version} />
                        <StatCell label="Environment" value={stats.app_environment ?? stats.app_env} />
                        <StatCell label="Service Port" value={stats.microservice_port} />
                        <StatCell label="Author" value={stats.publish_author} />
                        <StatCell label="Release SHA" value={stats.publish_sha} />
                        <StatCell label="Container Name" value={stats.container_name} />
                        <StatCell label="Release Date" value={stats.release_date} />
                        <StatCell label="Boot Date" value={stats.container_date} />
                        <ElapsedCell label="Release Age" value={stats.release_date} />
                        <ElapsedCell label="Uptime" value={stats.container_date} />
                    </div>
                    <StatLine label="Release URL" value={stats.release_url} />
                    <StatLine label="Repo URL" value={stats.repository_url} />
                    <StatLine label="Docker Image" value={stats.docker_image} />
                    {(() => {
                        if(!last) {
                            return <div className="section-pad"> </div>
                        }
                    })()}
                </>
            )
        }

        return (
            <>
                <HeaderNav title="Nerd Stuff" />
                <div className="inner">
                        <MicroServiceStats msName="User Interface" stats={uiStats} />
                        <MicroServiceStats msName="Recipes Microservice" stats={apiRecipeStats} />
                        <MicroServiceStats msName="Orders Microservice" stats={apiOrderStats} />
                        <MicroServiceStats msName="Metrics Microservice" stats={apiMetricsStats} />
                        <MicroServiceStats msName="Auth Microservice" stats={apiAuthStats} />
                        <MicroServiceStats msName="Share Microservice" stats={apiShareStats} />
                        <MicroServiceStats msName="Ingress" stats={apiGatewayStats} last="true" />
                    <div onClick={hideMenu} className="close"></div>
                </div>
            </>
        );
    }

    const MenuContent = () => {
        if(type === "recipes") {
            return <RecipeNav />
        } else if(type === "spirits") {
            return <RecipeSpiritsNav />
        } else if(type === "types") {
            return <RecipeTypesNav />
        } else if(type === "ingredients") {
            return <IngredientsNav />
        } else if(type === "tags") {
            return <RecipeTagsNav />
        } else if(type === "orders") {
            return <OrderNav />
        } else if(type === "patrons") {
            return <OrderPatronsNav />
        } else if(type === "locations") {
            return <OrderLocationsNav />
        } else if(type === "orders-spirits") {
            return <OrderSpiritsNav />
        } else if(type === "usermenu") {
            return <UserNav />
        } else if(type === "share") {
            return <ShareNav />
        } else if(type === "password") {
            return <PasswordNav />
        } else if(type === "metrics") {
            return <MetricsNav />
        } else if(type === "metrics-patrons") {
            return <MetricsPatronsNav />
        } else if(type === "about") {
            return <AboutEnvNav />
        } else {
            //console.log(`No results for: ${type}`);
        }

        return "";
    }

    return (
        <div id={`nav-modal-${type}`} className={`nav-modal ${visible ? "show" : "hide"}`}>
            <MenuContent />
        </div>
    );
}

export default NavModal;