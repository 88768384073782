import React, {useState, useEffect, useReducer} from 'react';

import Recipe from './Recipe';
import Order from './Order';
import OrderUpdate from './OrderUpdate';
import RecipeForm from './RecipeForm';
import ImageSet from './ImageSet';
import ImageView from './ImageView';

import styles from './css/Modal.css';

const Modal = ({ displayComponent, modalId, recipe, order, visible, setOrder, setRecipe, loadRecipe, showRecipeEdit, hideModal, deleteOrdersItem, editImage, orderUpdated }) => {

    useEffect(() => {
        if(visible) {
            document.getElementsByTagName("body")[0].classList.add("noscroll");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("noscroll");
        }
    }, [visible]);

    const modalContent = () => {
        if(displayComponent == "recipe") {
            return <Recipe recipeDef={recipe} hideRecipeModal={hideModal} showRecipeEdit={showRecipeEdit} displayType="modal" loadRecipe={loadRecipe} />
        } else if(displayComponent == "order") {
            return <Order order={order} hideOrderModal={hideModal} setOrder={setOrder} />
        } else if(displayComponent == "order-update") {
            return <OrderUpdate order={order} hideOrderModal={hideModal} setOrder={setOrder} deleteOrdersItem={deleteOrdersItem} orderUpdated={orderUpdated} visible={visible} />
        } else if(displayComponent == "recipe-add") {
            return <RecipeForm recipe={recipe} hideModal={hideModal} setRecipe={setRecipe} />
        } else if(displayComponent == "image-set") {
            return <ImageSet recipe={recipe} order={order} hideModal={hideModal} visible={visible} />
        } else if(displayComponent == "image-view") {
            return <ImageView recipe={recipe} order={order} hideModal={hideModal} editImage={editImage} visible={visible} />
        }
    }
    
    return (
        <div id={modalId} className={"modal " + (visible ? "show" : "hide")}>
            <div className="body">
                {modalContent()}
            </div>
        </div>
    );
}

export default Modal;