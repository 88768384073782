import React, {useState, useEffect, useReducer} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCocktail } from '@fortawesome/free-solid-svg-icons'

import idm from './helper/idm';
import ws from './helper/ws';
import cocktail from './helper/cocktail';
import config from './helper/config';

import Modal from './Modal';
import Rating from './Rating';
import OrderUpdate from './OrderUpdate';
import SpiritsActual from './SpiritsActual';

import styles from './css/Order.css'
import fastyles from './fa/css/all.css';

const Order = ({ order, setOrder, refreshSearch, single=false, originUrl }) => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [updateModalIsVisible, setUpdateModalIsVisible] = useState(false);
    const [imageModalIsVisible, setImageModalIsVisible] = useState(false);
    const [imageViewModalIsVisible, setImageViewModalIsVisible] = useState(false);
    const [recipe, setRecipe] = useState({});
    const [orderUpdated, setOrderUpdated] = useState(false);
    const canEdit = cocktail.canEditOrder(order.id);
    const navigate = useNavigate();

    const showImageModal = () => {
        setImageModalIsVisible(true);
    }

    const hideImageModal = () => {
        setImageModalIsVisible(false);

        if(refreshSearch) {
            refreshSearch();
        } else {
            cocktail.getOrder(
                order.id
            ).then(orderDoc => {
                setOrder(orderDoc);
                setOrderUpdated(true);
            }).catch(e => {
                console.error(e);
            });
        }
    }

    const showImageViewModal = () => {
        setImageViewModalIsVisible(true);
    }

    const hideImageViewModal = () => {
        setImageViewModalIsVisible(false);
    }

    const showEditImage = () => {
        if(imageViewModalIsVisible) {
            hideImageViewModal();
        }

        showImageModal();
    }

    const EditImageIcon = () => {
        if(canEdit && !order.icon) {
            return (
                <div className="image-edit" onClick={ showImageModal }><i className="fa-solid fa-upload" /></div>
            )
        }

        return "";
    }

    const Icon = () => {
        if(order.image) {
            return (
                <div className="drink-image-container">
                    <div className="drink-image">
                        <img key={order.image.filename} onClick={showImageViewModal} className="uploaded" src={ws.getEndpoint(`/orders/${order.id}/icon?cachebust=${moment().unix()}`)} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="drink-image-container">
                    <div className="drink-image">
                        <img className="generic" src="/images/Americano-icon.png" />
                        <EditImageIcon />
                    </div>
                </div>
            );
        }
    }

    const Details = () => {
        if(order.details) {
            return (
                <div>
                    <b>Special Instructions</b><br />
                    <div className="notes">
                        {order.details}
                    </div>
                    <hr />
                </div>
            )
        }

        return "";
    }

    const Notes = () => {
        if(order.notes) {
            return (
                <div>
                    <b>Bartender Notes</b><br />
                    <div className="notes">
                        {order.notes}
                    </div>
                    <hr />
                </div>
            )
        }

        return "";
    }

    const Comments = () => {
        if(order.comments) {
            return (
                <div>
                    <b>Patron Comments</b><br />
                    <div className="notes">
                        {order.comments}
                    </div>
                    <hr />
                </div>
            )
        }

        return "";
    }

    const RatingView = () => {
        let iteration = 0;

        if(order.rating) {
            const halfIcon = order.rating % 2 > 0 ? <i key={order.id + "-100"} className="fa fa-martini-glass rating-icon rating-icon-partial-50" /> : '';
            return (<div className="order-rating">
                {[...Array(Math.floor(order.rating / 2))].map(element => {
                    iteration++;
                    return <i key={order.id + "-" + iteration} className="fa fa-martini-glass rating-icon rating-icon-full" />
                })}
                {halfIcon}
                {[...Array(Math.floor((10 - order.rating) / 2))].map((i) => {
                    iteration++;
                    return <i key={order.id + "-" + iteration} className="fa fa-martini-glass rating-icon rating-icon-empty" />
                })}
            </div>);
        } else if(canEdit || order.ordered_by == localStorage.getItem("patronName")) {
            return (
                <>
                    <hr />
                    How was your drink?  Please rate it!
                    <div className="order-rating-update">
                        <div className="order-rating-elements">
                            <Rating Id={order.id} ratingStart={10} />
                        </div>
                        <textarea id={"comments-initial-" + order.id} className="comments" placeholder="Comments"></textarea>
                    </div>
                </>
            );
        }

        return "";
    }

    const OrderedByAtOn = () => {
        if(idm.tokenHasRole("order_viewer")) {
            // user has access to view who ordered the drink
            return (
                <div className="order-stats">
                    Ordered by {order.ordered_by} at {order.ordered_at} <OrderedOn />
                </div>
            );
        } else if(sessionStorage.getItem("localClient") && cocktail.getPatronName() && cocktail.getPatronName() == order.ordered_by) {
            // user does not have order_viewer role, but is local (on private network)
            // and their name stored in localstorage matches the name on the order
            return (
                <div className="order-stats">
                    Ordered by {order.ordered_by} at {order.ordered_at} <OrderedOn />
                </div>
            );
        }

        // assume the user cannot view who placed the drink order
        return (
            <div className="order-stats">
                Ordered at {order.ordered_at} <OrderedOn />
            </div>
        );
    }

    const OrderedOn = () => {
        if(moment().diff(moment(order.ordered_on), 'days') > 15) {
            return ("on " + moment(order.ordered_on).format("dddd, MMMM Do, YYYY"))
        } else {
            return moment(order.ordered_on).fromNow()
        }
    }

    const loadRecipe = async (force = false) => {
        if(!recipe.id || force) {
            const recipeDoc = await cocktail.getRecipe(order.recipe_id);
            setRecipe(recipeDoc);
            showRecipeModal();
        } else {
            showRecipeModal();
        }
    }

    const getRating = () => {
        let rating = 0;

        [...Array(10).keys()].forEach(ratingNum => {
            if(document.getElementById("rating-" + order.id + "-" + (ratingNum + 1)).classList.contains("on")) {
                rating++;
            }
        });

        return rating;
    }

    const saveFeedback = () => {
        let rating = getRating();
        let comments = document.getElementById("comments-initial-" + order.id).value;
        let updatePayload = {
            "order" : order,
            "id" : order.id,
            "recipe_id" : order.recipe_id,
            "rating" : rating,
            "callback" : (orderOutput) => {
                setOrder(orderOutput);
                setOrderUpdated(true);
            }
        }

        if(comments) {
            updatePayload.order['comments'] = comments;
        }

        cocktail.orderUpdate(updatePayload);
    }


    const toggleOrderComplete = isComplete => {
        cocktail.orderUpdate({
            "order" : order,
            "id" : order.id,
            "complete" : isComplete ? true : false,
            "callback" : orderDoc => {
                setOrder(orderDoc);
            },
            "error" : err => {
                console.error(err);
            }
        });
    }


    const Actions = () => {
        const EditButton = () => {
            if(idm.tokenHasRole("order_admin")) {
                return (
                    <button onClick={showUpdate}><i className="fa-solid fa-pen-to-square" /></button>
                );
            }
    
            return "";
        }

        const ViewRecipe = () => {
            return <button className="view-recipe" onClick={loadRecipe}><i className="fa-solid fa-martini-glass-citrus" /> Recipe</button>
        }

        const ViewOrderURL = () => {
            return <button onClick={() => {
                navigate(`/orders/${order.id}`);
            }}><i className="fa-solid fa-receipt" /></button>
        }

        const BackButton = () => {
            if(single) {
                return <button onClick={() => {
                    if(originUrl) {
                        navigate(originUrl);
                    } else {
                        navigate(-1);
                    }
                }}><i className="fa-solid fa-arrow-left" /></button>
            } else {
                return <></>
            }
        }

        const CompleteButton = () => {
            if(!idm.tokenHasRole("order_admin")) {
                return <></>
            }

            if(!order.complete) {
                return <button onClick={() => {
                    toggleOrderComplete(true);
                }}><i className="fa-regular fa-square" /></button>
            } else {
                return <button onClick={() => {
                    toggleOrderComplete(false);
                }}><i className="fa-regular fa-square-check" /></button>
            }
        }

        const SaveFeedbackButton = () => {
            if(!order.rating && (order.ordered_by == cocktail.getPatronName() || canEdit)) {
                return (
                    <button className="primary" onClick={() => {
                        saveFeedback();
                    }}><i className="fa-solid fa-thumbs-up" /> Rate</button>
                );
            }

            return <></>
        }

        return (
            <>
                <div className="order-actions button-group">
                    <BackButton />
                    <ViewRecipe />
                    <EditButton />
                    <CompleteButton />
                </div>
                <div className="order-actions-feedback">
                    <SaveFeedbackButton />
                </div>
            </>
        );
    }

    const showUpdate = () => {
        navigate(`/orders/${order.id}?update=true`);
    }

    const showUpdateModal = () => {
        setUpdateModalIsVisible(true);
    }

    const showRecipeModal = () => {
        setModalIsVisible(true);
    }

    const hideRecipeModal = () => {
        setModalIsVisible(false);
    }

    if(!order.id) {
        return <div>order definition not provided</div>;
    }

    return (
        <>
            <div className="order">
                <div className="inner">
                    <Icon />
                    <div className="title">{order['recipe_name']}</div>
                    <div className="details">
                        <div className="spirits">
                            {
                                order.spirits.map(spirit => {
                                    return <div key={spirit + "-" + order.id} className={spirit == order.primary_spirit ? 'primary-spirit' : 'spirit'}>{spirit}</div>
                                })
                            }
                            <div className="spirit">&nbsp;</div>
                        </div>
                        <hr />
                        <SpiritsActual order={order} setOrder={setOrder} />
                        <Details />
                        <Notes />
                        <Comments />
                        <OrderedByAtOn />
                        <RatingView />
                    </div>
                    <Actions />
                </div>
            </div>
            <Modal displayComponent="recipe" modalId={"recipe-modal-" + order['recipe_id']} recipe={recipe} visible={modalIsVisible} hideModal={hideRecipeModal} loadRecipe={loadRecipe} />
            <Modal displayComponent="image-set" modalId={"image-set-" + order.id} order={order} visible={imageModalIsVisible} hideModal={hideImageModal} />
            <Modal displayComponent="image-view" modalId={"image-view-" + order.id} order={order} visible={imageViewModalIsVisible} hideModal={hideImageViewModal} editImage={showEditImage} />
        </>
    );
}

export default Order;