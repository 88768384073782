import React, {useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrderTypesHistogram = ({ patron, daysAgo }) => {
    const [chartData, setChartData] = useState(null);
    const [uniqueTypes, setUniqueTypes] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        metrics.getOrderTypesHistogram({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(ordersHistogram => {
            setChartData(ordersHistogram);

            let _uniqueTypes = [];

            ordersHistogram.metrics.forEach(orderBucket => {
                orderBucket.types.forEach(typeBucket => {
                    if(!_uniqueTypes.includes(typeBucket.spirit)) {
                        _uniqueTypes.push(typeBucket.spirit);
                    }
                });
            });

            setUniqueTypes(_uniqueTypes);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData || !uniqueTypes) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'spline'
                    },
                    title: {
                        text: 'Drink Types Ordered By Month' + (patron ? ` (${patron})` : '')
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return 'Total orders by drink type per month';
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `Drink Types by Month ${patron ? `for ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        type: "datetime"
                    },
                    yAxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return `<b>${this.y}</b> ${this.series.name} Drinks Ordered`;
                        }
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        dateFormat: "YYYY-mm-dd"
                    },
                    series: uniqueTypes.map((type, i) => {
                        return {
                            "name" : type,
                            "data" : chartData.metrics.map(orderBucket => {
                                let typeResults = orderBucket.types.filter(typeBucket => typeBucket.spirit == type).map(typeBucket => {
                                    return {
                                        "x" : new Date(orderBucket.date).getTime(),
                                        "y" : typeBucket.count,
                                        "date" : orderBucket.date
                                    }
                                });
                                return typeResults.length > 0 ? typeResults[0] : {
                                    "x" : new Date(orderBucket.date).getTime(),
                                    "y" : 0,
                                    "date" : orderBucket.date
                                }
                            }),
                            "color" : graphColors[i % 10]
                        }
                    }),
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default OrderTypesHistogram;