import React, {useState, useEffect, useReducer} from 'react';
import Recipe from './Recipe';

import styles from './css/RecipeList.css';

const RecipeList = ({ recipes, showRecipeEdit, refreshSearch }) => {
    
    const ShowRecipes = () => {
        if(!recipes) {
            return (
                <div className="recipe-list"></div>
            );
        } else if(recipes.length > 0) {
            return (
                <div key="recipe-list" className="recipe-list">
                    {
                        recipes.map((recipe) => {
                            return <Recipe
                                key={recipe.id}
                                recipeDef={recipe}
                                showRecipeEdit={showRecipeEdit}
                                refreshSearch={refreshSearch}
                                displayType="list"
                            />
                        })
                    }
                </div>
            );
        } else {
            return (
                <>
                    <div className="recipe-list-empty">
                        Oh noes... Such empty
                    </div>
                </>
            )
        }
    }

    return (
        <ShowRecipes />
    );
}

export default RecipeList;