import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

require("highcharts/highcharts-more")(Highcharts);

const OrderSpiritsActualByType = ({ patron, daysAgo }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    // Make monochrome colors
    const pieColors = (function () {
        var colors = [],
            base = Highcharts.getOptions().colors[6],
            i;

        for (i = 0; i < 10; i += 1) {
            // Start out with a darkened base color (negative brighten), and end
            // up with a much brighter color
            colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
        }
        return colors;
    }());

    useEffect(() => {
        metrics.getOrderSpiritsActualByType().then(spiritsActual => {
            setChartData(spiritsActual);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'packedbubble',
                        height: '100%'
                    },
                    title: {
                        text: 'Brands Used by Spirit Type' + (patron ? ` (${patron})` : '')
                    },
                    tooltip: {
                        useHTML: true,
                        pointFormat: '<b>{point.name}:</b> {point.value} Orders'
                    },
                    plotOptions: {
                        packedbubble: {
                            minSize: '10%',
                            maxSize: '300%',
                            zMin: 0,
                            zMax: 1000,
                            layoutAlgorithm: {
                                gravitationalConstant: 0.05,
                                splitSeries: true,
                                seriesInteraction: false,
                                dragBetweenSeries: true,
                                parentNodeLimit: true
                            },
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}',
                                filter: {
                                    property: 'y',
                                    operator: '>',
                                    value: 250
                                },
                                style: {
                                    color: 'black',
                                    textOutline: 'none',
                                    fontWeight: 'normal'
                                }
                            }
                        }
                    },
                    legend: {
                        maxHeight: 80
                    },
                    series: [...new Set(chartData.spirits_metrics.map(result => {
                        return result.spirit_type;
                    }))].map(spirit_type => {
                        return {
                            "name" : spirit_type,
                            "data" : chartData.spirits_metrics.filter(result => result.spirit_type == spirit_type).map(result => {
                                return {
                                    "name" : result.actual_spirit,
                                    "value" : result.count
                                }
                            })
                        }
                    }),
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default OrderSpiritsActualByType;