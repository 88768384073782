import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index';
import './index.css';
import moment from "moment";
import App from './App';
import Login from './Login';
import AlertModal from './AlertModal';

import idm from './helper/idm';
import app from './helper/app';
import ws from './helper/ws';
import config from './helper/config';

//import reportWebVitals from './reportWebVitals';

// FOR PRODUCTION, ADD <React.StrictMode> at the start/end of the render block
// <React.StrictMode> was removed because of double API calls
const root = createRoot(document.getElementById('root'))

const main = () => {
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );
}


const login = () => {
    root.render(
        <>
            <Login loginSuccessful={(loginSuccessful)} visible={true} />
            <AlertModal />
        </>
    );
}


const loginSuccessful = () => {
    window.location.reload();
}


const init = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // if a token identifier exists, process it
    // token identifiers allow for anonymous sessions to be created for
    // sharing links to recipes or other resources
    if(params.t) {
        let queryList = [];
        Object.keys(params).forEach(key => {
            if(key !== "t") {
                queryList.push([key, params[key]]);
            }
        })

        // retrieve the latest tokens based on the identifer
        ws.apiRequest({
            "path" : `/auth/token/readonly/${params.t}`,
            "method" : "GET"
        }).then(output => {
            // set the tokens returned by the web services in session storage
            idm.setTokens({
                "idToken" : output.data.id_token,
                "accessToken" : output.data.access_token,
                "refreshToken" : output.data.refresh_token
            });
        
            // calculate the appropriate expiration times and set them in
            // session storage
            const momentNow = moment();
            let expiresInSeconds = moment((momentNow.unix() + output.data.expires_in) * 1000).unix();
            let refreshExpiresInSeconds = moment((momentNow.unix() + output.data.refresh_expires_in) * 1000).unix();
        
            idm.setExpires(expiresInSeconds);
            idm.setRefreshExpires(refreshExpiresInSeconds);
            idm.setSessionExpires(moment().unix() + config.session.expire_time);
            
            // redirect to the current URL without the token idenfier in
            // the query string
            const redirectTo = `${window.location.protocol}//${window.location.host}${window.location.pathname}${queryList.length > 0 ? '?' + queryList.map(qi => { return qi[0] + "=" + qi[1]}).join("&") : ''}`;
            window.location.href = redirectTo;
        }).catch(e => {
            // assume the request failed because the session
            // has expired (tokens and sessions can have different expirations)
            login();
        })
    } else {
        ws.apiRequest({
            "path" : "/auth/required",
            "method" : "GET"
        }).then(output => {
            sessionStorage.setItem("localClient", output.data.response.local_client);
            main();
        }).catch(e => {
            login();
        });
    }
}


init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
