'use strict';

import axios from 'axios';
//import keycloak from "../Keycloak";

import idm from './idm';
import config from './config';

class WebserviceError extends Error {
    constructor({ message="", status=500, request={}, response={}}) {
        super(message);

        this.message = message;
        this.status = status;
        this.request = request;
        this.response = response;
    }
}

var __EXPORTS = {};

const API_HOST = config.api.host ? `${config.api.ssl ? "https://" : "http://"}${config.api.host}` : "";
const API_ROOT = config.api.root;

const apiRequest = (options = {}) => {
    let apiRoot = "";
    const context = options.path.match(/^\/\w+/i)[0].replace(/^\//, "");

    if(config.api.contextRoot[context]) {
        apiRoot = config.api.contextRoot[context];
    } else {
        apiRoot = config.api.root;
    }

    let headers = {
        "Accept" : "application/json"
    };

    if(idm.isLoggedIn()) {
        headers['Authorization'] = `Bearer ${idm.getAccessToken()}`;
    }

    if(options.data) {
        headers['Content-Type'] = "application/json";
    }

    let axConfig = {
        "method" : options.method,
        "url" : API_HOST + apiRoot + options.path,
        "headers" : headers,
        "params" : options.params,
        "data" : options.data ? JSON.stringify(options.data) : null,
        "responseType" : "json"
    }

    if(options.headers) {
        for(let key in options.headers) {
            axConfig.headers[key] = options.headers[key];
        }
    }
    
    if(options.callback) {
        axios(axConfig).then((response) => {
            options.callback(response.data);
        }).catch((error) => {
            if(error.response) {
                console.error(`${config.app.name}: API services responded with a ${error.response.status} code; Full error: ${JSON.stringify(error.response.data)}`);
            } else if(error.request) {
                console.error(`${config.app.name}: API services could not be reached with the following error: ${error.request}`);
            } else {
                console.error(`${config.app.name}: API services encountered an unknown error: ${error.message}; Request details: ${JSON.stringify(axConfig, null, "    ")}`);
            }
        });
    } else {
        return axios(axConfig);
    }
    
}
__EXPORTS['apiRequest'] = apiRequest;


const call = async (options = {}) => {
    let apiRoot = "";
    const context = options.path.match(/^\/\w+/i)[0].replace(/^\//, "");

    if(config.api.contextRoot[context]) {
        apiRoot = config.api.contextRoot[context];
    } else {
        apiRoot = config.api.root;
    }

    let headers = {
        "Accept" : "application/json"
    };

    if(idm.isLoggedIn()) {
        headers['Authorization'] = `Bearer ${idm.getAccessToken()}`;
    }

    if(options.data) {
        headers['Content-Type'] = "application/json";
    }

    let axConfig = {
        "method" : options.method,
        "url" : API_HOST + apiRoot + options.path,
        "headers" : headers,
        "params" : options.params,
        "data" : options.data ? JSON.stringify(options.data) : null,
        "responseType" : "json"
    }

    if(options.headers) {
        for(let key in options.headers) {
            axConfig.headers[key] = options.headers[key];
        }
    }

    try {
        const response = await axios(axConfig);
        return response.data;
    } catch(e) {
        throw new WebserviceError(e, 500, axConfig, null);
    }
}
__EXPORTS['call'] = call;


const getEndpoint = uri => {
    return API_HOST + API_ROOT + uri;
}
__EXPORTS['getEndpoint'] = getEndpoint

export default __EXPORTS;
