import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import domtoimage from 'dom-to-image';

import ShareModal from './ShareModal';
import TypeaheadField from './TypeaheadField';

import app from './helper/app';
import cocktail from './helper/cocktail';
import idm from './helper/idm';
import ws from './helper/ws';
import config from './helper/config';

import {setRecipeSingle} from './reducers/recipesSlice';
import {set as setTags} from './reducers/tagsSlice';

import styles from './css/RecipeActions.css';
import fastyles from './fa/css/all.css';

const useOutsideBlur = (ref, hideMenu) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const RecipeActions = ({
    recipe,
    order = {},
    showRecipeEdit,
    handleDelete,
    hideRecipeModal,
    displayType,
    recipeRef
}) => {
    const [orderFormIsVisible, setOrderFormIsVisible] = useState(false);
    const [quickTagsVisible, setQuickTagsVisible] = useState(false);
    const [quickVariationsVisible, setQuickVariationsVisible] = useState(false);
    const [shareVisible, setShareVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [readonlyToken, setReadonlyToken] = useState(null);
    const [recipeTags, setRecipeTags] = useState(recipe.tags ?? []);
    const [recipeVariations, setRecipeVariations] = useState(recipe.variations ?? []);
    const [imageShareInvoke, setImageShareInvoke] = useState(false);
    const [imageShareData, setImageShareData] = useState(null);
    //const [patronsCache, setPatronsCache] = useState([]);
    //const [tagCache, setTagCache] = useState([]);
    //const [recipeCache, setRecipeCache] = useState([]);
    //const [locationCache, setLocationCache] = useState([]);
    const [patronErrorVisible, setPatronErrorVisible] = useState(false);
    const patrons = useSelector((state) => state.patrons.list);
    const recipeCache = useSelector((state) => state.recipeCache.list);
    const tags = useSelector((state) => state.tags.list);
    const locations = useSelector((state) => state.locations.list);
    const recipes = useSelector((state) => state.recipes.list);
    const actionsMenuRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //console.log(`<RecipeAction>${Date.now()}</RecipeAction>`);

    useEffect(() => {
        hideErrorMessage();
    }, [quickTagsVisible, quickVariationsVisible]);


    useEffect(() => {
        if(imageShareInvoke) {
            shareRecipe();
            setImageShareInvoke(false);
        }
    }, [imageShareInvoke]);


    const filterPatrons = text => {
        const regex1 = new RegExp(text, "gi");
        return patrons.filter(patron => regex1.test(patron)).sort();
    }


    const filterLocations = text => {
        const regex1 = new RegExp(text, "gi");
        return locations.filter(location => regex1.test(location)).sort();
    }


    const filterTags = text => {
        const filterText = getFilterText(text);
        const regex1 = new RegExp(filterText, "gi");
        return tags.filter(tag => regex1.test(tag.tag) && !recipeTags.includes(tag.tag)).map(tag => {
            return {
                "key" : tag.tag,
                "display" : `${tag.tag} (${tag.count})`
            }
        }).sort();
    }


    const filterRecipes = text => {
        const filterText = getFilterText(text);
        const regex1 = new RegExp(filterText, "gi");
        const regex2 = new RegExp(`${filterText.split(/\s+/g).join("|")}`, "gi");
        return recipeCache.filter(document => 
            (
                regex1.test(document.name) ||
                regex2.test(document.name) ||
                regex1.test(document.description) ||
                regex2.test(document.description) ||
                regex1.test(document.primary_spirit) ||
                regex2.test(document.primary_spirit)
            )
            && document.name !== recipe.name // removes the current recipe (cannot be a variant of itself)
            && !recipeVariations.includes(document.name)        
        ).map(recipe => {
            return recipe.name;
        }).sort();
    }


    const getFilterText = text => {
        return text.split(/,\s+?/).pop();
    }


    const hideActionsMenu = () => {
        setMenuVisible(false);
    }


    const createOrder = (recipeId) => {
        const clearFormValues = () => {
            document.getElementById("order-details-" + recipeId).value = "";
        }

        let details = document.getElementById('order-details-' + recipeId).value;
        let orderedBy = document.getElementById('ordered-by-' + recipeId).value;
        let orderedAt = document.getElementById('ordered-at-' + recipeId) ? document.getElementById('ordered-at-' + recipeId).value : null;

        if(!orderedBy) {
            setPatronErrorVisible(true);
            return;
        } else if(document.getElementById('ordered-at-' + recipeId) && !orderedAt) {
            app.showAlertModal({
                "type" : "error",
                "message" : `Drink orders not submitted at ${config.app.default_establishment} require the place they were ordered from.  Example: The Lake House.`
            });
            return;
        }
        
        cocktail.orderCreate({
            "recipeId" : recipe.id,
            "orderedBy" : orderedBy,
            "orderedAt" : orderedAt,
            "details" : details
        }).then(orderId => {
            clearFormValues();
            setOrderFormIsVisible(false);
            setPatronErrorVisible(false);

            // update the patron information in local storage if it's different
            // than the submitted order and if no user is currently logged in
            if(orderedBy !== cocktail.getPatronName() && !idm.isLoggedIn()) {
                cocktail.setPatronName(orderedBy);
            }

            // set the establishment name in local storage if ordered non-locally
            if(orderedAt !== cocktail.getEstablishmentName()) {
                cocktail.setEstablishmentName(orderedAt);
            }

            navigate(`/orders/${orderId}`);
        }).catch(e => {

        });
    }


    const deleteRecipe = () => {
        app.showConfirmModal({
            "type" : "error",
            "message" : "You are about to delete this <b>RECIPE</b>, an operation that cannot be undone.  Please confirm this action.",
            "buttonText" : "Confirm",
            "handleConfirm" : () => {
                app.hideConfirmModal();

                handleDelete();
            }
        });
    }


    const saveSeasonal = (tag, add) => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return;
        }

        let tags = recipe.tags ? [...recipe.tags] : [];

        if(add) {
            tags.push(tag);
        } else {
            tags = tags.filter(item => item !== tag);
        }

        cocktail.recipeTagsUpdate({
            "id" : recipe.id,
            "tags" : tags
        }).then(recipeDoc => {
            dispatch(setRecipeSingle(recipeDoc));

            // get the latest tags and set the state cache
            cocktail.getTags().then(tagList => {
                dispatch(setTags(tagList));
            }).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });
    }


    const saveFavorite = add => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return;
        }

        let tags = recipe.tags ? [...recipe.tags] : [];

        if(add) {
            tags.push("Favorite");
        } else {
            tags = tags.filter(item => item !== "Favorite");
        }

        cocktail.recipeTagsUpdate({
            "id" : recipe.id,
            "tags" : tags
        }).then(recipeDoc => {
            dispatch(setRecipeSingle(recipeDoc));

            // get the latest tags and set the state cache
            cocktail.getTags().then(tagList => {
                dispatch(setTags(tagList));
            }).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });
    }


    const saveTags = () => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return;
        }

        cocktail.recipeTagsUpdate({
            "id" : recipe.id,
            "tags" : recipeTags
        }).then(recipeDoc => {
            dispatch(setRecipeSingle(recipeDoc));

            // get the latest tags and set the state cache
            cocktail.getTags().then(tagList => {
                dispatch(setTags(tagList));
            }).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });
    }


    const saveVariations = () => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return;
        }

        const validateVariations = variations => {
            const unmatched = [];
    
            // filter out the empty string element if the field is empty
            variations.filter(variation => variation !== "").forEach(variation => {
                if(!recipeCache.find(recipe => recipe.name === variation)) {
                    unmatched.push(variation);
                }
            });
    
            return unmatched.length > 0 ? `The following variations do not exist: ${unmatched.join(", ")}` : false;
        }

        if(recipeVariations.length > 0) {
            let unmatched = validateVariations(recipeVariations);

            if(unmatched) {
                return unmatched;
            }
        }

        cocktail.recipeVariationsUpdate({
            "id" : recipe.id,
            "variations" : recipeVariations ?? []
        }).then(recipeDoc => {
            dispatch(setRecipeSingle(recipeDoc));
        }).catch(e => {
            console.error(e);
        });
    }


    const shareRecipe = () => {
        setShareVisible(true);
        genShareImage();

        if(idm.tokenHasRole("recipe_share")) {
            ws.apiRequest({
                "path" : "/auth/token/readonly",
                "method" : "PUT"
            }).then(output => {
                setReadonlyToken(output.data.response.id);
            }).catch(e => {
                console.error(e);
            });
        }
    }


    const genShareImage = async () => {
        domtoimage.toPng(recipeRef.current).then(dataUrl => {
            setImageShareData(dataUrl);
            setShareVisible(true);
        }).catch(e => {
            console.error(e);
        });
    }


    const closeShareModal = () => {
        setImageShareData(null);
        setReadonlyToken(null);

    }


    const RecipeMenu = () => {
        if(!recipe.order_count && !idm.tokenHasRole("recipe_admin")) {
            return <></>
        }

        const Seasonal = () => {
            if(idm.tokenHasRole("recipe_admin")) {
                const tags = recipe.tags ?? [];
                const seasonTag = cocktail.getCurrentSeasonTag();

                if(tags.includes(seasonTag)) {
                    return (
                        <li onClick={() => {
                            setMenuVisible(false);
                            saveSeasonal(seasonTag, false);
                        }}>Remove Seasonal</li>
                    );
                } else {
                    return (
                        <li onClick={() => {
                            setMenuVisible(false);
                            saveSeasonal(seasonTag, true);
                        }}>Add Seasonal</li>
                    );
                }
            }

            return <></>
        }

        const Favorite = () => {
            if(idm.tokenHasRole("recipe_admin")) {
                const tags = recipe.tags ?? [];
                if(tags.includes("Favorite")) {
                    return (
                        <li onClick={() => {
                            setMenuVisible(false);
                            saveFavorite(false);
                        }}>Remove Favorite</li>
                    );
                } else {
                    return (
                        <li onClick={() => {
                            setMenuVisible(false);
                            saveFavorite(true);
                        }}>Add Favorite</li>
                    );
                }
            }

            return <></>
        }

        const Variations = () => {
            if(idm.tokenHasRole("recipe_admin")) {
                return (
                    <li onClick={() => {
                        setMenuVisible(false);
                        setQuickVariationsVisible(true);
                    }}>Edit Variations</li>
                );
            }

            return <></>
        }

        const Tags = () => {
            if(idm.tokenHasRole("recipe_admin")) {
                return (
                    <li onClick={() => {
                        setMenuVisible(false);
                        setQuickTagsVisible(true);
                    }}>Edit Tags</li>
                );
            }

            return <></>
        }

        return (
            <>
                <button className={`last ${menuVisible ? "show-menu" : "hide-menu"}`} onClick={() => {
                    setMenuVisible(menuVisible ? false : true);
                }}><i className="fa-solid fa-ellipsis-vertical" /></button>
                <div className={`recipe-menu ${menuVisible ? "show" : "hide"}`}>
                    <ul className="menu">
                        <li onClick={() => {
                            navigate(`/orders/recipes/${recipe.id}`);
                        }}>View Orders</li>
                        <Seasonal />
                        <Favorite />
                        <Variations />
                        <Tags />
                    </ul>
                </div>
                <div className={`recipe-menu-cover ${menuVisible ? "show" : "hide"}`}></div>
            </>
        )
    }


    const RecipeRandomRefresh = () => {
        if(displayType === "random") {
            return (
                <button onClick={() => { 
                    navigate(`/recipes/random?r=${Math.random()}`);
                }}><i className="fa fa-arrow-rotate-right" /></button>
            )
        } else {
            return <></>
        }
    }


    const ModalCloseButton = () => {
        const handleDismiss = () => {
            if(displayType === "modal") {
                hideRecipeModal();
            } else if(navigate.length <= 2 && displayType == "single") {
                // handle direction navigation with no history
                navigate("/recipes");
            } else {
                // navigate back
                navigate(-1);
            }
        }

        //if(hideRecipeModal) {
        if(displayType === "modal" || displayType == "random" || displayType == "single") {
            return (
                <button onClick={() => {
                    handleDismiss();
                }}><i className="fa-solid fa-arrow-left" /></button>
            );
        }

        return "";
    }


    const Actions = () => {
        const EditActions = () => {
            if(idm.tokenHasRole("recipe_admin") && displayType !== "modal") {
                return (
                    <div className="button-group">
                        <button className="delete-button" onClick={() => { 
                            deleteRecipe();
                        }}><i className="fa-regular fa-trash-can" /></button>
                        <button className="edit-button" onClick={() => {
                            showRecipeEdit(recipe);
                        }}><i className="fa-regular fa-pen-to-square" /></button>
                    </div>
                );
            } else if(displayType === "modal") {
                return (
                    <div className="button-group">
                        <ModalCloseButton />
                    </div>
                );
            }
    
            return <></>;
        }

        const RecipeStats = () => {
            if(recipe.order_count) {
                return (
                    <button id="recipe-metrics" onClick={() => {
                        setMenuVisible(false);
                        navigate(`/metrics/recipes/${recipe.id}`);
                    }}><i className="fa fa-chart-simple" /></button>
                )
            } else {
                return <></>
            }
        }

        const RecipeShare = () => {
            return (
                <button id="share-recipe" onClick={() => {
                    setImageShareInvoke(true);
                    setMenuVisible(false);
                }}><i className="fa-solid fa-arrow-up-from-bracket" /></button>
            );
        }

        return (
            <div className={"recipe-actions " + (orderFormIsVisible || quickTagsVisible || quickVariationsVisible ? "hide" : "flex-show")}>
                <EditActions />
                <div className="button-group button-group-right">
                    <button id="order-drink" className="action primary" onClick={() => {
                        setOrderFormIsVisible(true)
                    }}><i className="fa-solid fa-whiskey-glass" /> Order</button>
                    <RecipeStats />
                    <RecipeShare />
                    <RecipeRandomRefresh />
                    <RecipeMenu />
                </div>
            </div>
        );
    }


    const QuickVariations = () => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return <></>
        } else if(!quickVariationsVisible) {
            return <></>
        }

        return (
            <div className="quick-edit-container">
                <TypeaheadField
                    key={`recipe-variations-key-${recipe.id}`}
                    fieldId={`recipe-variations-${recipe.id}`}
                    multiValues={recipeVariations}
                    multiSelect={true}
                    multiAllowNew={false}
                    multiSetValues={(values) => {
                        setRecipeVariations(values);
                    }}
                    filterMatches={filterRecipes}
                    selectAction={(tagValue) => {
                        setRecipeVariations([...recipeVariations, tagValue]);
                    }}
                    showAllButton={true}
                />
                <div className="button-group button-group-right">
                    <button className="primary" onClick={() => {
                        const error = saveVariations();
                        if(!error) {
                            setQuickVariationsVisible(false);
                        } else {
                            showErrorMessage(`<i className="fa-solid fa-circle-exclamation" /> ${error}`);
                        }
                    }}><i className="fa-solid fa-floppy-disk" /> Save</button>
                    <button onClick={() => {
                        setQuickVariationsVisible(false);
                    }}><i className="fa-solid fa-xmark" /></button>
                </div>
            </div>
        );
    }


    const QuickTags = () => {
        if(!idm.tokenHasRole("recipe_admin")) {
            return <></>
        } else if(!quickTagsVisible) {
            return <></>
        }

        return (
            <div className="quick-edit-container">
                <TypeaheadField
                    key={`recipe-tags-key-${recipe.id}`}
                    fieldId={`recipe-tags-${recipe.id}`}
                    multiValues={recipeTags}
                    multiSelect={true}
                    defaultValue=""
                    multiSetValues={(values) => {
                        setRecipeTags(values);
                    }}
                    filterMatches={filterTags}
                    selectAction={(tagValue) => {
                        setRecipeTags([...recipeTags, tagValue]);
                    }}
                    showAllButton={true}
                />
                <div className="button-group button-group-right">
                    <button className="primary" onClick={() => {
                        saveTags();
                        setQuickTagsVisible(false);
                    }}><i className="fa-solid fa-floppy-disk" /> Save</button>
                    <button onClick={() => {
                        setQuickTagsVisible(false);
                        setRecipeTags(recipe.tags);
                    }}><i className="fa-solid fa-xmark" /></button>
                </div>
            </div>
        );
    }


    const showErrorMessage = message => {
        let el = document.getElementById(`recipe-error-${recipe.id}`);
        el.innerHTML = message;
        el.classList.remove("hide");
        el.classList.add("show");
    }


    const hideErrorMessage = () => {
        if(!recipe.id) {
            return;
        }

        let el = document.getElementById(`recipe-error-${recipe.id}`);
        if(el) {
            el.classList.add("hide");
            el.classList.remove("show");
        }
    }


    const ErrorMessage = () => {
        return (
            <div id={`recipe-error-${recipe.id}`} className="recipe-error hide">
                
            </div>
        )
    }


    const Patron = () => {
        if(idm.tokenHasRole("order_admin")) {
            return (
                <TypeaheadField
                    key={"ordered-by-" + recipe.id}
                    fieldId={"ordered-by-" + recipe.id}
                    className="ordered-by"
                    placeholder="Your Name"
                    defaultValue={cocktail.getPatronName()}
                    filterMatches={filterPatrons}
                    showAllButton={true}
                />
            )
        } else {
            return <input id={"ordered-by-" + recipe.id} className="ordered-by" placeholder="Your Name" defaultValue={cocktail.getPatronName()} />
        }
    }


    const OrderedAt = () => {
        if(sessionStorage.getItem("localClient") === "true") {
            return <></>
        } else if(idm.tokenHasRole("order_admin")) {
            return (
                <TypeaheadField
                    key={"ordered-at-" + recipe.id}
                    fieldId={"ordered-at-" + recipe.id}
                    className="ordered-at"
                    placeholder="Establishment"
                    defaultValue={config.app.default_establishment}
                    filterMatches={filterLocations}
                    showAllButton={true}
                />
            )
        } else {
            return (
                <input
                    id={"ordered-at-" + recipe.id}
                    className="ordered-at" 
                    placeholder="Establishment"
                    readOnly={ idm.tokenHasRole("location_write") ? false : true }
                    defaultValue={ cocktail.getEstablishmentName() ?? config.app.default_establishment }
                />
            );
        }
    }


    // setup the off click typeahead hider
    useOutsideBlur(actionsMenuRef, hideActionsMenu);


    if(idm.tokenHasRole("recipe_readonly")) {
        if(displayType === "random") {
            return (
                <div className="order-section actions show">
                    <RecipeRandomRefresh />
                </div>
            )
        }

        return <></>
    } else {
        return (
            <div className="actions-container" ref={actionsMenuRef}>
                <Actions />
                <div key={"order-" + order.id} className="order-section">
                    <div className={"order-form " + (orderFormIsVisible ? "show" : "hide")}>
                        <textarea id={"order-details-" + recipe.id} className="order-details" placeholder="Special Instructions" />
                        <div className={`error-message ${patronErrorVisible ? "show" : ""}`}><i className="fa-solid fa-circle-exclamation" /> Please provide your name to order a drink</div>
                        <Patron />
                        <OrderedAt />
                        <div className="order-actions button-group">
                            <button className="primary" onClick={() => {createOrder(recipe.id)}}><i className="fa-solid fa-whiskey-glass" /> Submit</button>
                            <button onClick={() => setOrderFormIsVisible(false)}><i className="fa-solid fa-xmark" /></button>
                        </div>
                    </div>
                    <ErrorMessage />
                    <QuickVariations />
                    <QuickTags />
                </div>
                <ShareModal recipe={recipe} isVisible={shareVisible} setIsVisible={setShareVisible} tokenId={readonlyToken} imageShareData={imageShareData} closeShareModal={closeShareModal} />
            </div>
        );
    }
}

export default RecipeActions;