import { createSlice } from '@reduxjs/toolkit';

export const patronsSlice = createSlice({
    name: 'patrons',
    initialState: {
        list: [],
    },
    reducers: {
        /*
        increment: (state) => {
            //state.value += 1;
        },
        decrement: (state) => {
            //state.value = state.value - 1;
        },
        */
        set : (state, input) => {
            state.list = input.payload;
        },
        fetch : (state) => {
            // fetch from server TBD
        }
    },
});

export const { set, fetch } = patronsSlice.actions;
export default patronsSlice.reducer;