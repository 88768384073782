import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import styles from './css/NavModal.css';
import config from './helper/config';

const NavIngredients = ({ ingredients, hideMenu, navTo, HeaderNav }) => {
    const navigate = useNavigate();
    const [ingredientFilter, setIngredientFilter] = useState("");
    
    return (
        <>
            <HeaderNav title="Drinks by Ingredients" backTo="recipes" />
            <div className="inner-ingredients">
                <div className="ingredient-select">
                    <input
                        id="ingredient-filter"
                        type="text"
                        placeholder="Select Ingredients You Have"
                        onChange={(e) => {
                            setIngredientFilter(e.target.value);
                        }}
                    />
                    <button onClick={() => {
                        ingredients.forEach(ingredient => {
                            document.getElementById(`nav-ingredient-${ingredient.value}`).classList.remove("selected");
                            setIngredientFilter("");
                        });
                    }} className="clear-selected">
                        <i className="fa-solid fa-xmark" />
                    </button>
                    <button onClick={() => {
                        document.getElementById("ingredient-filter").value = "";
                        ingredients.map(ingredient => {
                            if(document.getElementById(`nav-ingredient-${ingredient.value}`).classList.contains("selected")) {
                                ingredient.selected = true;
                            } else {
                                ingredient.selected = false;
                            }

                            return ingredient;
                        });

                        const ingredientsToSearch = ingredients.filter(ingredient => ingredient.selected).map(ingredient => { return encodeURIComponent(ingredient.display) });

                        if(ingredientsToSearch.length > 0) {
                            setIngredientFilter("");
                            navigate(
                                `/recipes/ingredients?ingredients=${ingredientsToSearch.join(",")}`
                            );
                            hideMenu();
                        }
                    }} className="select">
                        <i className="fa-solid fa-clipboard-check" /> Done
                    </button>
                </div>
                <div className="items-select">
                    {
                        ingredients.map(ingredient => {
                            return (
                                <div key={`nav-ingredient-${ingredient.value}`}
                                id={`nav-ingredient-${ingredient.value}`}
                                value={ingredient.value}
                                className={`ingredient-select ${ingredient.value.includes(ingredientFilter) ? "ingredient-show" : "hide"} ${ingredient.selected ? "selected" : ""}`}
                                onClick={(e) => {
                                    if(e.target.classList.contains("selected")) {
                                        e.target.classList.remove("selected");
                                        ingredients.find(ingredient => ingredient.value === e.target.getAttribute("value")).selected = false;
                                    } else {
                                        e.target.classList.add("selected");
                                        ingredients.find(ingredient => ingredient.value === e.target.getAttribute("value")).selected = true;
                                    }
                                }}>{ingredient.display}</div>
                            )
                        })
                    }
                    <div onClick={ () => {
                        if(document.getElementById("ingredient-filter").value) {
                            document.getElementById("ingredient-filter").value = "";
                            setIngredientFilter("");
                        } else {
                            navTo("recipes");
                            hideMenu();
                        }
                    }} className="back"></div>
                </div>
            </div>
        </>
    );
}

export default NavIngredients;