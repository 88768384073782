import { configureStore } from '@reduxjs/toolkit';

import appReducer from '../reducers/appSlice';
import patronsReducer from '../reducers/patronsSlice';
import recipeCacheReducer from '../reducers/recipeCacheSlice';
import recipesReducer from '../reducers/recipesSlice';
import tagsReducer from '../reducers/tagsSlice';
import locationsReducer from '../reducers/locationsSlice';

export default configureStore({
    reducer: {
        app: appReducer,
        patrons: patronsReducer,
        recipeCache: recipeCacheReducer,
        tags: tagsReducer,
        locations: locationsReducer,
        recipes: recipesReducer
    }
});