'use strict';

var __EXPORTS = {};


const showAlertModal = ({
    type,
    message
}) => {
    const modal = document.getElementById("alert-modal");

    document.getElementById("alert-message").innerHTML = message;


    modal.classList.remove("hide");
    modal.classList.add("show");

    if(type == "error") {
        modal.classList.add("error");
        modal.classList.remove("info");
        modal.classList.remove("warning");
    } else if(type == "warning") {
        modal.classList.add("warning");
        modal.classList.remove("error");
        modal.classList.remove("info");
    } else if(type == "info") {
        modal.classList.add("info");
        modal.classList.remove("error");
        modal.classList.remove("warning");
    }
}
__EXPORTS['showAlertModal'] = showAlertModal;


const hideAlertModal = () => {
    const modal = document.getElementById("alert-modal");

    modal.classList.remove("show");
    modal.classList.add("hide");
}
__EXPORTS['hideAlertModal'] = hideAlertModal;


const showConfirmModal = ({
    type,
    message,
    buttonText,
    handleConfirm
}) => {
    const modal = document.getElementById("confirm-modal");

    document.getElementById("confirm-message").innerHTML = message;

    modal.classList.remove("hide");
    modal.classList.add("show");

    if(type == "error") {
        modal.classList.add("error");
        modal.classList.remove("info");
        modal.classList.remove("warning");
    } else if(type == "warning") {
        modal.classList.add("warning");
        modal.classList.remove("error");
        modal.classList.remove("info");
    } else if(type == "info") {
        modal.classList.add("info");
        modal.classList.remove("error");
        modal.classList.remove("warning");
    }

    document.getElementById("confirm-submit").innerHTML = `<i class="fa-solid fa-check"></i> ${buttonText}`;
    document.getElementById("confirm-submit").onclick = () => {
        handleConfirm();
    }
}
__EXPORTS['showConfirmModal'] = showConfirmModal;


const hideConfirmModal = () => {
    const modal = document.getElementById("confirm-modal");

    modal.classList.remove("show");
    modal.classList.add("hide");

    document.getElementById("confirm-submit").onclick = () => {};
}
__EXPORTS['hideConfirmModal'] = hideConfirmModal;


export default __EXPORTS;