'use strict';

import moment from 'moment';
import tinycolor from 'tinycolor2';

import ws from './ws';
import idm from './idm';
import config from './config';

var __EXPORTS = {};

const graphColors = themeColor => {
    return [
        tinycolor(themeColor).lighten(25).toString(),
        tinycolor(themeColor).lighten(20).toString(),
        tinycolor(themeColor).lighten(15).toString(),
        tinycolor(themeColor).lighten(10).toString(),
        tinycolor(themeColor).lighten(5).toString(),
        tinycolor(themeColor).darken(5).toString(),
        tinycolor(themeColor).darken(10).toString(),
        tinycolor(themeColor).darken(15).toString(),
        tinycolor(themeColor).darken(20).toString(),
        tinycolor(themeColor).darken(25).toString()
    ]
}
__EXPORTS['graphColors'] = graphColors;

const graphColorsPie = themeColor => {
    return [
        tinycolor(themeColor).lighten(5).toString(),
        tinycolor(themeColor).lighten(10).toString(),
        tinycolor(themeColor).darken(15).toString(),
        tinycolor(themeColor).lighten(25).toString(),
        tinycolor(themeColor).toString(),
        tinycolor(themeColor).darken(20).toString(),
        tinycolor(themeColor).lighten(15).toString(),
        tinycolor(themeColor).darken(5).toString(),
        tinycolor(themeColor).lighten(20).toString(),
        tinycolor(themeColor).darken(10).toString()
    ]
}
__EXPORTS['graphColorsPie'] = graphColorsPie;

const graphColorsAll = () => {
    return [
        "#2782a3",
        "#ff5b5b",
        "#d138d4",
        "#a3273b",
        "#a35227",
        "#27a339",
        "#94a327",
        "#6b27a3",
        "#e78d4d",
        "#93d1cc"
    ];
}
__EXPORTS['graphColorsAll'] = graphColorsAll;

const getRecipesPopular = params => {
    return __getMetrics("/metrics/recipes/popular", params);
}
__EXPORTS['getRecipesPopular'] = getRecipesPopular;


const getRecipesHighRating = params => {
    return __getMetrics("/metrics/recipes/high-rating", params);
}
__EXPORTS['getRecipesHighRating'] = getRecipesHighRating;


const getOrdersHighRating = params => {
    return __getMetrics("/metrics/orders/high-rating", params);
}
__EXPORTS['getOrdersHighRating'] = getOrdersHighRating;


const getRecipeTopPatrons = params => {
    return __getMetrics("/metrics/orders/patrons", params);
}
__EXPORTS['getRecipeTopPatrons'] = getRecipeTopPatrons;


const getRecipePrimarySpirits = params => {
    return __getMetrics("/metrics/recipes/primary-spirits", params);
}
__EXPORTS['getRecipePrimarySpirits'] = getRecipePrimarySpirits;


const getOrderPrimarySpirits = params => {
    return __getMetrics("/metrics/orders/primary-spirits", params);
}
__EXPORTS['getOrderPrimarySpirits'] = getOrderPrimarySpirits;


const getOrderSpiritsActual = params => {
    return __getMetrics("/metrics/orders/actual-spirits", params);
}
__EXPORTS['getOrderSpiritsActual'] = getOrderSpiritsActual;


const getOrderSpiritsActualByType = params => {
    return __getMetrics("/metrics/orders/actual-spirits/types", params);
}
__EXPORTS['getOrderSpiritsActualByType'] = getOrderSpiritsActualByType;


const getRecipeTypes = () => {
    return __getMetrics("/metrics/recipes/types");
}
__EXPORTS['getRecipeTypes'] = getRecipeTypes;


const getOrderTypes = params => {
    return __getMetrics("/metrics/orders/types", params);
}
__EXPORTS['getOrderTypes'] = getOrderTypes;


const getRecipeGlassTypes = () => {
    return __getMetrics("/metrics/recipes/glass-types");
}
__EXPORTS['getRecipeGlassTypes'] = getRecipeGlassTypes;


const getOrderGlassTypes = params => {
    return __getMetrics("/metrics/orders/glass-types", params);
}
__EXPORTS['getOrderGlassTypes'] = getOrderGlassTypes;


const getRecipeGarnishes = params => {
    return __getMetrics("/metrics/recipes/garnishes", params);
}
__EXPORTS['getRecipeGarnishes'] = getRecipeGarnishes;


const getOrderGarnishes = params => {
    return __getMetrics("/metrics/orders/garnishes", params);
}
__EXPORTS['getOrderGarnishes'] = getOrderGarnishes;


const getOrdersHistogram = params => {
    return __getMetrics("/metrics/orders/histogram", params);
}
__EXPORTS['getOrdersHistogram'] = getOrdersHistogram;


const getOrderPrimarySpiritsHistogram = params => {
    return __getMetrics("/metrics/orders/primary-spirits/histogram", params);
}
__EXPORTS['getOrderPrimarySpiritsHistogram'] = getOrderPrimarySpiritsHistogram;


const getOrderTypesHistogram = params => {
    return __getMetrics("/metrics/orders/types/histogram", params);
}
__EXPORTS['getOrderTypesHistogram'] = getOrderTypesHistogram;

const getOrdersByDateType = params => {
    return __getMetrics("/metrics/orders/date", params);
}
__EXPORTS['getOrdersByDateType'] = getOrdersByDateType;


// params.spirit_total = n (returns the top n most used primary spirits)
// default should be 8
const getRecipeTypesPrimarySpirits = params => {
    return __getMetrics("/metrics/recipes/types/primary-spirits", params);
}
__EXPORTS['getRecipeTypesPrimarySpirits'] = getRecipeTypesPrimarySpirits;


// params.total = n (returns the top n results)
// default should be 15
const getRecipePrimarySpiritsGarnishes = params => {
    return __getMetrics("/metrics/recipes/primary-spirits/garnishes", params);
}
__EXPORTS['getRecipePrimarySpiritsGarnishes'] = getRecipePrimarySpiritsGarnishes;


// params.total = n (returns the top n most used primary spirits)
// default should be 10
const getRecipeSpiritsCommonCombination = params => {
    return __getMetrics("/metrics/recipes/primary-spirits/spirits", params);
}
__EXPORTS['getRecipeSpiritsCommonCombination'] = getRecipeSpiritsCommonCombination;


// params.glass_types_total = n (returns the top n most used glass types)
// default should be 8
const getRecipeTypesGlassTypes = (params) => {
    return new Promise((resolve, reject) => {
        __getMetrics("/metrics/recipes/types/glass-types", params).then(output => {
            output.metrics = output.metrics.map(result => {
                return {
                    "type" : result.type,
                    "glass_type" : result.glass_type.replace(/sour/i, "Sour Glass").replace(/tiki/i, "Tiki Glass"),
                    "count" : result.count
                }
            });

            resolve(output);
        }).catch(e => {
            console.error(e);
            reject(e);
        })
    });
}
__EXPORTS['getRecipeTypesGlassTypes'] = getRecipeTypesGlassTypes;


const __getMetrics = (path, params = {}) => {
    return new Promise((resolve, reject) => {
        ws.apiRequest({
            "method" : "GET",
            "path" : path,
            "params" : params
        }).then(output => {
            resolve(output.data.response);
        }).catch(e => {
            console.error(e);
            reject();
        });
    });
}

export default __EXPORTS;