import React, { useState } from "react";
import styles from './css/Pagination.css';

const Pagination = ({currentPage, totalPages, listType, totalResults, resultsPerPage, goToPage}) => {
    if(totalPages < 1) {
        return <></>
    }

    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;
    const startRecord = (currentPage - 1) * resultsPerPage + 1;
    const endRecord = currentPage * resultsPerPage < totalResults ? currentPage * resultsPerPage : totalResults;

    return (
        <div className="pagination">
            <div className="control">
                <button key={`${listType}-start`} className={currentPage == 1 ? "disabled" : ""} onClick={() => {
                    if(currentPage != 1) {
                        goToPage(1);
                    }
                }}><i className="fa-solid fa-backward-step" /></button>
                <button key={`${listType}-${previousPage}`} className={previousPage < 1 ? "disabled" : ""} onClick={() => {
                    if(previousPage > 0) {
                        goToPage(previousPage);
                    }
                }}><i className="fa-solid fa-chevron-left" /></button>
                <button key={`${listType}-current`} className="current">
                    {currentPage}
                </button>
                <button key={`${listType}-${nextPage}`} className={nextPage > totalPages ? "disabled" : ""} onClick={() => {
                    if(nextPage <= totalPages) {
                        goToPage(nextPage);
                    }
                }}><i className="fa-solid fa-chevron-right" /></button>
                <button key={`${listType}-end`} className={currentPage == totalPages ? "disabled" : ""} onClick={() => {
                    if(currentPage != totalPages) {
                        goToPage(totalPages);
                    }
                }}><i className="fa-solid fa-forward-step" /></button>
            </div>
            <div className="info">
                <span>{startRecord} - {endRecord} of {totalResults}</span>
            </div>
        </div>
    );

    /*
    return (
        <div className="pagination">
            {[...Array(totalPages).keys()].map((page) => {
                return (
                    <button key={listType + "-" + page} className={currentPage == page + 1 ? "current" : ""} onClick={() => { goToPage(page + 1) }}>
                        {page + 1}
                    </button>
                );
            })}
        </div>
    );
    */
};

export default Pagination;
