import React, {useState, useEffect, useReducer} from 'react';
import { useSearchParams, useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

import config from './helper/config';
import idm from './helper/idm';
import ws from './helper/ws';

import ErrorBoundry from './ErrorBoundry';
import SearchBox from './SearchBox';
import OrderList from './OrderList';
import Pagination from './Pagination';
import OrderSortMenu from './OrderSortMenu';

import styles from './css/OrderSearch.css';

const OrderSearch = ({ searchType }) => {
    const [searchParams] = useSearchParams();
    const [orders, setOrderList] = useState(null);
    const [page, setPage] = useState(searchParams.get("p") ? Number(searchParams.get("p")) : 1);
    const [totalResults, setTotalResults] = useState(0);
    const [orderSearchText, setOrderSearchText] = useState(
        searchParams.get("q") ||  ''
    );
    const [orderCache, setOrderCache] = useState([]);
    const [error, setError] = useState(null);
    const resultsPerPage = config.app.results_per_page;
    const params = useParams();
    const patron = params.patron;
    const location = params.location;
    const recipeId = params.recipe;
    const spirit = params.spirit;
    const actualSpirit = params.actualSpirit;
    const navigate = useNavigate();
    const currentLocation = useLocation();
    const currentPath = currentLocation.pathname;
    const currentUrl = currentLocation.pathname + currentLocation.search;
    const sortList = {
        "score" : {
            "display" : "Relevance",
            "field" : "_score",
            "direction" : "desc"
        },
        "name" : {
            "display" : "Drink Name",
            "field" : "recipe_name.keyword",
            "direction" : "asc"
        },
        "rating_high" : {
            "display" : "Rating (Highest)",
            "field" : "rating",
            "direction" : "desc"
        },
        "rating_low" : {
            "display" : "Rating (Lowest)",
            "field" : "rating",
            "direction" : "asc"
        },
        "ordered_newer" : {
            "display" : "Ordered On (Newer)",
            "field" : "ordered_on",
            "direction" : "desc"
        },
        "ordered_older" : {
            "display" : "Ordered On (Older)",
            "field" : "ordered_on",
            "direction" : "asc"
        }
    }
    const [sort, setSort] = useState((() => {
        if(sortList[searchParams.get("s")]) {
            return searchParams.get("s");
        } else if(sortList[localStorage.getItem('recipeSearchSort')]) {
            return localStorage.getItem('recipeSearchSort');
        } else {
            return 'ordered_newer';
        }
    })());

    // load the recipe cache (all documents with limited fields)
    // this will facilitate client-side searching (typeahead)
    // routine will run once (on component load)
    useEffect(() => {
        let path = "/orders/cache";

        if(searchType === "patron") {
            path += `/patrons/${patron}`;
        } else if(searchType === "location") {
            path += `/locations/${location}`;
        } else if(searchType === "new") {
            path += "/new";
        } else if(searchType === "recipe") {
            path += `/recipes/${recipeId}`;
        } else if(searchType === "actual-spirit") {
            path += `/actual-spirits/${actualSpirit}`;
        } else if(searchType === "spirit") {
            path += `/spirits/${spirit}`;
        }

        ws.apiRequest({
            "method" : "GET",
            "path" : path,
            "params" : {
                "size" : 1000
            }
        }).then(output => {
            setOrderCache(output.data.results);
        }).catch(e => {
            console.error(e);
        });
    }, [searchType]);

    /*
    * Execute a search whenever the sort, page, or search text changes
    */
    useEffect(() => {
        executeSearch();
        navigate(calculateRoute());
    }, [sort, page, orderSearchText]);


    /*
    * Execute a search whenever parameters are change via URL navigation
    * Do not change the URL
    */
    useEffect(() => {
        setOrderSearchText("");
        executeSearch();
    }, [searchType, spirit, patron, location, actualSpirit]);


    /*
    * state setting from child components
    */
    const setSearchText = text => {
        setOrderSearchText(text);
        setPage(1);
    }


    const setSortText = text => {
        setSort(text);
        setPage(1);
    }


    const setPageNum = (pageNum) => {
        if(page == pageNum) {
            return;
        }

        setPage(pageNum);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }


    const setOrder = (newOrder) => {
        let newOrders = [];

        newOrders = orders.map((order) => {
            return order.id == newOrder.id ? newOrder : order;
        });

        setOrderList(newOrders);
    }


    const deleteOrdersItem = (orderIdToDelete) => {
        executeSearch();
    }


    const filterText = text => {
        const regex1 = new RegExp(text);
        const regex2 = new RegExp(`${text.split(/\s+/g).join("|")}`, "gi");
        const matches = orderCache.filter(order => 
                regex1.test(order.recipe_name) ||
                regex2.test(order.recipe_name) ||
                regex1.test(order.actual_spirits ? order.actual_spirits.join(",") : "") ||
                regex2.test(order.actual_spirits ? order.actual_spirits.join(",") : "") ||
                regex1.test(order.primary_spirit) ||
                regex2.test(order.primary_spirit)
            ).map(order => {
                return order.recipe_name;
            }
        ).sort();

        // remove any duplicates
        return [...new Set(matches)];
    }


    const calculateRoute = () => {
        let qs = {};
        searchParams.forEach((value, key) => {
            qs[key] = value;
        });

        qs.q = orderSearchText;
        qs.p = page;
        qs.s = sort;

        let kv = Object.keys(qs).map(key => {
            return key + "=" + qs[key];
        });

        return currentPath + "?" + kv.join("&");
    }


    const executeSearch = () => {
        let path = "/orders";
        let sortBy = null;
        let sortDir = null;
        let method = "GET";
        let query = {};

        if(sort) {
            sortBy = sortList[sort].field;
            sortDir = sortList[sort].direction;
        }

        if(searchType === "patron") {
            path += `/patrons/${patron}`;
        } else if(searchType === "location") {
            path += `/locations/${location}`;
        } else if(searchType === "new") {
            path += "/new";
        } else if(searchType === "recipe") {
            path += `/recipes/${recipeId}`;
        } else if(searchType === "actual-spirit") {
            path += `/actual-spirits/${actualSpirit}`;
        } else if(searchType === "spirit" || searchType === "spirits") {
            path += `/spirits/${spirit}`;
        }

        /*
        console.log({
            "type" : "order search",
            "orderSearchText" : orderSearchText,
            "page" : page,
            "sort" : sort,
            "searchType" : searchType,
            "currentPath" : calculateRoute(),
            "apiPath" : path
        });
        */

        ws.call({
            "method" : method,
            "path" : path,
            "params" : {
                "q" : orderSearchText,
                "size" : 10,
                "from" : ((page - 1) * resultsPerPage),
                "sort" : sortBy,
                "direction" : sortDir
            }
        }).then(output => {
            setError(null);

            setOrderList(output.response);
            setTotalResults(output.hits);
        }).catch(e => {
            setError("Order search is not available");
        });
    };

    const SearchResults = () => {
        if(error) {
            return <div className="search-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        return <OrderList orders={orders} setOrder={setOrder} deleteOrdersItem={executeSearch} refreshSearch={executeSearch} originUrl={currentUrl} />
    }


    return (
        <ErrorBoundry fallback={<p>Something went wrong</p>}>
            {
                (() => {
                    if(searchType === "patron") {
                        return (
                            <div className="search-type">Patron: {patron}<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    } else if(searchType === "location") {
                        return (
                            <div className="search-type">Location: {location}<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    } else if(searchType === "new") {
                        return (
                            <div className="search-type">Showing: New Orders<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    } else if(searchType === "recipe") {
                        return (
                            <div className="search-type">Showing: Orders Per Recipe<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    } else if(searchType === "spirit" || searchType === "spirits" || searchType === "orders-spirit") {
                        return (
                            <div className="search-type">Drinks Made With: {spirit}<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    } else if(searchType === "actual-spirit") {
                        return (
                            <div className="search-type">Drinks Made With: {actualSpirit}<div onClick={() => {
                                navigate("/orders");
                            }} className="close" /></div>
                        )
                    }
                })()
            }

            <SearchBox placeholderText="Search Orders" searchType="orders" searchText={orderSearchText} setSearchText={setSearchText} filterText={filterText} />

            <div className="order-filter-container">
                <OrderSortMenu sortList={sortList} sort={sort} handleSort={setSortText} />
            </div>

            <SearchResults />

            <Pagination listType="order" currentPage={page} totalPages={Math.ceil(totalResults / resultsPerPage)} totalResults={totalResults} resultsPerPage={resultsPerPage} goToPage={setPageNum} />
        </ErrorBoundry>
    );
}

export default OrderSearch;