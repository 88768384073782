import React, {useState, useEffect} from 'react';

import styles from './css/ConfirmModal.css';

const ConfirmModal = ({  }) => {
    const hideModal = () => {
        document.getElementById("confirm-modal").classList.remove("show");
        document.getElementById("confirm-modal").classList.add("hide");

        document.getElementById("confirm-submit").onclick = () => {};
    }

    return (
        <div id="confirm-modal" className="confirm-modal hide">
            <div className="inner">
                <div id="confirm-message" className="confirm-message">lol testing</div>
                <div className="actions button-group">
                    <button id="confirm-submit" className="standard-button" onClick={ () => {} }></button>
                    <button id="confirm-cancel" className="standard-button" onClick={ hideModal }>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmModal;