import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import config from '../helper/config';
import metrics from '../helper/metrics';

const RecipesMinMax = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    useEffect(() => {
        let docMin = 0;

        if(patron || (daysAgo && daysAgo < 90)) {
            docMin = 0;
        } else {
            docMin = config.app.order_count_min;
        }

        metrics.getRecipesHighRating({
            "patron" : patron,
            "days_ago" : daysAgo,
            "order_count_min" : docMin
        }).then(recipesHighRating => {
            setChartData(recipesHighRating);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'columnrange',
                        inverted: true
                    },
                    title: {
                        text: `Top ${chartData.buckets} Drinks by Rating - High/Low`
                    },
                    subtitle: {
                        text: (() => {
                            if((!daysAgo || daysAgo < 90) && !patron) {
                                return `The low/high rating for the top ${chartData.buckets} rated drinks (${config.app.order_count_min} order min)`;
                            } else {
                                return `The lowest & highest rating for the top ${chartData.buckets} rated drinks`;
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `High/low ratings ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        categories: chartData.metrics.map(bucket => {
                            return bucket.recipe.length > 12 ? bucket.recipe.substring(0, 12) + "..." : bucket.recipe;
                        })
                    },
                    yAxis: {
                        title: {
                            text: 'Rating Range'
                        }
                    },
                    tooltip: {
                        valueSuffix: '',
                        formatter: function() {
                            return "<b>" + this.point.recipe + "</b><br />Highest Rating: " + this.point.high + "<br />Lowest Rating: " + this.point.low + "<br />Average Rating: " + this.point.average + "<br />Total Orders: " + this.point.count;
                        }
                    },
                    plotOptions: {
                        columnrange: {
                            dataLabels: {
                                enabled: true,
                                format: '{y}',
                                style: {
                                    textOutline: "0px"
                                }
                            },
                            color: graphColors[5]
                        },
                        series: {
                            borderWidth: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        name: 'Min/Max Ratings',
                        data: chartData.metrics.map(bucket => {
                            return {
                                "low" : bucket.min_rating,
                                "high" : bucket.max_rating,
                                "average" : bucket.avg_rating,
                                "count" : bucket.count,
                                "recipe" : bucket.recipe
                            }
                        }),
                        color: {
                            linearGradient: {
                                x1: 0,
                                x2: 0,
                                y1: 0,
                                y2: 1
                            },
                            stops: [
                                [0, graphColors[9]],
                                [1, graphColors[0]]
                            ]
                        }
                    }],
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default RecipesMinMax;