import React, {useState, useEffect, useReducer} from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';
import idm from './helper/idm';
import app from './helper/app';
import cocktail from './helper/cocktail';

import styles from './css/Login.css';

const Login = ({ visible = false, setVisible = () => {}, loginSuccessful, handleExpire }) => {
    const handleLogin = (event) => {
        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;

        if(!username || !password) {
            return;
        }

        idm.requestAccessToken({
            "username" : username,
            "password" : password,
            "success" : (output) => {
                // set the user's name in localstorage so we are not prompting for
                // the patron's name immediately after login
                cocktail.setPatronName(idm.getTokenAttribute("name"));
                loginSuccessful();
                setVisible(false);
                document.getElementById("username").value = "";
                document.getElementById("password").value = "";
            },
            "fail" : (output) => {
                app.showAlertModal({
                    "type" : "error",
                    "message" : "The username and password could not be validated -- Please correct your credentials and try again"
                });
            },
            "handleExpire" : handleExpire
        })
    }


    return (
        <div className={"login-modal " + (visible ? "show" : "hide")}>
            <div className="body">
                <div>
                    <div className="login-info-box">
                        <span className="hello">Hello, who's bartending?</span>
                    </div>
                    <div className="login-info-box">
                        <input type="text" className="login-input" id="username" placeholder="Email" />
                    </div>
                    <div className="login-info-box">
                        <input type="password" className="login-input" id="password" placeholder="Password" />
                    </div>
                    <div className="login-actions button-group">
                        <button className="primary" onClick={() => {
                            handleLogin();
                        }}>Login</button>
                        <button onClick={() => {
                            document.getElementById("username").value = "";
                            document.getElementById("password").value = "";
                            setVisible(false);
                        }}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;