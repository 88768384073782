'use strict';

import axios from 'axios';
import moment from 'moment';
import config from './config';
import app from './app';

var __EXPORTS = {};
var REFRESH_TIMEOUT;
var SESSION_TIMEOUT;
var SESSION_WARNING_TIMEOUT;
var INITIATED = false;


const initiate = ({ isInitiated, handleExpire }) => {
    const momentNow = moment();

    // check for existing token expiration values
    // call isInitiated when/if access token is granted
    if(getExpires()) {
        // if the expiration time of the access token is still greater
        // than the current time, use the existing token
        if(Number(getExpires()) > momentNow.unix()) {
            INITIATED = true;
            __scheduleRefreshToken({
                "secs" : Number(getExpires()) - momentNow.unix(),
                "handleExpire" : handleExpire
            });

            __scheduleSessionEnd({
                "handleExpire" : handleExpire
            });

            isInitiated();

            //sessionExpiresIn();
        } else if(Number(getRefreshExpires()) > momentNow.unix()) {
            // if the expiration time of the refresh token is still
            // greater than the current time, request a new access token
            refreshAccessToken({
                "success" : () => {
                    __scheduleSessionEnd({
                        "handleExpire" : handleExpire
                    });
        
                    isInitiated();
                },
                "fail" : () => handleExpire,
                "handleExpire" : handleExpire
            });
        } else {
            clearAllTokens();
        }
    }
}
__EXPORTS['initiate'] = initiate;


const isLoggedIn = () => {
    // if the expiration date of the access token in local storage
    // is greater than the current datetime, then treat the current
    // session as logged in
    return Number(getExpires()) > Number(moment().unix());
}
__EXPORTS['isLoggedIn'] = isLoggedIn;


const getIdToken = () => {
    return sessionStorage.getItem("cocktail.id_token");
}
__EXPORTS['getIdToken'] = getIdToken;


const setIdToken = (token) => {
    sessionStorage.setItem("cocktail.id_token", token);
}
__EXPORTS['setIdToken'] = setIdToken;


const getAccessToken = () => {
    return sessionStorage.getItem("cocktail.access_token");
}
__EXPORTS['getAccessToken'] = getAccessToken;


const setAccessToken = (token) => {
    sessionStorage.setItem("cocktail.access_token", token);
}
__EXPORTS['setAccessToken'] = setAccessToken;


const getRefreshToken = () => {
    return sessionStorage.getItem("cocktail.refresh_token");
}
__EXPORTS['getRefreshToken'] = getRefreshToken;


const setRefreshToken = (token) => {
    sessionStorage.setItem("cocktail.refresh_token", token);
}
__EXPORTS['setRefreshToken'] = setRefreshToken;


const getExpires = () => {
    return sessionStorage.getItem("cocktail.token_expires_epoch");
}
__EXPORTS['getExpires'] = getExpires;

 
const setExpires = (expires) => {
    sessionStorage.setItem("cocktail.token_expires_epoch", expires);
}
__EXPORTS['setExpires'] = setExpires;


const getRefreshExpires = () => {
    return sessionStorage.getItem("cocktail.refresh_token_expires_epoch");
}
__EXPORTS['getRefreshExpires'] = getRefreshExpires;


const setRefreshExpires = (expires) => {
    sessionStorage.setItem("cocktail.refresh_token_expires_epoch", expires);
}
__EXPORTS['setRefreshExpires'] = setRefreshExpires;

const getSessionExpires = () => {
    return sessionStorage.getItem("cocktail.session_expires_epoch");
}
__EXPORTS['getSessionExpires'] = getSessionExpires;


const setSessionExpires = expires => {
    sessionStorage.setItem("cocktail.session_expires_epoch", expires);
}
__EXPORTS['setSessionExpires'] = setSessionExpires;


const setTokens = ({ idToken, accessToken, refreshToken }) => {
    sessionStorage.setItem("cocktail.id_token", idToken);
    sessionStorage.setItem("cocktail.access_token", accessToken);
    sessionStorage.setItem("cocktail.refresh_token", refreshToken);
}
__EXPORTS['setTokens'] = setTokens;


const clearAllTokens = () => {
    sessionStorage.removeItem("cocktail.id_token");
    sessionStorage.removeItem("cocktail.access_token");
    sessionStorage.removeItem("cocktail.refresh_token");
    sessionStorage.removeItem("cocktail.token_expires_epoch");
    sessionStorage.removeItem("cocktail.refresh_token_expires_epoch");
    sessionStorage.removeItem("cocktail.session_expires_epoch");

    // clear the scheduled token refresh, if it exists
    if(REFRESH_TIMEOUT) {
        clearTimeout(REFRESH_TIMEOUT);
    }
}
__EXPORTS['clearAllTokens'] = clearAllTokens;


const requestAccessToken = ({ username, password, success, fail, handleExpire }) => {
    if(!username || !password) {
        console.error("Could not request an access token -- Username and/or password not provided");
        return;
    }

    let reqConfig = {
        "method" : "PUT",
        "url" : __getEndpoint("/auth/token/new"),
        "headers" : {
            "Authorization" : "Basic " + btoa(`${username}:${password}`)
        },
        "responseType" : "json"
    }

    axios(reqConfig).then((response) => {
        setSessionExpires(moment().unix() + config.session.expire_time);

        __setupEvents({
            "tokenBody" : response.data.response,
            "handleExpire" : handleExpire
        });

        success(response.data.response);
    }).catch((error) => {
        console.log(error);
        fail(error);
    });
}
__EXPORTS['requestAccessToken'] = requestAccessToken;


const refreshAccessToken = ({ success, fail, handleExpire }) => {
    const token = getRefreshToken();

    if(!token) {
        console.error("Could not request an access token -- A refresh token does not exist");
        return;
    }

    let reqConfig = {
        "method" : "POST",
        "url" : __getEndpoint("/auth/token/refresh"),
        "headers" : {
            "Content-Type" : "application/json"
        },
        "data" : {
            "refresh_token" : token
        },
        "responseType" : "json"
    }

    axios(reqConfig).then((response) => {
        if(!response.data.response.access_token) {
            console.error("No access token found in the refresh token response");
            fail();
            return;
        }

        __setupEvents({
            "tokenBody" : response.data.response,
            "handleExpire" : handleExpire
        });

        success(response.data.response);
    }).catch((error) => {
        console.error(error);
        fail();
    });
}
__EXPORTS['refreshAccessToken'] = refreshAccessToken;


const changePassword = ({ oldPassword, newPassword }) => {
    if(!oldPassword || !newPassword) {
        return;
    }

    return new Promise((resolve, reject) => {
        let reqConfig = {
            "method" : "PUT",
            "url" : __getEndpoint("/auth/account/password"),
            "headers" : {
                "Content-Type" : "application/json",
                "Authorization" : `Bearer ${getAccessToken()}`
            },
            "data" : {
                "old_password" : oldPassword,
                "new_password" : newPassword
            },
            "responseType" : "json"
        }

        axios(reqConfig).then((response) => {
            resolve();
        }).catch(e => {
            reject(e);
        });
    });
}
__EXPORTS['changePassword'] = changePassword;


const tokenHasRole = role => {
    if(!role) {
        return false;
    }

    if(!getAccessToken()) {
        return false;
    }

    const decodedToken = __parseJwt(getAccessToken());

    try {
        return decodedToken.realm_access.roles.includes(role);
    } catch(e) {
        console.error(e);
    }
}
__EXPORTS['tokenHasRole'] = tokenHasRole;


const sessionExpiresIn = () => {
    if(!getAccessToken()) {
        return false;
    }

    const decodedToken = __parseJwt(getAccessToken());

    console.log(decodedToken.exp - moment().unix());
}
__EXPORTS['sessionExpiresIn'] = sessionExpiresIn;


const getTokenAttribute = attr => {
    const decodedToken = __parseJwt(getAccessToken());

    return decodedToken[attr];
}
__EXPORTS['getTokenAttribute'] = getTokenAttribute;


///////////////////////////////////////////////////////////////////////////////
// PRIVATE METHODS
///////////////////////////////////////////////////////////////////////////////
const __getEndpoint = uri => {
    if(config.api.host) {
        return `${config.api.ssl ? "https://" : "http://"}${config.api.host}${config.api.port ? ":" + config.api.port : ""}${config.api.root}${uri}`;
    } else {
        return config.api.root + uri;
    }
}


const __setupEvents = ({ tokenBody, handleExpire }) => {
    __processTokenRequest({
        "tokenBody" : tokenBody,
        "handleExpire" : handleExpire
    });

    __scheduleSessionEnd({
        "handleExpire" : handleExpire
    });
}


const __processTokenRequest = ({ tokenBody = {}, handleExpire }) => {
    const momentNow = moment();

    // set the session as being initiated
    INITIATED = true;

    if(REFRESH_TIMEOUT) {
        clearTimeout(REFRESH_TIMEOUT);
    }

    setTokens({
        "idToken" : tokenBody.id_token,
        "accessToken" : tokenBody.access_token,
        "refreshToken" : tokenBody.refresh_token
    });

    let expiresInSeconds = moment((momentNow.unix() + tokenBody.expires_in) * 1000).unix();
    let refreshExpiresInSeconds = moment((momentNow.unix() + tokenBody.refresh_expires_in) * 1000).unix();

    setExpires(expiresInSeconds);
    setRefreshExpires(refreshExpiresInSeconds);

    __scheduleRefreshToken({
        "secs" : tokenBody.expires_in,
        "handleExpire" : handleExpire
    });
}


const __scheduleRefreshToken = ({ secs, handleExpire }) => {
    clearTimeout(REFRESH_TIMEOUT);

    REFRESH_TIMEOUT = setTimeout(() => {
        refreshAccessToken({
            "success" : () => { },
            "fail" : () => {
                clearTimeout(REFRESH_TIMEOUT);
                handleExpire();
            },
            "handleExpire" : handleExpire
        })
    }, (secs * 1000));
}


const __scheduleSessionEnd = ({ handleExpire }) => {
    const secs = getSessionExpires() - moment().unix();

    if(!SESSION_TIMEOUT) {
        //clearTimeout(SESSION_TIMEOUT);
        //clearTimeout(REFRESH_TIMEOUT);
        //console.log("scheduling session end: " + secs);

        SESSION_TIMEOUT = setTimeout(() => {
            clearAllTokens();
            app.hideAlertModal();
            handleExpire(false);
        }, (secs * 1000));

        SESSION_WARNING_TIMEOUT = setTimeout(() => {
            app.showAlertModal({
                "type" : "warning",
                "message" : "Your login session will end shortly -- Please relogin to continue your session"
            });
        }, (secs - config.session.warn_time) > 0 ? (secs - config.session.warn_time) * 1000 : 1000);
        // set the timeout to remaining time minus the warn time or 1 second if not enough time to warn remains
    }
}


const __parseJwt = token => {
    if(!token) {
        return {};
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export default __EXPORTS;
