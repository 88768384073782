import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import wordCloud from 'highcharts/modules/wordcloud.js';

import metrics from '../helper/metrics';

wordCloud(Highcharts);

const OrderSpiritsActualByRecipe = ({ recipe = {} }) => {
    const [chartData, setChartData] = useState(null);
    const [chart, setChart] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        if(!recipe.id) {
            return;
        }

        metrics.getOrderSpiritsActualByType({
            "recipe" : recipe.id
        }).then(spiritsActual => {
            setChartData(spiritsActual);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [recipe]);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const AllSpiritsWordCloud = () => {
        if(!chartData) {
            return <></>
        }

        return (
            <div className="graph-container">
                <HighchartsReact
                    key={`wc-allspirits-${recipe.id}`}
                    highcharts={Highcharts}
                    options={{
                        "series" : [{
                            "type" : "wordcloud",
                            "data" : chartData.spirits_metrics.filter(spirit => spirit.actual_spirit !== "").map(spirit => {
                                return {
                                    "name" : spirit.actual_spirit,
                                    "weight" : spirit.count
                                }
                            }),
                            "name" : "Spirits",
                            "colors" : graphColors
                        }],
                        "title" : {
                            "text" : `Spirits Used in ${recipe.name}`
                        },
                        "credits" : {
                            "enabled" : false
                        }
                    }}
                />
            </div>
        );
    }

    const SpiritsWordCloud = () => {
        return recipe.spirits.map(spiritType => {
            return wordCloudBySpirit(spiritType);
        })
    }

    const wordCloudBySpirit = (spiritType) => {
        if(!chartData) {
            return <div className="graph-container" key={`wcdiv-${spiritType}-${recipe.id}}`}></div>
        }

        return (
            <div className="graph-container" key={`wcdiv-${spiritType}-${recipe.id}}`}>
                <HighchartsReact
                    key={`wc-${spiritType}-${recipe.id}}`}
                    highcharts={Highcharts}
                    options={{
                        "series" : [{
                            "type" : "wordcloud",
                            "data" : chartData.spirits_metrics.filter(spirit => spirit.spirit_type === spiritType).map(spirit => {
                                return {
                                    "name" : spirit.actual_spirit,
                                    "weight" : spirit.count
                                }
                            }),
                            "name" : spiritType,
                            "colors" : graphColors
                        }],
                        "title" : {
                            "text" : `${spiritType} Used in ${recipe.name}`
                        },
                        "credits" : {
                            "enabled" : false
                        }
                    }}
                />
            </div>
        );
    }


    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        return (
            <>
                <AllSpiritsWordCloud />
                <SpiritsWordCloud />
            </>
        )
    }

    return <Chart />
}

export default OrderSpiritsActualByRecipe;