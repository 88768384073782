import React, {useState, useEffect, useReducer} from 'react';
import Order from './Order';

import styles from './css/OrderList.css';

const OrderList = ({ orders, setOrder, deleteOrdersItem, refreshSearch, originUrl }) => {
    if(!orders) {
        return <></>
    } else if(orders.length > 0) {
        return (
            <>
                <div className="order-list">
                    {
                        orders.map((order) => {
                            return <Order key={order.id} order={order} setOrder={setOrder} deleteOrdersItem={deleteOrdersItem} refreshSearch={refreshSearch} single={false} originUrl={originUrl} />
                        })
                    }
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="order-list-empty">
                    Oh noes... Such empty
                </div>
            </>
        )
    }
}

export default OrderList;