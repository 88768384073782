import React, {useState, useEffect, useReducer} from 'react';
import {BrowserRouter, Routes, Route, Link, useNavigate} from 'react-router-dom';
//import fastyles from './fa/css/all.css';
import togglestyle from './toggle.css';
import moment from 'moment';
import axios from 'axios';
import Toggle from 'react-toggle'
import DatePicker from 'react-datepicker';
import Rating from './Rating';
import TypeaheadField from './TypeaheadField';

import app from './helper/app';
import ws from './helper/ws';
import idm from './helper/idm';

import "react-datepicker/dist/react-datepicker.css";
import localstyles from './css/OrderUpdate.css';


const OrderUpdate = ({ order, setOrder, deleteOrdersItem, orderUpdated, visible }) => {
    const [orderedOn, setOrderedOn] = useState(order.ordered_on);
    const [spiritsCache, setSpiritsCache] = useState(null);
    const [patronsCache, setPatronsCache] = useState([]);
    const [locationsCache, setLocationsCache] = useState([]);
    const navigate = useNavigate();

    // only retrieve typeahead cache if the edit recipe dialog has been flipped to visible
    useEffect(() => {
        if(!visible) {
            return;
        }

        ws.apiRequest({
            "method" : "GET",
            "path" : "/orders/patrons"
        }).then(output => {
            setPatronsCache(output.data.response);
        }).catch(e => {
            console.error(e);
        });

        ws.apiRequest({
            "method" : "GET",
            "path" : "/orders/locations"
        }).then(output => {
            setLocationsCache(output.data.response);
        }).catch(e => {
            console.error(e);
        });

        if(!order.spirits.length) {
            return;
        }

        if(!spiritsCache) {
            let _cache = {};

            order.spirits.forEach(spirit => {
                ws.apiRequest({
                    "method" : "GET",
                    "path" : `/orders/actual-spirits/type/${spirit}`
                }).then(output => {
                    _cache[spirit] = output.data.response.map(actualSpirit => {
                        return actualSpirit.spirit;
                    });

                    setSpiritsCache(_cache);
                }).catch(e => {
                    console.error(e);
                });
            });
        }
    }, [visible]);

    const filterSpirits = (text, spiritType) => {
        const regex1 = new RegExp(text, "gi");
        return spiritsCache[spiritType].filter(spirit => regex1.test(spirit)).sort();
    }

    const filterPatrons = text => {
        const regex1 = new RegExp(text, "gi");
        return patronsCache.filter(patron => regex1.test(patron)).sort();
    }

    const filterLocations = text => {
        const regex1 = new RegExp(text, "gi");
        return locationsCache.filter(location => regex1.test(location)).sort();
    }

    const SpiritsActual = () => {
        let actual_spirits = order.actual_spirits || [];

        return (
            <>
                <b>Spirits Used:</b><br />
                <div className="order-update-text">
                    {
                        order.spirits.map(spiritType => {
                            return (
                                <TypeaheadField
                                    key={`actual_spirit-${spiritType}-${order.id}`}
                                    fieldId={`actual_spirit-${spiritType}-${order.id}`}
                                    containerClass="field-container ta-container"
                                    className="actual-spirit"
                                    defaultValue={
                                        actual_spirits.filter(spirit => spirit.type == spiritType).map(spirit => {
                                            return spirit.used;
                                        })
                                    }
                                    placeholder={spiritType}
                                    filterMatches={text => {
                                        return filterSpirits(text, spiritType);
                                    }}
                                    showAllButton={true}
                                />
                            )
                        })
                    }
                </div>
            </>
        );
    }
    
    const Details = () => {
        return (
            <div>
                <b>Special Instructions:</b><br />
                <div className="order-update-text">
                    <textarea id={"details-" + order.id} defaultValue={order.details}></textarea>
                </div>
            </div>
        )
    }
    
    const Notes = () => {
        return (
            <>
                <b>Bartender Notes:</b><br />
                <div className="order-update-text">
                    <textarea id={"notes-" + order.id} defaultValue={order.notes}></textarea>
                </div>
            </>
        );
    }

    const Comments = () => {
        return (
            <>
                <b>Patron Comments:</b><br />
                <div className="order-update-text">
                    <textarea id={"comments-" + order.id} defaultValue={order.comments}></textarea>
                </div>
            </>
        );
    }

    const OrderedBy = () => {
        return (
            <>
                <b>Ordered By:</b><br />
                <div className="order-update-text">
                    <TypeaheadField
                        key={"ordered-by-" + order.id}
                        fieldId={"ordered-by-" + order.id}
                        defaultValue={order.ordered_by}
                        filterMatches={filterPatrons}
                        showAllButton={true}
                    />
                </div>
            </>
        );
    }

    const OrderedAt = () => {
        return (
            <>
                <b>Establishment:</b><br />
                <div className="order-update-text">
                    <TypeaheadField
                        key={"ordered-at-" + order.id}
                        fieldId={"ordered-at-" + order.id}
                        defaultValue={order.ordered_at}
                        filterMatches={filterLocations}
                    />
                </div>
            </>
        );
    }

    const OrderedOn = () => {
        const handleDateSelect = date => {
            console.log(date);
            console.log(typeof(date));
            setOrderedOn(moment(date).format("YYYY-MM-DD HH:mm"));
        }

        return (
            <>
                <b>Ordered On:</b><br />
                <div className="order-update-text">
                    <DatePicker
                        id={`ordered-on-${order.id}`}
                        selected={new Date(moment(orderedOn).toDate())}
                        onChange={handleDateSelect} 
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm:SS"
                        calendarClassName="calendar-picker"
                    />
                </div>
            </>
        );
    }

    const OrderComplete = () => {
        return (
            <div className="order-complete">
                <label>
                    <div className="label"><b>Order Complete</b> </div>
                    &nbsp;&nbsp;
                    <div className="label"><Toggle id={"complete-" + order.id} defaultChecked={order.complete} /></div>
                </label>
            </div>
        );
    }
    
    const RatingView = () => {
        if(!order.rating && !order.complete) {
            return "";
        }

        let iteration = 0;

        return (
            <div className="order-rating-elements">
                <Rating Id={`${order.id}-update`} ratingStart={order.rating} />
            </div>
        );
    }
    
    const getRating = () => {
        let rating = 0;
    
        [...Array(10).keys()].forEach(ratingNum => {
            const ratingEl = document.getElementById("rating-" + order.id + "-update-" + (ratingNum + 1)); 
            
            if(!ratingEl) {
                //console.log("No rating element" + ratingNum);
                return;
            }

            if(ratingEl.classList.contains("on")) {
                rating++;
            }
        });
    
        return rating || undefined;
    }

    const orderedOnDisplay = () => {
        if(moment().diff(moment(order.ordered_on), 'days') > 15) {
            return ("on " + moment(order.ordered_on).format("dddd, MMMM Do, YYYY"))
        } else {
            return moment(order.ordered_on).fromNow()
        }
    }

    const updateOrder = () => {
        let actual_spirits = order.spirits.map(spirit_type => {
            return {
                "type" : spirit_type,
                "used" : document.getElementById(`actual_spirit-${spirit_type}-${order.id}`).value
            }
        });
        let details = document.getElementById("details-" + order.id).value;
        let notes = document.getElementById("notes-" + order.id).value;
        let comments = document.getElementById("comments-" + order.id).value;
        let ordered_by = document.getElementById("ordered-by-" + order.id).value;
        let ordered_at = document.getElementById("ordered-at-" + order.id).value;
        let ordered_on = document.getElementById("ordered-on-" + order.id).value;
        let rating = getRating();
        let complete = document.getElementById("complete-" + order.id).checked;

        let orderDoc = {
            "order" : {
                "recipe_id" : order.recipe_id,
                "complete" : complete
            }
        }

        orderDoc.order['actual_spirits'] = actual_spirits;
        orderDoc.order['details'] = details ? details : null;
        orderDoc.order['notes'] = notes ? notes : null;
        orderDoc.order['comments'] = comments ? comments : null;

        if(ordered_by) {
            orderDoc.order['ordered_by'] = ordered_by;
        } else {
            app.showAlertModal({
                "type" : "error",
                "message" : "The name of who ordered the drink is required"
            });
            return;
        }

        if(ordered_at) {
            orderDoc.order['ordered_at'] = ordered_at;
        } else {
            app.showAlertModal({
                "type" : "error",
                "message" : "The name of the establishment where the drink was ordered is required"
            });
            return;
        }

        if(ordered_on) {
            orderDoc.order['ordered_on'] = moment(ordered_on).utc().format("YYYY-MM-DDTHH:mm:ssZZ");
        }

        if(rating) {
            orderDoc.order['rating'] = rating;
        }

        ws.apiRequest({
            "method" : "POST",
            "path" : `/orders/${order.id}`,
            "data" : orderDoc,
            "callback" : (output) => {
                ws.apiRequest({
                    "method" : "GET",
                    "path" : `/orders/${order.id}`,
                    "callback" : (orderOutput) => {
                        setOrder(orderOutput);
                        orderUpdated();
                    }
                });
            }
        });
    }

    const deleteOrder = () => {
        app.showConfirmModal({
            "type" : "error",
            "message" : "You are about to delete this order, an operation that cannot be undone.  Please confirm this action.",
            "buttonText" : "Confirm",
            "handleConfirm" : () => {
                app.hideConfirmModal();
                ws.apiRequest({
                    "method" : "DELETE",
                    "path" : `/orders/${order.id}`,
                    "callback" : (orderOutput) => {
                        if(deleteOrdersItem) {
                            deleteOrdersItem(order.id);
                        } else {
                            setOrder({});
                        }
                        closeOrder();
                    }
                });
            }
        });
    }


    const closeOrder = () => {
        navigate(-1);
    }

    return (
        <div className="order order-update">
            <div className="inner">
                <div className="title">{order['recipe_name']}</div>
                <div className="details">
                    <div className="spirits">
                        {
                            order.spirits.map(spirit => {
                                return <div key={spirit + "-" + order.id} className={spirit == order.primary_spirit ? 'primary-spirit' : 'spirit'}>{spirit}</div>
                            })
                        }
                        <div className="spirit">&nbsp;</div>
                    </div>
                    <hr />
                    <SpiritsActual />
                    <hr />
                    <Details />
                    <hr />
                    <Notes />
                    <hr />
                    <Comments />
                    <hr />
                    <OrderedBy />
                    <hr />
                    <OrderedAt />
                    <hr />
                    <OrderedOn />
                    <hr />
                    <OrderComplete />
                    <hr />
                    <RatingView />
                </div>
                <div className="order-update-actions-delete button-group">
                    <button onClick={() => {deleteOrder()}}><i className="fa fa-trash" /></button>
                </div>
                <div className="order-update-actions button-group">
                    <button className="primary" onClick={() => {updateOrder()}}><i className="fa-solid fa-floppy-disk" /> Save</button>
                    <button onClick={closeOrder}><i className="fa-solid fa-xmark" /></button>
                </div>
            </div>
        </div>
    );
}

export default OrderUpdate;
