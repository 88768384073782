import React, {useState, useEffect, useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const useOutsideBlur = (ref, hideFilterMenu) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                hideFilterMenu();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const MetricsFilterMenu = ({ handleDateSelect, daysAgo, partial = false }) => {
    const [filterMenuVisible, setFilterMenuVisible] = useState(false);
    const filterRef = useRef(null);

    const hideFilterMenu = () => {
        setFilterMenuVisible(false);
    }

    const DateSelect = () => {
        return (
            <div className="days-select-container">
                <span className={`${daysAgo === 7 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(7);
                }}>Show Last 7 Days</span>
                <span className={`${daysAgo === 14 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(14);
                }}>Show Last 14 Days</span>
                <span className={`${daysAgo === 30 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(30);
                }}>Show Last 30 Days</span>
                <span className={`${daysAgo === 90 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(90);
                }}>Show Last 3 Months</span>
                <span className={`${daysAgo === 182 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(182);
                }}>Show Last 6 Months</span>
                <span className={`${daysAgo === 365 ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(365);
                }}>Show Last Year</span>
                <span className={`${!daysAgo ? "selected" : ""}`} onClick={() => {
                    handleDateSelect(null);
                }}>Show All Time</span>
                {
                    (() => {
                        if(partial) {
                            return <i className="filter-note">Filters may not affect some graphs</i>
                        }
                    })()
                }
            </div>
        )
    }

    // setup the off click typeahead hider
    useOutsideBlur(filterRef, hideFilterMenu);

    return (
        <div className="filter-container" ref={filterRef}>
            <button id="filter-select" className={`filter-select ${filterMenuVisible ? "menu-open" : "menu-closed"}`} onClick={event => {
                setFilterMenuVisible(filterMenuVisible ? false : true);
            }}><i className="fa-solid fa-filter" /></button>
            <div id="filter-menu" className={`filter-menu ${filterMenuVisible ? "show" : "hide"}`}>
                <div className="border-hider"></div>
                <DateSelect />
            </div>
        </div>
    );
}

export default MetricsFilterMenu;