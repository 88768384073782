import React, {useState, useEffect, useReducer} from 'react';
import ReactDOM from 'react-dom';

import idm from './helper/idm';
import cocktail from './helper/cocktail';

import styles from './css/ImageView.css';
import fastyles from './fa/css/all.css';

const ImageView = ({ recipe = {}, order = {}, hideModal, editImage, visible = false }) => {
    const [imageData, setImageData] = useState(null);
    const [isVisible, setIsVisible] = useState(visible);

    // only load the image if the modal has been opened by the user
    useEffect(() => {
        if(visible && !imageData) {
            setIsVisible(true);
            getSrc();
        }
    }, [visible]);

    const getSrc = async () => {
        if(imageData) {
            return;
        }

        if(recipe.image) {
            setImageData(await cocktail.getImage(recipe.id, "image", "recipe"));
        } else if(order.image) {
            setImageData(await cocktail.getImage(order.id, "image", "order"));
        } else {
            return "";
        }
    }

    const EditButton = () => {
        if(!idm.tokenHasRole("recipe_admin") && recipe.id) {
            return "";
        } else if(!idm.tokenHasRole("order_admin") && order.id) {
            return "";
        }

        return <button className="standard-button" onClick={ editImage }><i className="fa-solid fa-pen-to-square" /> Edit</button>
    }

    const Actions = () => {
        return (
            <div className="actions button-group">
                <EditButton />
                <button className="standard-button" onClick={() => {
                    setIsVisible(false);
                    hideModal();
                }}><a className="fa-solid fa-xmark" /></button>
            </div>
        );
    }

    const Image = () => {
        if(imageData) {
            return <img src={`data:image/jpg;base64,${imageData}`} />
        } else {
            return <></>
        }
    }
  
    return (
        <div className="image-container">
            <Image />
            <Actions />
        </div>
    )
}

export default ImageView;