import React, {useState, useEffect, useReducer, useRef} from 'react';
import {Link, useParams, useNavigate, useSearchParams} from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import domtoimage from 'dom-to-image';

import MetricsFilterMenu from './MetricsFilterMenu';
import ShareMetricModal from './ShareMetricModal';

import RecipesPopular from './metrics/RecipesPopular';
import RecipesHighRating from './metrics/RecipesHighRating';
import RecipePrimarySpirits from './metrics/RecipePrimarySpirits';
import OrderPrimarySpirits from './metrics/OrderPrimarySpirits';
import OrderSpiritsActual from './metrics/OrderSpiritsActual';
import OrderSpiritsActualByType from './metrics/OrderSpiritsActualByType';
import OrderSpiritsActualByTypeBar from './metrics/OrderSpiritsActualByTypeBar';
import RecipeTypes from './metrics/RecipeTypes';
import OrderTypes from './metrics/OrderTypes';
import RecipeGlassTypes from './metrics/RecipeGlassTypes';
import OrderGlassTypes from './metrics/OrderGlassTypes';
import RecipeGarnishes from './metrics/RecipeGarnishes';
import RecipesMinMax from './metrics/RecipesMinMax';
import OrdersMinMax from './metrics/OrdersMinMax';
import OrdersByDateType from './metrics/OrdersByDateType';
import OrdersHistogram from './metrics/OrdersHistogram';
import OrderPrimarySpiritsHistogram from './metrics/OrderPrimarySpiritsHistogram';
import OrderTypesHistogram from './metrics/OrderTypesHistogram';
import OrderSpiritsActualByRecipe from './metrics/OrderSpiritsActualByRecipe';
import RecipeQuickStats from './metrics/RecipeQuickStats';
import OrderSpiritsActualWordCloud from './metrics/OrderSpiritsActualWordCloud';
import OrderSpiritTypesActualWordCloud from './metrics/OrderSpiritTypesActualWordCloud';
import RecipeTopPatrons from './metrics/RecipeTopPatrons';
import RecipeTypesPrimarySpiritsSankey from './metrics/RecipeTypesPrimarySpiritsSankey';
import RecipeTypesGlassTypesSankey from './metrics/RecipeTypesGlassTypesSankey';
import RecipePrimarySpiritsGarnishesSankey from './metrics/RecipePrimarySpiritsGarnishesSankey';
import RecipeSpiritsCommonCombination from './metrics/RecipeSpiritsCommonCombination';

import idm from './helper/idm';
import metrics from './helper/metrics';
import cocktail from './helper/cocktail';

import styles from './css/MetricsHome.css';
import { faCocktail } from '@fortawesome/free-solid-svg-icons';

const reducer = (state, action) => {
    switch(action.type) {
        case 'set':
            return {...state, ...action.setTo};
        default:
            throw new Error("action.type is not valid");
    }
}

const MetricsHome = ({ searchType, themeColor }) => {
    const [daysAgo, setDaysAgo] = useState(null);
    //const [themeColor, setThemeColor] = useState(defaultColor);
    const [recipe, setRecipe] = useState({});
    const params = useParams();
    const navigate = useNavigate();
    const initialState = {
        "shareVisible" : false,
        "imageData" : null,
        "graphName" : null
    }
    const [state, setState] = useReducer(reducer, initialState);

    useEffect(() => {

    }, [searchType]);


    useEffect(() => {
        if(!params.recipeId) {
            return;
        }

        cocktail.getRecipe(params.recipeId).then(recipe => {
            setRecipe(recipe);
        }).catch(e => {
            console.error(e);
        });
    }, [params.recipeId]);


    const SearchType = () => {
        if(searchType === "patron") {
            return (
                <div className="search-type">Metrics for {params.patron}<div onClick={() => {
                    navigate("/metrics");
                }} className="close" /></div>
            );
        }

        return "";
    }


    const genShareImage = async (graphRef, name) => {
        console.log(graphRef);
        domtoimage.toPng(graphRef.current).then(dataUrl => {
            setState({
                type: "set",
                setTo: {
                    "shareVisible" : true,
                    "imageData" : dataUrl,
                    "graphName" : name
                }
            });
        }).catch(e => {
            console.error(e);
        });
    }


    const closeShareModal = () => {
        setState({
            type: "set",
            setTo: {
                "shareVisible" : false,
                "imageData" : null,
                "graphName" : null
            }
        });

    }

    const ShareGraph = ({ graphRef, name }) => {
        return (
            <div className="share-metric">
                <button onClick={() => {
                    genShareImage(graphRef, name);
                }}><i className="fa-solid fa-arrow-up-from-bracket" /></button>
            </div>
        );
    }

    const PopularityMetrics = () => {
        const popDrinksRef = useRef();
        const ratedDrinks = useRef();
        const minMaxDrinks = useRef();
        const spiritsActual = useRef();
        const orderTypes = useRef();
        const typesWordCloud = useRef();
        const spiritsWordCloud = useRef();

        return (
            <>
                <div className="title">
                    Popularity Metrics
                    <FilterMenu />
                </div>
                <div className="graph-container" ref={popDrinksRef}>
                    <ShareGraph graphRef={popDrinksRef} name="Most Popular Drinks" />
                    <RecipesPopular patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ratedDrinks}>
                    <ShareGraph graphRef={ratedDrinks} name="Highest Rated Drinks" />
                    <RecipesHighRating patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={minMaxDrinks}>
                    <ShareGraph graphRef={minMaxDrinks} name="Top Drinks - High/Low Rating" />
                    <RecipesMinMax patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsActual}>
                    <ShareGraph graphRef={spiritsActual} name="Types of Spirits Ordered" />
                    <OrderSpiritsActualByTypeBar patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={orderTypes}>
                    <ShareGraph graphRef={orderTypes} name="Most Popular Drink Types" />
                    <OrderTypes patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={typesWordCloud}>
                    <ShareGraph graphRef={typesWordCloud} name="Primary Spirits Used" />
                    <OrderSpiritTypesActualWordCloud patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsWordCloud}>
                    <ShareGraph graphRef={spiritsWordCloud} name="All Brands Used" />
                    <OrderSpiritsActualWordCloud patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
            </>
        );
    }

    const SpiritUse = () => {
        const typesSpirts = useRef();
        const topSpirits = useRef();
        const typesSpiritsOrdered = useRef();
        const mostOrderedSpirits = useRef();
        const brandsOrdered = useRef();
        const brandsByType = useRef();
        const primarySpiritsUsed = useRef();
        const allSpiritsUsed = useRef();

        return (
            <>
                <div className="title">
                    Spirit Use Metrics
                    <FilterMenu partial={true} />
                </div>
                <div className="graph-container" ref={typesSpirts}>
                    <ShareGraph graphRef={typesSpirts} name="Drink Type and Spirit Pairing" />
                    <RecipeTypesPrimarySpiritsSankey />
                </div>
                <div className="graph-container" ref={topSpirits}>
                    <ShareGraph graphRef={topSpirits} name="Top 10 Spirits in the Menu" />
                    <RecipePrimarySpirits themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={typesSpiritsOrdered}>
                    <ShareGraph graphRef={typesSpiritsOrdered} name="Types of Spirits Ordered" />
                    <OrderSpiritsActualByTypeBar daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={mostOrderedSpirits}>
                    <ShareGraph graphRef={mostOrderedSpirits} name="Most Ordered Spirits" />
                    <OrderPrimarySpirits daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={brandsOrdered}>
                    <ShareGraph graphRef={brandsOrdered} name="Top 10 Brands Ordered" />
                    <OrderSpiritsActual daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={brandsByType}>
                    <ShareGraph graphRef={brandsByType} name="Brands Used by Spirit Type" />
                    <OrderSpiritsActualByType daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={primarySpiritsUsed}>
                    <ShareGraph graphRef={primarySpiritsUsed} name="Primary Spirits Used" />
                    <OrderSpiritTypesActualWordCloud daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={allSpiritsUsed}>
                    <ShareGraph graphRef={allSpiritsUsed} name="All Brands Used" />
                    <OrderSpiritsActualWordCloud daysAgo={daysAgo} />
                </div>
            </>
        );
    }

    const DrinkTrends = () => {
        const orderByDay = useRef();
        const orderByMonth = useRef();
        const orderByYear = useRef();
        const orderByDate = useRef();
        const spiritsByDate = useRef();
        const typesByMonth = useRef();

        return (
            <>
                <div className="title">
                    Drink Trend Metrics
                    <FilterMenu />
                </div>
                <div className="graph-container" ref={orderByDay}>
                    <ShareGraph graphRef={orderByDay} name="Number of Order by Day" />
                    <OrdersByDateType daysAgo={daysAgo} byWeekday={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={orderByMonth}>
                    <ShareGraph graphRef={orderByMonth} name="Most Popular Drinks" />
                    <OrdersByDateType daysAgo={daysAgo} byMonth={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={orderByYear}>
                    <ShareGraph graphRef={orderByYear} name="Most Popular Drinks" />
                    <OrdersByDateType daysAgo={daysAgo} byYear={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={orderByDate}>
                    <ShareGraph graphRef={orderByDate} name="Most Popular Drinks" />
                    <OrdersHistogram daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsByDate}>
                    <ShareGraph graphRef={spiritsByDate} name="Most Popular Drinks" />
                    <OrderPrimarySpiritsHistogram daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={typesByMonth}>
                    <ShareGraph graphRef={typesByMonth} name="Most Popular Drinks" />
                    <OrderTypesHistogram daysAgo={daysAgo} />
                </div>
            </>
        );
    }

    const PatronMetrics = () => {
        const popDrinksRef = useRef();
        const ratedDrinks = useRef();
        const minMaxDrinks = useRef();
        const minMaxOrders = useRef();
        const orderedSpirits = useRef();
        const brandsOrdered = useRef();
        const popTypes = useRef();
        const glassTypes = useRef();
        const ordersByDay = useRef();
        const ordersByMonth = useRef();
        const ordersByYear = useRef();
        const ordersByDate = useRef();
        const spiritsByDate = useRef();
        const typesOrdered = useRef();
        const typesWordCloud = useRef();
        const spiritsWordCloud = useRef();

        return (
            <>
                <div className="title">
                    {`${params.patron === localStorage.getItem("patronName") ? "My Order" : "Leaderboard"} Metrics`}
                    <FilterMenu />
                </div>
                <div className="graph-container" ref={popDrinksRef}>
                    <ShareGraph graphRef={popDrinksRef} name="Top 10 Most Popular Drinks" />
                    <RecipesPopular patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ratedDrinks}>
                    <ShareGraph graphRef={ratedDrinks} name="Highest Rated Drinks" />
                    <RecipesHighRating patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={minMaxDrinks}>
                    <ShareGraph graphRef={minMaxDrinks} name="Top Drinks by Rating - High/Low" />
                    <RecipesMinMax patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={minMaxOrders}>
                    <ShareGraph graphRef={minMaxOrders} name="Most Ordered Drinks - High/Low" />
                    <OrdersMinMax patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={orderedSpirits}>
                    <ShareGraph graphRef={orderedSpirits} name="Most Ordered Spirits" />
                    <OrderPrimarySpirits patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={brandsOrdered}>
                    <ShareGraph graphRef={brandsOrdered} name="Top Brands Ordered" />
                    <OrderSpiritsActual patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={popTypes}>
                    <ShareGraph graphRef={popTypes} name="Most Popular Drink Types" />
                    <OrderTypes patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={glassTypes}>
                    <ShareGraph graphRef={glassTypes} name="Glasses by Order" />
                    <OrderGlassTypes patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByDay}>
                    <ShareGraph graphRef={ordersByDay} name="Number of Orders by Day" />
                    <OrdersByDateType patron={params.patron} daysAgo={daysAgo} byWeekday={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByMonth}>
                    <ShareGraph graphRef={ordersByMonth} name="Number of Orders by Month" />
                    <OrdersByDateType patron={params.patron} daysAgo={daysAgo} byMonth={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByYear}>
                    <ShareGraph graphRef={ordersByYear} name="Number of Orders by Year" />
                    <OrdersByDateType patron={params.patron} daysAgo={daysAgo} byYear={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByDate}>
                    <ShareGraph graphRef={ordersByDate} name="Number of Orders by Date" />
                    <OrdersHistogram patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsByDate}>
                    <ShareGraph graphRef={spiritsByDate} name="Primary Spirit Use by Date" />
                    <OrderPrimarySpiritsHistogram patron={params.patron} daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={typesOrdered}>
                    <ShareGraph graphRef={typesOrdered} name="Types of Spirits Ordered" />
                    <OrderSpiritsActualByTypeBar patron={params.patron} daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={typesWordCloud}>
                    <ShareGraph graphRef={typesWordCloud} name="Primary Spirits Used" />
                    <OrderSpiritTypesActualWordCloud patron={params.patron} daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={spiritsWordCloud}>
                    <ShareGraph graphRef={spiritsWordCloud} name="All Brands Used" />
                    <OrderSpiritsActualWordCloud patron={params.patron} daysAgo={daysAgo} />
                </div>
            </>
        );
    }

    const RecipeIdMetrics = () => {
        if(!recipe.id) {
            return <></>
        }

        return (
            <>
                <div className="metrics-title">
                    <div className="title">
                        {recipe.name} Metrics
                    </div>
                    <div className="metrics-actions">
                        <button onClick={() => {
                            navigate(-1);
                        }}><i className="fa-solid fa-xmark" /></button>
                    </div>
                </div>
                <div className="stats-container">
                    <RecipeQuickStats recipe={recipe} themeColor={themeColor} />
                </div>
                <OrderSpiritsActualByRecipe recipe={recipe} />
                {
                    (() => {
                        if(!idm.tokenHasRole("order_viewer")) {
                            return "";
                        }

                        return (
                            <div className="graph-container">
                                <RecipeTopPatrons recipe={recipe} themeColor={themeColor} />
                            </div>
                        );
                    })()
                }
                <div className="graph-container">
                    <OrdersHistogram recipeId={recipe.id} themeColor={themeColor} />
                </div>
                
            </>
        )
    }

    const RecipesMetrics = () => {
        const topSpirits = useRef();
        const typestoSpirits = useRef();
        const combSpirits = useRef();
        const drinksByType = useRef();
        const commonGarnishes = useRef();
        const spiritsToGarnishes = useRef();
        const ratingsHighLow = useRef();
        const glassByDrink = useRef();
        const drinksByGlass = useRef();

        return (
            <>
                <div className="title">
                    Menu Insights
                </div>
                <div className="graph-container" ref={topSpirits}>
                    <ShareGraph graphRef={topSpirits} name="Top 10 Spirits in the Menu" />
                    <RecipePrimarySpirits themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={typestoSpirits}>
                    <ShareGraph graphRef={typestoSpirits} name="Drink Types with Top Primary Spirits" />
                    <RecipeTypesPrimarySpiritsSankey />
                </div>
                <div className="graph-container" ref={combSpirits}>
                    <ShareGraph graphRef={combSpirits} name="Commonly Combined Spirits" />
                    <RecipeSpiritsCommonCombination />
                </div>
                <div className="graph-container" ref={drinksByType}>
                    <ShareGraph graphRef={drinksByType} name="Percentage of Drinks by Type" />
                    <RecipeTypes themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={commonGarnishes}>
                    <ShareGraph graphRef={commonGarnishes} name="Most Common Garnishes" />
                    <RecipeGarnishes themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsToGarnishes}>
                    <ShareGraph graphRef={spiritsToGarnishes} name="Spirits with Top Garnishes" />
                    <RecipePrimarySpiritsGarnishesSankey />
                </div>
                <div className="graph-container" ref={ratingsHighLow}>
                    <ShareGraph graphRef={ratingsHighLow} name="Top 5 Drinks by Rating - High/Low" />
                    <RecipesMinMax themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={glassByDrink}>
                    <ShareGraph graphRef={glassByDrink} name="Glass Types by Drink" />
                    <RecipeGlassTypes themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={drinksByGlass}>
                    <ShareGraph graphRef={drinksByGlass} name="Drink Types with Top Glass Types" />
                    <RecipeTypesGlassTypesSankey />
                </div>
            </>
        );
    }

    const AllMetrics = () => {
        const popDrinksRef = useRef();
        const ratedDrinks = useRef();
        const topSpirits = useRef();
        const mostOrdered = useRef();
        const topBrands = useRef();
        const brandsByType = useRef();
        const popularTypes = useRef();
        const drinksByType = useRef();
        const ordersByDay = useRef();
        const ordersByMonth = useRef();
        const ordersByYear = useRef();
        const ordersByDate = useRef();
        const spiritsUsed = useRef();
        const brandsUsed = useRef();

        return (
            <>
                <div className="title">
                    Menu &amp; Order Metrics
                    <FilterMenu partial={true} />
                </div>
                <div className="graph-container" ref={popDrinksRef}>
                    <ShareGraph graphRef={popDrinksRef} name="Most Popular Drinks" />
                    <RecipesPopular daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ratedDrinks}>
                    <ShareGraph graphRef={ratedDrinks} name="Highest Rated Drinks" />
                    <RecipesHighRating daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={topSpirits}>
                    <ShareGraph graphRef={topSpirits} name="Top Spirits in the Menu" />
                    <RecipePrimarySpirits themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={mostOrdered}>
                    <ShareGraph graphRef={mostOrdered} name="Most Ordered Spirits" />
                    <OrderPrimarySpirits daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={topBrands}>
                    <ShareGraph graphRef={topBrands} name="Top Brands Ordered" />
                    <OrderSpiritsActual daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={brandsByType}>
                    <ShareGraph graphRef={brandsByType} name="Brands Used by Spirit Type" />
                    <OrderSpiritsActualByType daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={popularTypes}>
                    <ShareGraph graphRef={popularTypes} name="Most Popular Drink Types" />
                    <OrderTypes daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={drinksByType}>
                    <ShareGraph graphRef={drinksByType} name="Percentage of Drinks by Type" />
                    <RecipeTypes themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByDay}>
                    <ShareGraph graphRef={ordersByDay} name="Number of Orders by Day" />
                    <OrdersByDateType daysAgo={daysAgo} byWeekday={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByMonth}>
                    <ShareGraph graphRef={ordersByMonth} name="Number of Orders by Month" />
                    <OrdersByDateType daysAgo={daysAgo} byMonth={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByYear}>
                    <ShareGraph graphRef={ordersByYear} name="Number of Orders by Year" />
                    <OrdersByDateType daysAgo={daysAgo} byYear={true} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={ordersByDate}>
                    <ShareGraph graphRef={ordersByDate} name="Number of Orders by Date" />
                    <OrdersHistogram daysAgo={daysAgo} themeColor={themeColor} />
                </div>
                <div className="graph-container" ref={spiritsUsed}>
                    <ShareGraph graphRef={spiritsUsed} name="Primary Spirits Used" />
                    <OrderSpiritTypesActualWordCloud daysAgo={daysAgo} />
                </div>
                <div className="graph-container" ref={brandsUsed}>
                    <ShareGraph graphRef={brandsUsed} name="All Brands Used" />
                    <OrderSpiritsActualWordCloud daysAgo={daysAgo} />
                </div>
            </>
        );
    }

    const Metrics = () => {
        //console.log(window.getComputedStyle(document.getElementById("app-header"), null).getPropertyValue("background-color"));

        if(searchType === "popularity") {
            return <PopularityMetrics />
        } else if(searchType === "spirits") {
            return <SpiritUse />
        } else if(searchType === "trends") {
            return <DrinkTrends />
        } else if(searchType == "patron") {
            return <PatronMetrics />
        } else if(searchType === "recipes") {
            return <RecipesMetrics />
        } else if(searchType === "recipe-id") {
            return <RecipeIdMetrics />
        } else {
            return <AllMetrics />
        }
    }

    const FilterMenu = ({ partial = false }) => {
        return <MetricsFilterMenu handleDateSelect={handleDateSelect} daysAgo={daysAgo} partial={partial} />
    }

    const handleDateSelect = days => {
        setDaysAgo(days || null);
    }

    if(!themeColor) {
        // the theme color needs to be set via the header class
        return <></>
    } else {
        return (
            <>
                <SearchType />
                <div className="metrics">
                    <Metrics />
                    <ShareMetricModal isVisible={state.shareVisible} setIsVisible={closeShareModal} imageShareData={state.imageData} closeShareModal={closeShareModal} name={state.graphName} />
                </div>
            </>
        );
    }
}

export default MetricsHome;