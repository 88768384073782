import React, {useState, useEffect} from 'react';
import {Routes, Route, Outlet, Link, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faFingerprint, faAngleDoubleLeft, faIdBadge, faChartPie, faCodeBranch } from '@fortawesome/free-solid-svg-icons';

import idm from './helper/idm';
import app from './helper/app';
import config from './helper/config';
import Login from './Login';
import NavModal from './NavModal';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';

import styles from './css/Header.css';
import { theme } from 'highcharts';

const Header = ({ setTokenIsValid, sessionChanged, themeChanged=()=>{} }) => {
    const [loginVisible, setLoginVisible] = useState(false);
    const [themeNumber, setThemeNumber] = useState(localStorage.getItem("themeNumber") ?? config.app.default_theme);
    const [isLoggedIn, setIsLoggedIn] = useState(idm.isLoggedIn());
    const [recipeNavVisible, setRecipeNavVisible] = useState(false);
    const [spiritsNavVisible, setSpiritsNavVisible] = useState(false);
    const [typesNavVisible, setTypesNavVisible] = useState(false);
    const [ingredientsNavVisible, setIngredientsNavVisible] = useState(false);
    const [tagsNavVisible, setTagsNavVisible] = useState(false);
    const [orderNavVisible, setOrderNavVisible] = useState(false);
    const [patronNavVisible, setPatronNavVisible] = useState(false);
    const [locationNavVisible, setLocationNavVisible] = useState(false);
    const [orderSpiritsNavVisible, setOrderSpiritsNavVisible] = useState(false);
    const [userMenuNavVisible, setUserMenuNavVisible] = useState(false);
    const [shareNavVisible, setShareNavVisible] = useState(false);
    const [passwordNavVisible, setPasswordNavVisible] = useState(false);
    const [metricsNavVisible, setMetricsNavVisible] = useState(false);
    const [metricsPatronsNavVisible, setMetricsPatronsNavVisible] = useState(false);
    const [aboutNavVisible, setAboutNavVisible] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        const themeRegex = /^theme\-\d+/g;
        let currentThemeNumber = null;

        [...body.classList].forEach(className => {
            let classMatch = className.match(themeRegex);

            if(classMatch) {
                currentThemeNumber = classMatch[0].split("-")[1];
            }
        });

        [...body.classList].forEach(className => {
            if(className.match(themeRegex)) {
                body.classList.remove(className);
            }
        });

        if(themeNumber != currentThemeNumber) {
            localStorage.setItem("themeNumber", themeNumber);
            body.classList.add(`theme-${themeNumber}`);
            themeChanged(themeNumber);
        } else if(localStorage.getItem("themeNumber")) {
            body.classList.add(`theme-${localStorage.getItem("themeNumber")}`);
            themeChanged(localStorage.getItem("themeNumber"));
        }
    }, [themeNumber]);

    const showLogin = () => {
        setLoginVisible(true);
    }

    const loginSuccessful = () => {
        setIsLoggedIn(true);
        setTokenIsValid(true);
    }

    const clearTokens = () => {
        idm.clearAllTokens();
        setIsLoggedIn(false);
        setTokenIsValid(false);
    }

    const handleExpire = () => {
        clearTokens();
        sessionChanged(false);
    }

    const displayLoginHeader = () => {
        if(idm.isLoggedIn()) {
            return (
                <span onClick={() => {
                    //clearTokens();
                    showMenu("usermenu");
                }}><i className="fa-regular fa-id-badge" /></span>
            );
        } else {
            return (
                <span onClick={() => {
                    showLogin();
                }}><FontAwesomeIcon icon={faFingerprint} /></span>
            );
        }
    }

    const switchTheme = () => {
        const body = document.getElementsByTagName("body")[0];
        const themeRegex = /^theme\-\d+/g;
        let currentThemeNumber = null;
        
        [...body.classList].forEach(className => {
            let classMatch = className.match(themeRegex);

            if(classMatch) {
                currentThemeNumber = classMatch[0].split("-")[1];
            }
        });

        if(currentThemeNumber) {
            if(currentThemeNumber >= config.app.total_themes) {
                setThemeNumber(1);
            } else {
                setThemeNumber(Number(currentThemeNumber) + 1);
            }
        }
    }

    const displayModeHeader = () => {
        return (
            <>
                <span onClick={() => {
                    switchTheme();
                }}>
                    <i className={`fa-solid fa-palette`} />
                </span>
            </>
        );
    }

    const displayMetricsHeader = () => {
        return (
            <>
                <span onClick={() => {
                    //navigate("/metrics");
                    showMenu("metrics");
                }}>
                    <FontAwesomeIcon icon={faChartPie} />
                </span>
            </>
        );
    }

    const displayAboutHeader = () => {
        return (
            <>
                <span onClick={() => {
                    showMenu("about");
                }}>
                    <FontAwesomeIcon icon={faCodeBranch} />
                </span>
            </>
        );
    }

    const showMenu = topic => {
        if(topic == "recipes") {
            setRecipeNavVisible(true);
        } else if(topic === "spirits") {
            setSpiritsNavVisible(true);
        } else if(topic === "types") {
            setTypesNavVisible(true);
        } else if(topic === "ingredients") {
            setIngredientsNavVisible(true);
        } else if(topic === "tags") {
            setTagsNavVisible(true);
        } else if(topic === "orders") {
            setOrderNavVisible(true);
        } else if(topic === "patrons") {
            setPatronNavVisible(true);
        } else if(topic === "locations") {
            setLocationNavVisible(true);
        } else if(topic === "orders-spirits") {
            setOrderSpiritsNavVisible(true);
        } else if(topic === "usermenu") {
            setUserMenuNavVisible(true);
        } else if(topic === "share") {
            setShareNavVisible(true);
        } else if(topic === "password") {
            setPasswordNavVisible(true);
        } else if(topic === "metrics") {
            setMetricsNavVisible(true);
        } else if(topic === "metrics-patrons") {
            setMetricsPatronsNavVisible(true);
        } else if(topic === "about") {
            setAboutNavVisible(true);
        }

        const body = document.getElementsByTagName("body")[0];
        body.classList.add("noscroll");
    }

    const hideMenu = topic => {
        if(topic == "recipes") {
            setRecipeNavVisible(false);
        } else if(topic == "spirits") {
            setSpiritsNavVisible(false);
        } else if(topic == "types") {
            setTypesNavVisible(false);
        } else if(topic == "ingredients") {
            setIngredientsNavVisible(false);
        } else if(topic === "tags") {
            setTagsNavVisible(false);
        } else if(topic == "orders") {
            setOrderNavVisible(false);
        } else if(topic == "patrons") {
            setPatronNavVisible(false);
        } else if(topic === "locations") {
            setLocationNavVisible(false);
        } else if(topic === "orders-spirits") {
            setOrderSpiritsNavVisible(false);
        } else if(topic == "usermenu") {
            setUserMenuNavVisible(false);
        } else if(topic === "share") {
            setShareNavVisible(false);
        } else if(topic === "password") {
            setPasswordNavVisible(false);
        } else if(topic === "metrics") {
            setMetricsNavVisible(false);
        } else if(topic === "metrics-patrons") {
            setMetricsPatronsNavVisible(false);
        } else if(topic === "about") {
            setAboutNavVisible(false);
        }

        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("noscroll");
    }

    const hideAllMenus = () => {
        const menus = document.getElementsByClassName("nav-modal");
        let elem = null;

        for(let i = 0; i < menus.length; i++) {
            elem = menus[i];
            elem.classList.remove("show");
            elem.classList.add("hide");
        }
    }

    const navTo = topic => {
        if(topic === "logout") {
            clearTokens();
        } else {
            showMenu(topic);
        }
    }

    return (
        <>
            <div id="app-header" className="header">
                <div className="header-item header-text">
                    <span onClick={ () => { showMenu("recipes") } }>Drinks</span>
                </div>
                <div className="header-item header-text">
                <span onClick={ () => { showMenu("orders") } }>Orders</span>
                </div>
                <div className="header-item icon-right">
                    { displayLoginHeader() }
                </div>
                <div className="header-item icon-right">
                    { displayAboutHeader() }
                </div>
                <div className="header-item icon-right">
                    { displayModeHeader() }
                </div>
                <div className="header-item icon-right">
                    { displayMetricsHeader() }
                </div>
            </div>
            <Login visible={loginVisible} setVisible={setLoginVisible} loginSuccessful={loginSuccessful} setTokenIsValid={setTokenIsValid} handleExpire={handleExpire} />
            <NavModal type="recipes" visible={ recipeNavVisible } hideMenu={() => { hideMenu("recipes") } } navTo={ navTo } />
            <NavModal type="spirits" visible={ spiritsNavVisible } hideMenu={() => { hideMenu("spirits") } } navTo={ navTo } />
            <NavModal type="types" visible={ typesNavVisible } hideMenu={() => { hideMenu("types") } } navTo={ navTo } />
            <NavModal type="ingredients" visible={ ingredientsNavVisible } hideMenu={() => { hideMenu("ingredients") } } navTo={ navTo } />
            <NavModal type="tags" visible={ tagsNavVisible } hideMenu={() => { hideMenu("tags") } } navTo={ navTo } />
            <NavModal type="orders" visible={ orderNavVisible } hideMenu={() => { hideMenu("orders") } } navTo={ navTo } />
            <NavModal type="patrons" visible={ patronNavVisible } hideMenu={() => { hideMenu("patrons") } } navTo={ navTo } />
            <NavModal type="locations" visible={ locationNavVisible } hideMenu={() => { hideMenu("locations") } } navTo={ navTo } />
            <NavModal type="orders-spirits" visible={ orderSpiritsNavVisible } hideMenu={() => { hideMenu("orders-spirits") } } navTo={ navTo } />
            <NavModal type="usermenu" visible={ userMenuNavVisible } hideMenu={() => { hideMenu("usermenu") } } navTo={ navTo } />
            <NavModal type="share" visible={ shareNavVisible } hideMenu={() => { hideMenu("share") } } navTo={ navTo } />
            <NavModal type="password" visible={ passwordNavVisible } hideMenu={() => { hideMenu("password") } } navTo={ navTo } />
            <NavModal type="metrics" visible={ metricsNavVisible } hideMenu={() => { hideMenu("metrics") } } navTo={ navTo } />
            <NavModal type="metrics-patrons" visible={ metricsPatronsNavVisible } hideMenu={() => { hideMenu("metrics-patrons") } } navTo={ navTo } />
            <NavModal type="about" visible={ aboutNavVisible } hideMenu={() => { hideMenu("about") } } navTo={ navTo } />
            <AlertModal />
            <ConfirmModal />
        </>
    );
}

export default Header;