import React, {useState, useEffect, useReducer} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from './helper/config';

import styles from './css/ShareModal.css';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import fastyles from './fa/css/all.css';

const ShareModal = ({
    recipe,
    isVisible = false,
    tokenId,
    imageShareData,
    closeShareModal
}) => {
    const shareUrl = window.location.protocol + "//" + window.location.host + "/s/recipes/" + recipe.slug + "?t=" + tokenId;

    if(!tokenId && !imageShareData) {
        return <></>
    }

    function base64ToBlob(base64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
        
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    const shareImage = () => {
        if(navigator.share) {
            const contentType = 'image/png'; // Replace with the actual content type of your image
            const blob = base64ToBlob(imageShareData.replace(/^data:image\/png;base64,/, ''), contentType);
            const files = [new File([blob], `${recipe.slug ?? "cocktail"}.png`, { type: blob.type })];
            const shareData = {
                "text": `${recipe.name}: ${recipe.description}`,
                "title": `${config.app.name} - ${recipe.name}`,
                "files": files
            };
            navigator.share(shareData);
        } else {
            try {
                const newTab = window.open();
                newTab.document.write(`<img src="${imageShareData}" />`);
            } catch(e) {
                console.error(e);
            }
        }

        closeShareModal();
    }


    const ShareButton = () => {
        if(navigator.share) {
            return <button onClick={() => {
                const shareData = {
                    "text": `${recipe.name}: ${recipe.description}`,
                    "title": `${config.app.name} - ${recipe.name}`,
                    "url": shareUrl
                };
                navigator.share(shareData);
                closeShareModal();
            }}><i className="fa-solid fa-arrow-up-from-bracket" /></button>
        } else {
            return <button onClick={() => {
                navigator.clipboard.writeText(shareUrl);
                closeShareModal();
            }}><FontAwesomeIcon icon={faCopy} /></button>
        }
    }

    const ShareLink = () => {
        if(!tokenId) {
            return <></>
        }

        return (
            <>
                <span className="share-option">- Share a Link -</span>
                <div className="share-box">
                    <input key={tokenId} type="text" id="recipe-share-url" readOnly={true} defaultValue={shareUrl} />
                    <ShareButton />
                </div>
                <div className="share-note">This link will be accessible for {config.session.share_ttl / 60 / 60} hours, after which a valid user account will be required for external access.{!navigator.share ? '  This link will be copied to the device clipboard.' : ''}</div>
            </>
        )
    }


    const RecipeImage = () => {
        if(!imageShareData) {
            return <img />
        }

        return (
            <img src={`${imageShareData}`} onClick={() => {
                shareImage();
            }} />
        )
    }
      

    return (
        <div className={`share-modal ${isVisible ? 'show' : 'hide'}`}>
            <div className="share-inner">
                <div className="title-container">
                    <div className="share-title">Select How to Share</div>
                    <div className="share-close">
                        <i className="fa-solid fa-xmark" onClick={() => {
                            closeShareModal();
                        }} />
                    </div>
                </div>
                <span className="share-option">- Share a Picture -</span>
                <div className="share-image">
                    <RecipeImage />
                </div>
                {!navigator.share ? <div className="share-note-image">The recipe picture will open in a new browser tab.</div> : ''}
                <br /><br />
                <ShareLink />
            </div>
        </div>
    );
}

export default ShareModal;