import React, {useState, useEffect, useReducer} from 'react';

import ws from '../helper/ws';
import metrics from '../helper/metrics';
import cocktail from '../helper/cocktail';

//import styles from './css/MetricsHome.css';
//import { faCocktail } from '@fortawesome/free-solid-svg-icons';

const RecipeQuickStats = ({ recipe, themeColor }) => {
    const [orderRank, setOrderRank] = useState(null);
    const [ratingRank, setRatingRank] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        metrics.getRecipesPopular({
            "total" : 1000
        }).then(output => {
            output.metrics.forEach((rank, i) => {
                if(rank.recipe === recipe.name) {
                    setOrderRank(i + 1);
                }
            })
        }).catch(e => {
            setError("Visualization Unavailable");
        });

        ws.apiRequest({
            "path" : "/recipes/cache",
            "params" : {
                "sort" : "average_rating",
                "direction" : "desc",
                "size" : 5000
            }
        }).then(output => {
            output.data.response.forEach((recipeCache, i) => {
                if(recipeCache.id === recipe.id) {
                    setRatingRank(i + 1);
                }
            })
        }).catch(e => {
            console.error(e);
        })
    }, [recipe]);

    const getOrderRank = () => {
        if(!orderRank) {
            return "";
        }

        return getOrdinal(Number(orderRank));
    }

    const getRatingRank = () => {
        if(!ratingRank) {
            return "";
        }

        return getOrdinal(Number(ratingRank));
    }

    const getOrdinal = i => {
        const j = i % 10,
            k = i % 100;

        if (j === 1 && k !== 11) {
            return i + "st";
        }

        if (j === 2 && k !== 12) {
            return i + "nd";
        }

        if (j === 3 && k !== 13) {
            return i + "rd";
        }

        return i + "th";
    }

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        return (
            <>
                <div className="split-container split-top">
                    <div className="quick-stat">
                        <span className="stat">{recipe.order_count || 0}</span>
                        <span className="caption">Total Times</span>
                        <div />
                        <span className="caption">Ordered</span>
                    </div>
                    <div className="quick-stat">
                        <span className="stat">{recipe.average_rating || 0}</span>
                        <span className="caption">Average</span>
                        <div />
                        <span className="caption">Rating</span>
                    </div>
                </div>
                <div className="split-container">
                    <div className="quick-stat">
                        <span className="stat">{getOrderRank()}</span>
                        <span className="caption">Most Ordered</span>
                        <div />
                        <span className="caption">Drink</span>
                    </div>
                    <div className="quick-stat">
                        <span className="stat">{getRatingRank()}</span>
                        <span className="caption">Highest Rated</span>
                        <div />
                        <span className="caption">Drink</span>
                    </div>
                </div>
            </>
        )
    }

    return <Chart />
}

export default RecipeQuickStats;