import React from 'react';

class ErrorBoundary extends React.Component {
  	constructor(props) {
    	super(props);
    	this.state = {
            hasError: false,
            errorString : null
        };
  	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return {
            hasError: true,
            errorString: error
        };
	}

	componentDidCatch(error, info) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		//logErrorToMyService(error, info.componentStack);
	}

	render() {
		if (this.state.hasError) {
            if(this.props.type == "main" || !this.props.type) {
                return (
                    <div className="error-container">
                        <h1>Oh no, something went wrong!</h1>
                        <img src="/images/broken-cocktail.jpg" />
                        <div className="button-group button-group-right">
                            <button className="primary" onClick={() => {
                                window.location.href = '/';
                            }}>Home Screen</button>
                            <button onClick={() => {
                                window.location.reload();
                            }}><i className="fa-solid fa-rotate-right" /></button>
                        </div>
                        <div className="error-message">
                            {this.state.errorString.toString()}
                        </div>
                    </div>
                );
            }
			
            return (
                <div className="error-container">
                    <h1>Oops, something went wrong!</h1>
                </div>
            );
		}

		return this.props.children;
	}
}

export default ErrorBoundary;