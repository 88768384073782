
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {BrowserRouter, Link, Routes, Route, Switch, useParams} from 'react-router-dom';

import config from './helper/config';
import idm from './helper/idm';
import cocktail from './helper/cocktail';

import ErrorBoundry from './ErrorBoundry';
import Header from './Header';
import RecipeSearch from './RecipeSearch';
import RecipeLoader from './RecipeLoader';
import OrderSearch from './OrderSearch';
import OrderLoader from './OrderLoader';
import RecipeForm from './RecipeForm';
import PatronInfo from './PatronInfo';
import MetricsHome from './MetricsHome';
import {setTokenIsValid} from './reducers/appSlice';
import {set as setPatrons} from './reducers/patronsSlice';
import {set as setRecipeCache} from './reducers/recipeCacheSlice';
import {set as setTags} from './reducers/tagsSlice';
import {set as setLocations} from './reducers/locationsSlice';

import styles from './css/App.css';

const App = () => {
    const [tokenInitiated, setTokenInitiated] = useState(false);//useState(idm.isLoggedIn());
    //const [tokenIsValid, setTokenIsValid] = useState(idm.isLoggedIn());
    const [themeColor, setThemeColor] = useState(null);
    const tokenIsValid = useSelector((state) => state.app.tokenIsValid);
    const patrons = useSelector((state) => state.patrons.list);
    const recipeCache = useSelector((state) => state.recipeCache.list);
    const tags = useSelector((state) => state.tags.list);
    const locations = useSelector((state) => state.locations.list);
    const dispatch = useDispatch();

    const sessionChanged = (sessionValid) => {
        if(sessionValid) {
            //setTokenIsValid(true);
            dispatch(setTokenIsValid(true));
        } else {
            dispatch(setTokenIsValid(false));
            idm.clearAllTokens();
        }
    }

    useEffect(() => {
        idm.initiate({
            "isInitiated" : () => {
                if(!tokenInitiated) {
                    setTokenInitiated(true);
                }

                if(!tokenIsValid) {
                    dispatch(setTokenIsValid(true));
                }
            },
            "handleExpire" : sessionChanged
        });
    }, [tokenInitiated]);

    // initiate application cache
    useEffect(() => {
        // patrons
        if(idm.tokenHasRole("order_admin") && !patrons.length) {
            cocktail.getPatrons().then(output => {
                dispatch(setPatrons(output));
            }).catch(e => {
                console.error(e);
            });
        }

        // all recipes (limited fields)
        if(idm.tokenHasRole("recipe_admin") && recipeCache.length < 1) {
            cocktail.getRecipeCacheAll().then(output => {
                dispatch(setRecipeCache(output));
            }).catch(e => {
                console.error(e);
            });
        }

        if(idm.tokenHasRole("recipe_admin") && tags.length < 1) {
            cocktail.getTags().then(output => {
                dispatch(setTags(output));
            }).catch(e => {
                console.error(e);
            });
        }

        if(idm.tokenHasRole("order_viewer") && locations.length < 1) {
            cocktail.getLocations().then(output => {
                dispatch(setLocations(output));
            }).catch(e => {
                console.error(e);
            });
        }
    }, [tokenIsValid]);
    // end initiate application cache

    const setTokenIsValidDispatch = isValid => {
        dispatch(setTokenIsValid(isValid));
    }

    const NotFound = () => {
        return (
            <div className="not-found">
                O Noes!  The Route you attempted to reach could not be found...
            </div>
        );
    }

    const Welcome = () => {
        return (
            <div className="welcome">
                <div className="welcome-title">Welcome to {config.app.name}!</div>
                <div className="welcome-message">How would you like to browse our drinks?</div>
                <a onClick={() => {
                    window.location.href = "/recipes/favorites";
                }}>Favorites</a>
                <a onClick={() => {
                    window.location.href = "/recipes/seasonal";
                }}>Seasonal</a>
                <a onClick={() => {
                    window.location.href = "/recipes";
                }}>Everything</a>
                <a onClick={() => {
                    window.location.href = "/recipes/random";
                }}>Surprise Me!</a>
            </div>
        );
    }

    const themeChanged = () => {
        if(document.getElementById("app-header")) {
            setThemeColor(window.getComputedStyle(document.getElementById("app-header")).backgroundColor.toString());
        }
    }

    return (
        <ErrorBoundry type="main">
            <BrowserRouter>
                <Header sessionChanged={sessionChanged} setTokenIsValid={setTokenIsValidDispatch} themeChanged={themeChanged} />
                <Routes>
                    {/*<Route path="/" element={<RecipeSearch searchType="general" />} />*/}
                    <Route path="/" element={<Welcome />} />
                    <Route path="/recipes" element={<RecipeSearch searchType="general" />} />
                    <Route path="/recipes/favorites" element={<RecipeSearch searchType="favorites" />} />
                    <Route path="/recipes/seasonal" element={<RecipeSearch searchType="seasonal" />} />
                    <Route path="/recipes/ingredients" element={<RecipeSearch searchType="ingredients" />} />
                    <Route path="/recipes/types/:type" element={<RecipeSearch searchType="types" />} />
                    <Route path="/recipes/spirits/:spirit" element={<RecipeSearch searchType="spirits" />} />
                    <Route path="/recipes/glasses/:glassType" element={<RecipeSearch searchType="glass-types" />} />
                    <Route path="/recipes/garnishes/:garnish" element={<RecipeSearch searchType="garnishes" />} />
                    <Route path="/recipes/tags/:tag" element={<RecipeSearch searchType="tags" />} />
                    <Route path="/recipes/create" element={<RecipeForm />} />
                    <Route path="/recipes/random" element={<RecipeLoader random={true} />} />
                    <Route path="/recipes/:recipeId" element={<RecipeLoader />} />
                    <Route path="/orders" element={<OrderSearch />} />
                    <Route path="/orders/patrons/:patron" element={<OrderSearch searchType="patron" />} />
                    <Route path="/orders/locations/:location" element={<OrderSearch searchType="location" />} />
                    <Route path="/orders/recipes/:recipe" element={<OrderSearch searchType="recipe" />} />
                    <Route path="/orders/spirits/:spirit" element={<OrderSearch searchType="spirits" />} />
                    <Route path="/orders/actual-spirits/:actualSpirit" element={<OrderSearch searchType="actual-spirit" />} />
                    <Route path="/orders/new" element={<OrderSearch searchType="new" />} />
                    <Route path="/orders/:orderId" element={<OrderLoader />} />
                    <Route path="/metrics" element={<MetricsHome themeColor={themeColor} />} />
                    <Route path="/metrics/popularity" element={<MetricsHome searchType="popularity" themeColor={themeColor} />} />
                    <Route path="/metrics/spirits" element={<MetricsHome searchType="spirits" themeColor={themeColor} />} />
                    <Route path="/metrics/trends" element={<MetricsHome searchType="trends" themeColor={themeColor} />} />
                    <Route path="/metrics/patrons/:patron" element={<MetricsHome searchType="patron" themeColor={themeColor} />} />
                    <Route path="/metrics/recipes/:recipeId" element={<MetricsHome searchType="recipe-id" themeColor={themeColor} />} />
                    <Route path="/metrics/recipes" element={<MetricsHome searchType="recipes" themeColor={themeColor} />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            <PatronInfo />
        </ErrorBoundry>
    );
}

export default App;
