import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrdersMinMax = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    useEffect(() => {
        metrics.getOrdersHighRating({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(recipesHighRating => {
            setChartData(recipesHighRating);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'columnrange',
                        inverted: true
                    },
                    title: {
                        text: `${chartData.buckets} Most Ordered Drinks`
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return `The highest and lowest rating for the top ${chartData.buckets} ordered drinks`;
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `High/low ratings ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        categories: chartData.metrics.map(bucket => {
                            return bucket.recipe.length > 12 ? bucket.recipe.substring(0, 12) + "..." : bucket.recipe;
                        })
                    },
                    yAxis: {
                        title: {
                            text: 'Rating Range'
                        }
                    },
                    tooltip: {
                        valueSuffix: '',
                        formatter: function() {
                            return "<b>" + this.point.recipe + "</b><br />Highest Rating: " + this.point.high + "<br />Lowest Rating: " + this.point.low + "<br />Average Rating: " + this.point.average +  "<br />Total Orders: " + this.point.count;
                        }
                    },
                    plotOptions: {
                        columnrange: {
                            dataLabels: {
                                enabled: true,
                                format: '{y}',
                                style: {
                                    textOutline: "0px"
                                }
                            },
                            color: graphColors[5]
                        },
                        series: {
                            borderWidth: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        name: 'Min/Max Ratings',
                        data: chartData.metrics.map(bucket => {
                            /*
                            return [
                                bucket.min_rating,
                                bucket.max_rating
                            ]
                            */
                        return {
                            "low" : bucket.min_rating,
                            "high" : bucket.max_rating,
                            "average" : bucket.avg_rating,
                            "count" : bucket.count,
                            "recipe" : bucket.recipe
                        }
                        }),
                        color: {
                            linearGradient: {
                                x1: 0,
                                x2: 0,
                                y1: 0,
                                y2: 1
                            },
                            stops: [
                                [0, graphColors[9]],
                                [1, graphColors[0]]
                            ]
                        }
                    }],
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        );

    }

    return <Chart />
}

export default OrdersMinMax;