import React, {useState, useEffect, useRef} from 'react';

import config from './helper/config';

import styles from './css/SearchBox.css';

const useOutsideBlur = (ref, setInputText) => {
    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setInputText("");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const SearchBox = ({ placeholderText, searchType, searchText, setSearchText, filterText }) => {
    let searchTimeout = null;
    const [inputText, setInputText] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [showTypeahead, setShowTypeahead] = useState(false);
    const searchBoxRef = useRef(null);

    const handleSearchClick = () => {
        let text = document.getElementById('search-' + searchType).value;
        setSearchText(text);
    }

    useEffect(() => {
        //document.getElementById(`search-${searchType}`).value = searchText;
        setInputText(searchText);
    }, [searchText]);

    const hideTypeahead = () => {
        setShowTypeahead(false);
        setSelectedIndex(-1);
    }

    const clearSearchText = () => {
        setInputText("");
        setSearchText("");
    }

    const handleTextChange = event => {
        setInputText(event.target.value);
    }

    const ClearButton = () => {
        if(inputText) {
            return (
                <button
                    className="clear-button" 
                    onClick={event => {
                        if(document.getElementById(`search-${searchType}`).value) {
                            hideTypeahead();
                            clearSearchText();
                            searchBoxRef.current.focus();
                        }
                    }}
                ><i className="fa-solid fa-xmark" /></button>
            );
        }

        return <></>
    }

    const Typeahead = () => {

        if(!filterText) {
            return null;
        }

        if(inputText.length < 1) {
            return null;
        }

        const matches = filterText(inputText);

        if(matches.length < 1) {
            return null;
        }

        return (
            <ul id={`ta-${searchType}`} className={showTypeahead ? "show" : "hide"}>
                {matches.map((name, i) => {
                    return (
                        <li
                            key={name}
                            className={`${selectedIndex == i ? "selected" : ""}`}
                            onClick={() => {
                                //searchBoxRef.current.value = name;
                                setSearchText(name);
                                hideTypeahead();
                                //handleSearchClick();
                            }}>
                            {name}
                        </li>
                    );
                })}
            </ul>
        );
    }

    useOutsideBlur(searchBoxRef, hideTypeahead);
    
    return (
        <div className="search-box" ref={searchBoxRef}>
            <input
                key={`search-${searchType}`}
                type="text"
                className="search"
                id={"search-" + searchType}
                placeholder={placeholderText}
                value={inputText}
                onClick={() => { }}
                onChange={ handleTextChange }
                onKeyDown={event => {
                    clearTimeout(searchTimeout);

                    if(event.code === "Enter") {
                        handleSearchClick();
                        hideTypeahead();
                        return;
                    } else if(event.code === "Escape") {
                        hideTypeahead();
                        return;
                    } else if(event.code === "ArrowDown") {
                        const list = document.getElementById(`ta-${searchType}`);
                        const listElements = list ? list.getElementsByTagName("li") : [];

                        setSelectedIndex(selectedIndex + 1 >= listElements.length ? listElements.length - 1 : selectedIndex + 1);
                        event.preventDefault();
                    } else if(event.code === "ArrowUp") {
                        setSelectedIndex(selectedIndex - 1 > -2 ? selectedIndex - 1 : -1);
                        event.preventDefault();
                    } else if(event.code === "Tab") {
                        event.target.value = document.getElementById(`ta-${searchType}`).getElementsByTagName("li")[selectedIndex].innerText;
                        hideTypeahead();
                        event.preventDefault();
                    } else if(!showTypeahead) {
                        setShowTypeahead(true);
                    }

                    if(event.target.value.length > 1 && config.app.search_auto) {
                        setTimeout(handleSearchClick, config.app.search_timeout);
                    }
                }}
            />
            <ClearButton />
            <button
                className="search-button"
                onClick={() => {
                    hideTypeahead();
                    handleSearchClick();
                }}
            ><i className="fa-solid fa-magnifying-glass" /></button>
            <Typeahead />
        </div>
    );
}

export default SearchBox;