import { createSlice } from '@reduxjs/toolkit';

export const recipeCacheSlice = createSlice({
    name: 'recipeCache',
    initialState: {
        list: [],
    },
    reducers: {
        set : (state, input) => {
            state.list = input.payload;
        },
        fetch : (state) => {
            // fetch from server TBD
        }
    },
});

export const { set, fetch } = recipeCacheSlice.actions;
export default recipeCacheSlice.reducer;