import React, { useEffect, useState } from "react";

import styles from './css/Rating.css';
import fastyles from './fa/css/all.css';

const Rating = ({ceiling, Id, ratingStart}) => { 
    const [rating, setRating] = useState(ratingStart);
    const [hover, setHover] = useState(ratingStart);

    useEffect(() => {
        setRating(ratingStart);
    }, [ratingStart]);

    ceiling = ceiling ? ceiling : 10;

    return (
        <div className="rating" key={"rating-" + Id}>
            {[...Array(ceiling).keys()].map((index) => {
                index++; // increment because index starts at 0
                return (
                    <button key={"rating-" + Id + "-" + index}
                        id={"rating-" + Id + "-" + index}
                        type="button"
                        className={`rating-button ${index <= (hover || rating) ? "on" : "off"}`}
                        onClick={() => setRating(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <i className="fa-solid fa-martini-glass" />
                    </button>
                );
            })}
        </div>
    );
};

export default Rating;
