import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';
import config from '../helper/config';

const RecipesHighRating = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [chart, setChart] = useState(null);
    const [error, setError] = useState(null);
    let docMin = 0;
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    const genChart = refresh => {
        if(chartData && !refresh) {
            return;
        }

        let docMin = 0;

        if(patron || (daysAgo && daysAgo < 90)) {
            docMin = 0;
        } else {
            docMin = config.app.order_count_min;
        }

        metrics.getRecipesHighRating({
            "patron" : patron,
            "days_ago" : daysAgo,
            "order_count_min" : docMin
        }).then(recipesHighRating => {
            setChartData(recipesHighRating);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }

    useEffect(() => {
        if(!chartData) {
            return;
        }

        let docMin = 0;

        if(patron || (daysAgo && daysAgo < 90)) {
            docMin = 0;
        } else {
            docMin = config.app.order_count_min;
        }

        setChart({
            chart: {
                type: 'column'
            },
            title: {
                text: 'Highest Rated Drinks'
            },
            subtitle: {
                text: (() => {
                    if(docMin) {
                        return `With a minimum of ${config.app.order_count_min} orders`;
                    }
                    const daysAgoLabel = () => {
                        if(daysAgo === 365) {
                            return "year";
                        } else if(daysAgo === 182) {
                            return "6 months";
                        } else if(daysAgo === 90) {
                            return "3 months";
                        } else if(daysAgo === 14) {
                            return "2 weeks";
                        } else {
                            return `${daysAgo} days`;
                        }
                    }
                    return `${chartData.buckets} Highest Rated Drinks ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                })()
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '10px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: '<b>{point.y:.1f}</b>'
            },
            plotOptions: {
                series: {
                    borderWidth: 0
                },
                column: {
                    colorByPoint: true
                }
            },
            colors: graphColors,
            series: [{
                name: 'Rating',
                data: chartData.metrics.map(recipe => {
                    return [
                        recipe.recipe,
                        recipe.avg_rating
                    ]
                }),
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: '#FFFFFF',
                    align: 'right',
                    format: '{point.y:.1f}', // one decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }],
            "credits" : {
                "enabled" : false
            }
        });
    }, [chartData]);

    useEffect(() => {
        genChart(true);
    }, [daysAgo, patron]);

    genChart(); 

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={chart}
            />
        )
    }

    return <Chart />
}

export default RecipesHighRating;