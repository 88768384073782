import { createSlice } from '@reduxjs/toolkit';

export const recipesSlice = createSlice({
    name: 'recipes',
    initialState: {
        list: null,
    },
    reducers: {
        set : (state, input) => {
            state.list = input.payload;
        },
        fetch : (state) => {
            // fetch from server TBD
        },
        setRecipeSingle : (state, input) => {
            state.list = state.list.map(recipeEl => {
                if(input.payload.id == recipeEl.id) {
                    return input.payload;
                }
                return recipeEl;
            });
        }
    },
});

export const { set, fetch, setRecipeSingle } = recipesSlice.actions;
export default recipesSlice.reducer;