import React, {useState, useEffect, useReducer} from 'react';
import {Routes, Route, Outlet, Link} from 'react-router-dom';
import idm from './helper/idm';

import styles from './css/PatronInfo.css';

const PatronInfo = ({visible = false}) => {
    const [isVisible, setIsVisible] = useState(visible);
    let patronName = localStorage.getItem("patronName") || "";

    useEffect(() => {
        if(!patronName && !isVisible && !idm.tokenHasRole("recipe_readonly")) {
            //setIsVisible(true);
        }
    });

    const handleSave = (event) => {
        let patronName = document.getElementById("patron-name").value;

        if(!patronName) {
            return;
        } else if(patronName.split(/\s+/).length < 2) {
            document.getElementById("patron-error").classList.remove("hide");
        } else {
            document.getElementById("patron-error").classList.add("hide");
        }

        localStorage.setItem("patronName", patronName);
        setIsVisible(false);
    }

    return (
        <div className={"patron-modal " + (isVisible ? "show" : "hide")}>
            <div className="body">
                <div>
                    <div className="patron-info-box">
                        <span className="hello">Hello, who's ordering drinks?</span>
                    </div>
                    <div id="patron-error" className="patron-error hide">
                        <span>Please enter your full name (both first and last)</span>
                    </div>
                    <div className="patron-info-box">
                        <input type="text" className="name" id="patron-name" placeholder="Your Name" /><button onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PatronInfo;