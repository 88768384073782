'use strict';

const config = {
    "app" : {
        "name" : "The Study",
        "spirits_max" : 7,
        "default_establishment" : "The Study",
        "search_auto" : false,
        "search_timeout" : 2, // number of seconds to wait before automatically searching
        "default_theme" : 4,
        "total_themes" : 8,
        "order_count_min" : 5,
        "results_per_page" : 10
    },
    "api" : {
        "ssl" : false,
        "host" : "",//"dev.api.thestudy.bar", // leave blank to use the same host for both API and UI
        "port" : null,
        "root" : "/api/v2",
        "contextRoot" : {
            "recipes" : "/api/v2",
            "orders" : "/api/v2",
            "auth" : "/api/v2",
            "metrics" : "/api/v2"
        }
    },
    "session" : {
        "expire_time" : (60 * 60 * 10), // 10 hours in seconds, this is configured within IDM
        "warn_time" : (5 * 60), // amount of warning to give the user before their session ends
        "share_ttl" : (24 * 60 * 60) // amount of time (seconds) a shared link will be accessible
    }
};

module.exports = config;