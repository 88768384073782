import React, {useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrdersByDateType = ({ patron, daysAgo, recipeId, byWeekday=null, byMonth=null, byYear=null, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    const days = {
        "1" : "Sunday",
        "2" : "Monday",
        "3" : "Tuesday",
        "4" : "Wednesday",
        "5" : "Thursday",
        "6" : "Friday",
        "7" : "Saturday"
    }
    const months = {
        "1" : "January",
        "2" : "February",
        "3" : "March",
        "4" : "April",
        "5" : "May",
        "6" : "June",
        "7" : "July",
        "8" : "August",
        "9" : "September",
        "10" : "October",
        "11" : "November",
        "12" : "December"
    }

    useEffect(() => {
        metrics.getOrdersByDateType({
            "patron" : patron,
            "days_ago" : daysAgo,
            "recipe_id" : recipeId,
            "by_weekday" : byWeekday ?? "",
            "by_month" : byMonth ?? "",
            "by_year" : byYear ?? ""
        }).then(ordersData => {
            // ordersData.results[i].date is a numeric value (in string format)
            // sort the array based on the numeric value of the date value
            ordersData.metrics = ordersData.metrics.sort((a, b) => {
                return Number(a.date) > Number(b.date);
            });
            setChartData(ordersData);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: `Number of Orders by ${byWeekday ? "Day" : ""}${byMonth ? "Month" : ""}${byYear ? "Year" : ""}`
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return null;//"Total Number of Drink Orders Grouped By Date";
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `Drink orders by ${byYear ? "day" : ""}${byMonth ? "month" : ""}${byYear ? "year" : ""} ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        type: 'category',
                        labels: {
                            rotation: -45,
                            style: {
                                fontSize: '10px',
                                fontFamily: 'Verdana, sans-serif'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        //pointFormat: '<b></b>'
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0
                        },
                        column: {
                            colorByPoint: true
                        }
                    },
                    colors: graphColors,
                    series: [{
                        name: 'Order Count',
                        data: chartData.metrics.map(bucket => {
                            return [
                                (() => {
                                    if(byMonth) {
                                        return months[bucket.date];
                                    } else if(byWeekday) {
                                        return days[bucket.date];
                                    } else {
                                        return bucket.date;
                                    }
                                })(),
                                bucket.count
                            ]
                        }),
                        dataLabels: {
                            enabled: true,
                            rotation: -90,
                            color: '#FFFFFF',
                            align: 'right',
                            //format: '{point.y:.1f}', // one decimal
                            y: 10, // 10 pixels down from the top
                            style: {
                                fontSize: '13px',
                                fontFamily: 'Verdana, sans-serif'
                            }
                        }
                    }],
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        );
    }

    return <Chart />
}

export default OrdersByDateType;