import React, {useState, useEffect} from 'react';

import styles from './css/AlertModal.css';

const AlertModal = ({  }) => {
    const hideModal = () => {
        document.getElementById("alert-modal").classList.remove("show");
        document.getElementById("alert-modal").classList.add("hide");
    }

    return (
        <div id="alert-modal" className="alert-modal hide">
            <div className="inner">
                <div id="alert-message" className="alert-message">lol testing</div>
                <div className="actions">
                    <button className="standard-button" onClick={ hideModal }>Ok</button>
                </div>
            </div>
        </div>
    );
}

export default AlertModal;