import React, {useState, useEffect, useReducer} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';

import Order from './Order';
import OrderUpdate from './OrderUpdate';
import Modal from './Modal';

import cocktail from './helper/cocktail';

const OrderLoader = ({ random }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [editVisible, setEditVisible] = useState(false);
    const orderId = params.orderId;
    const updateParam = searchParams.get("update");

    useEffect(() => {
        // don't run if the order has already been loaded
        // or if no recipe ID has been provided
        if(order.id == orderId || !orderId) {
            return;
        }

        cocktail.getOrder(orderId).then(orderDoc => {
            setOrder(orderDoc);
        }).catch(e => {
            console.log(e);
        });
    }, [orderId]);

    const hideEditModal = () => {
        setEditVisible(false);
    }

    const showRecipeEdit = ({ hideRecipeModal }) => {
        setEditVisible(true);

        if(hideRecipeModal) {
            setEditVisible(false);
        }
    }

    const deleteOrdersItem = () => {

    }

    const refreshSearch = () => {

    }

    const hideOrderUpdate = () => {
        //navigate(`/orders/${order.id}`);
        navigate(-1);
    }

    const renderOrder = () => {
        if(order.id) {
            if(!updateParam) {
                return (
                    <>
                        <Order key={order.id} order={order} setOrder={setOrder} deleteOrdersItem={deleteOrdersItem} refreshSearch={refreshSearch} single={true} originUrl="" />
                    </>
                );
            } else {
                return (
                    <>
                        <OrderUpdate order={order} setOrder={setOrder} deleteOrdersItem={deleteOrdersItem} visible={true} />
                    </>
                );
            }
        } else {
            return <></>
        }
    }

    return renderOrder();
}

export default OrderLoader;