import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const RecipeGarnishes = ({ themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    useEffect(() => {
        metrics.getRecipeGarnishes({
            "total" : 15
        }).then(recipeGarnishes => {
            setChartData(recipeGarnishes);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Most Common Garnishes'
                    },
                    subtitle: {
                        text: `${chartData.buckets} Most Used Garnishes by Drink`
                    },
                    xAxis: {
                        type: 'category',
                        labels: {
                            rotation: -45,
                            style: {
                                fontSize: '10px',
                                fontFamily: 'Verdana, sans-serif'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        pointFormat: '<b>{point.y}</b> Drinks'
                    },
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            color: graphColors
                        },
                        column: {
                            colorByPoint: true
                        }
                    },
                    series: [{
                        name: 'Garnish',
                        data: chartData.metrics.map(bucket => {
                            return [
                                bucket.garnish ? bucket.garnish : "No Garnish",
                                bucket.count
                            ]
                        }),
                        dataLabels: {
                            enabled: true,
                            rotation: -90,
                            color: '#FFFFFF',
                            align: 'right',
                            format: '{point.y}', // one decimal
                            y: 10, // 10 pixels down from the top
                            style: {
                                fontSize: '13px',
                                fontFamily: 'Verdana, sans-serif'
                            }
                        },
                        colors: graphColors
                    }],
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default RecipeGarnishes;