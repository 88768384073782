import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrderGlassTypes = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [chart, setChart] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColors(themeColor);
    }());

    const genChart = refresh => {
        if(chartData && !refresh) {
            return;
        }

        metrics.getOrderGlassTypes({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(orderGlassTypes => {
            setChartData(orderGlassTypes);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }

    useEffect(() => {
        if(!chartData) {
            return;
        }

        setChart({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Glasses by Order'
            },
            subtitle: {
                text: (() => {
                    if(!daysAgo && !patron) {
                        return null;
                    }
                    const daysAgoLabel = () => {
                        if(daysAgo === 365) {
                            return "year";
                        } else if(daysAgo === 182) {
                            return "6 months";
                        } else if(daysAgo === 90) {
                            return "3 months";
                        } else if(daysAgo === 14) {
                            return "2 weeks";
                        } else {
                            return `${daysAgo} days`;
                        }
                    }
                    return `Glasses Used ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                })()
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br />{point.y} Orders'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    borderWidth: 0,
                    cursor: 'pointer',
                    colors: graphColors,
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                        distance: -50,
                        filter: {
                            property: 'percentage',
                            operator: '>',
                            value: 4
                        }
                    }
                }
            },
            series: [{
                name: 'Share',
                data: chartData.metrics.map((glass_type, i) => {
                    return {
                        "name" : glass_type.glass_type,
                        "y" : glass_type.count
                    }
                })
            }],
            "credits" : {
                "enabled" : false
            }
        });
    }, [chartData]);

    useEffect(() => {
        genChart(true);
    }, [daysAgo, patron]);

    genChart(); 

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={chart}
            />
        );
    }

    return <Chart />
}

export default OrderGlassTypes;