import React, {useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const RecipesPopular = ({ patron, daysAgo, themeColor }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColorsPie(themeColor);
    }());

    useEffect(() => {
        metrics.getRecipesPopular({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(recipesPopular => {
            setError(null);
            setChartData(recipesPopular);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);


    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        return <HighchartsReact
            highcharts={Highcharts}
            options={{
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: `Top ${chartData.buckets} Most Popular Drinks`
                },
                subtitle: {
                    text: (() => {
                        if(!daysAgo && !patron) {
                            return null;
                        }
                        const daysAgoLabel = () => {
                            if(daysAgo === 365) {
                                return "year";
                            } else if(daysAgo === 182) {
                                return "6 months";
                            } else if(daysAgo === 90) {
                                return "3 months";
                            } else if(daysAgo === 14) {
                                return "2 weeks";
                            } else {
                                return `${daysAgo} days`;
                            }
                        }
                        return `Popular Drinks ${patron ? `for ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                    })()
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b><br />{point.y} Orders'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        borderWidth: 0,
                        cursor: 'pointer',
                        colors: graphColors,
                        dataLabels: {
                            enabled: true,
                            format: '<b>{point.name_short}</b><br>{point.percentage:.1f} %',
                            distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        }
                    }
                },
                series: [{
                    name: 'Share',
                    data: chartData.metrics.map((recipe, i) => {
                        return {
                            "name" : recipe.recipe,
                            "name_short" : recipe.recipe.length > 12 ? recipe.recipe.substring(0, 12) + "..." : recipe.recipe,
                            "y" : recipe.count,
                            "events" : {
                                "click" : e => {
                                    console.log(e);
                                    return false;
                                }
                            }
                        }
                    })
                }],
                "credits" : {
                    "enabled" : false
                }
            }}
        />
    }

    return <Chart />
}

export default RecipesPopular;