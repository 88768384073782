import React, {useState, useEffect} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import metrics from '../helper/metrics';

const OrderPrimarySpiritsHistogram = ({ patron, daysAgo }) => {
    const [chartData, setChartData] = useState(null);
    const [uniqueSpirits, setUniqueSpirits] = useState(null);
    const [error, setError] = useState(null);
    
    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    useEffect(() => {
        metrics.getOrderPrimarySpiritsHistogram({
            "patron" : patron,
            "days_ago" : daysAgo
        }).then(ordersHistogram => {
            setChartData(ordersHistogram);

            let _uniqueSpirits = [];

            ordersHistogram.metrics.forEach(orderBucket => {
                orderBucket.primary_spirits.forEach(spiritBucket => {
                    if(!_uniqueSpirits.includes(spiritBucket.spirit)) {
                        _uniqueSpirits.push(spiritBucket.spirit);
                    }
                });
            });

            setUniqueSpirits(_uniqueSpirits);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, [patron, daysAgo]);

    
    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData || !uniqueSpirits) {
            return <></>
        }

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: 'spline'
                    },
                    title: {
                        text: 'Primary Spirit Use By Date'
                    },
                    subtitle: {
                        text: (() => {
                            if(!daysAgo && !patron) {
                                return "Total orders per day by primary spirit type";
                            }
                            const daysAgoLabel = () => {
                                if(daysAgo === 365) {
                                    return "year";
                                } else if(daysAgo === 182) {
                                    return "6 months";
                                } else if(daysAgo === 90) {
                                    return "3 months";
                                } else if(daysAgo === 14) {
                                    return "2 weeks";
                                } else {
                                    return `${daysAgo} days`;
                                }
                            }
                            return `Top spirits by day ${patron ? `by ${patron} ` : ""}${daysAgo ? `in the last ${daysAgoLabel()}` : ""}`;
                        })()
                    },
                    xAxis: {
                        type: "datetime"
                    },
                    yAxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        pointFormatter: function () {
                            return `<b>${this.y}</b> ${this.series.name} Drinks Ordered on ${this.date}`;
                        }
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        dateFormat: "YYYY-mm-dd"
                    },
                    series: uniqueSpirits.map((spirit, i) => {
                        return {
                            "name" : spirit,
                            "data" : chartData.metrics.map(orderBucket => {
                                let spiritResults = orderBucket.primary_spirits.filter(spiritBucket => spiritBucket.spirit == spirit).map(spiritBucket => {
                                    return {
                                        "x" : new Date(orderBucket.date).getTime(),
                                        "y" : spiritBucket.count,
                                        "date" : orderBucket.date
                                    }
                                });
                                return spiritResults.length > 0 ? spiritResults[0] : {
                                    "x" : new Date(orderBucket.date).getTime(),
                                    "y" : 0,
                                    "date" : orderBucket.date
                                }
                            }),
                            "color" : graphColors[i % 10]
                        }
                    }),
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        );
    }

    return <Chart />
}

export default OrderPrimarySpiritsHistogram;