import React, {useState, useEffect, useReducer} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDependencyWheel from 'highcharts/modules/dependency-wheel';

import metrics from '../helper/metrics';

HighchartsDependencyWheel(Highcharts);

const RecipeSpiritsCommonCombination = ({  }) => {
    const [chartData, setChartData] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        metrics.getRecipeSpiritsCommonCombination().then(primarySpiritsSpirits => {
            setChartData(primarySpiritsSpirits);
        }).catch(e => {
            setError("Visualization Unavailable");
        });
    }, []);

    const graphColors = (function () {
        return metrics.graphColorsAll();
    }());

    const Chart = () => {
        if(error) {
            return <div className="metric-error">
                <i className="fa-solid fa-triangle-exclamation" />
                {error}
            </div>
        }

        if(!chartData) {
            return <></>
        }

        let spirits = [];
        chartData.metrics.map(result => {
            return result.spirits.map(spiritAgg => {
                spirits.push([
                    result.primary_spirit,
                    spiritAgg.spirit,
                    spiritAgg.count
                ]);
            });
        });

        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    "series" : [{
                        "type" : "dependencywheel",
                        "keys" : ['from', 'to', 'weight'],
                        "data" : spirits,
                        "name" : "Common Spirit Combinations",
                        "colors" : graphColors
                    }],
                    "title" : {
                        "text" : `Commonly Combined Spirit Types`
                    },
                    "credits" : {
                        "enabled" : false
                    }
                }}
            />
        )
    }

    return <Chart />
}

export default RecipeSpiritsCommonCombination;