import { createSlice } from '@reduxjs/toolkit';

export const tagsSlice = createSlice({
    name: 'tags',
    initialState: {
        list: [],
    },
    reducers: {
        set : (state, input) => {
            state.list = input.payload;
        },
        fetch : (state) => {
            // fetch from server TBD
        }
    },
});

export const { set, fetch } = tagsSlice.actions;
export default tagsSlice.reducer;