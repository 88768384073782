import React, {useState, useEffect, useReducer} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from './helper/config';

import styles from './css/ShareModal.css';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import fastyles from './fa/css/all.css';

const ShareGraphModal = ({
    name,
    isVisible = false,
    tokenId,
    imageShareData,
    closeShareModal
}) => {
    if(!tokenId && !imageShareData) {
        return <></>
    }

    function base64ToBlob(base64Data, contentType) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
        
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    const shareImage = () => {
        if(navigator.share) {
            const contentType = 'image/png'; // Replace with the actual content type of your image
            const blob = base64ToBlob(imageShareData.replace(/^data:image\/png;base64,/, ''), contentType);
            const files = [new File([blob], `cocktail-metrics.png`, { type: blob.type })];
            const shareData = {
                "text": `${config.app.name} - ${name}`,
                "title": `${config.app.name} - ${name}`,
                "files": files
            };
            navigator.share(shareData);
        } else {
            try {
                const newTab = window.open();
                newTab.document.write(`<img src="${imageShareData}" />`);
            } catch(e) {
                console.error(e);
            }
        }

        closeShareModal();
    }


    const GraphImage = () => {
        if(!imageShareData) {
            return <img />
        }

        return (
            <img src={`${imageShareData}`} onClick={() => {
                shareImage();
            }} />
        )
    }
      

    return (
        <div className={`share-modal ${isVisible ? 'show' : 'hide'}`}>
            <div className="share-inner">
                <div className="title-container">
                    <div className="share-title">Select How to Share</div>
                    <div className="share-close">
                        <i className="fa-solid fa-xmark" onClick={() => {
                            closeShareModal();
                        }} />
                    </div>
                </div>
                <span className="share-option">- Share a Picture -</span>
                <div className="share-graph-image">
                    <GraphImage />
                </div>
                {!navigator.share ? <div className="share-note-image">The recipe picture will open in a new browser tab.</div> : ''}
            </div>
        </div>
    );
}

export default ShareGraphModal;